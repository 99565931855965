import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WorkflowDrawer } from "./workflow-drawer";
import { useState } from "react";
import { RenderGroupOrUser } from "../misc/columns_def";
import uuid from "react-uuid";

export const WorkflowDefinition = (props) => {
  const { value, onChange, edit, label, error } = props

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [drawerValue, setDrawerValue] = useState(null)

  const handleEditStep = (step) => {
    setDrawerValue(step)
    setIsDrawerOpen(true)
  }

  const handleDeleteStep = (step) => {
    const updatedSteps = value.filter((wStep) => wStep.id !== step.id);
    onChange(updatedSteps);
  };

  const handleAddStep = () => {
    setDrawerValue(null)
    setIsDrawerOpen(true)
  }

  const handleDrawerClose = (v) => {
    if (v) {
      if (v.id) {
        const updatedSteps = value.map((step) =>
          step.id === v.id ? { ...step, ...v } : step
        );
        onChange(updatedSteps);
      } else {
        onChange([...value, { ...v, id: uuid() }]);
      }
    }
    setIsDrawerOpen(false);
  };

  return (
    <>
      <WorkflowDrawer
        open={isDrawerOpen}
        onClose={handleDrawerClose}
        defaultValues={drawerValue}
      />
      <label className='block text-sm font-medium text-gray-800 mb-1'>{label}</label>
      {value?.map((wStep, index) => (
        <div key={wStep.id} className="flex gap-x-2 mb-4" data-testid={`wf-def-${index}`}>
          <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-blue-800">
            <div className="relative z-10 size-7 flex justify-center items-center">
              <div className="size-3 rounded-full bg-blue-800"></div>
            </div>
          </div>
          <div className="flex-grow border py-4 px-4 rounded hover:bg-slate-50">
            <div className='flex justify-between'>
              <div className="text-xs uppercase text-blue-800 font-bold mb-1">
                Step {index + 1} of {value?.length}
              </div>
              <div className='flex gap-1'>
                {edit &&
                  <>
                    <a
                      className={`hover:underline text-blue-600 hover:text-blue-800 cursor-pointer font-bold uppercase text-xs`}
                      onClick={() => { handleEditStep(wStep); }}
                    >
                      Edit
                    </a>
                    <a
                      className={`hover:underline text-blue-600 hover:text-blue-800 cursor-pointer font-bold uppercase text-xs`}
                      onClick={() => { handleDeleteStep(wStep); }}
                    >
                      Delete
                    </a>
                  </>
                }
              </div>
            </div>
            <h3 className="text-lg font-semibold teFxt-gray-900">
              {wStep.name}
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              {wStep.description}
            </p>
            <div className='flex flex-col mt-2 border-t pt-2 gap-2'>
              <div className='flex gap-2 items-center'>
                <span className='font-bold uppercase text-xs text-gray-800'>Assigned: </span>
                {wStep?.assigned && <RenderGroupOrUser user={!wStep?.group ? wStep?.assigned : null} group={wStep?.group ? wStep?.assigned : null} />}
              </div>
              <div className='flex gap-2'>
                <span className='font-bold uppercase text-xs text-gray-800'>Permission to Add Notes: </span>
                {wStep.can_add_note ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faXmark} />}
              </div>
            </div>
          </div>
        </div>
      ))}
      {(value?.length === 0 || value === undefined) &&
        <div className="py-12 border flex justify-center border-dashed border-2 border-slate-400 ">
          <span className="uppercase font-bold text-xs text-gray-800">No steps have been added yet</span>
        </div>
      }
      {edit &&
        <a
          className={`hover:underline text-blue-600 hover:text-blue-800 cursor-pointer font-bold uppercase text-xs`}
          onClick={handleAddStep}
          data-testid="button-add-step"
        >
          Add Step
        </a>
      }
      {error && <p className='text-red-500 text-xs'>{error}</p>}
    </>
  );
};

