import React from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { Fragment, useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { UserInfoContext } from '../../page/root-page'
import { useAuth } from '../../supabase'
import { ActionButton } from '../form/action-button'
import { JSelect } from '../form/j-select'
import { LoadingWheel } from '../misc/loading-wheel'
import { AdditionnalFieldsForm } from '../form/addtionnal-fields-form'

/*
const maturityOptions = [
  { value: '0', label: '0' },
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' }
]*/

const applicableOptions = [
  { value: true, label: 'Applicable' },
  { value: false, label: 'Not Applicable' }
]

export const EditComplianceDrawer = (props) => {
  const { supabase } = useAuth()
  const { userInfo } = useContext(UserInfoContext)
  const maturityOptions = userInfo?.enumData?.compliance_maturity;
 
  const { open, onClose, data } = props

  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
    watch,
    control
  } = useForm({ defaultValues: data, mode: 'onBlur' })
  const formValues = watch()

  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = async (v) => {
    setIsLoading(true)

    const payload = {
      applicable: v.applicable,
      maturity: v.maturity,
      custom_values: v?.custom_values
    }
    const { d, error } = await supabase
      .from('framework_safeguard')
      .update(payload)
      .eq('id', data.id)

    if (error) console.error(error)
    setIsLoading(false)
    onClose()
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter='ease-in-out duration-500'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in-out duration-500'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 overflow-hidden'>
          <div className='absolute inset-0 overflow-hidden'>
            <div className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10'>
              <Transition.Child
                as={Fragment}
                enter='transform transition ease-in-out duration-500 sm:duration-700'
                enterFrom='translate-x-full'
                enterTo='translate-x-0'
                leave='transform transition ease-in-out duration-500 sm:duration-700'
                leaveFrom='translate-x-0'
                leaveTo='translate-x-full'
              >
                <Dialog.Panel className='pointer-events-auto relative w-screen max-w-md'>
                  <Transition.Child
                    as={Fragment}
                    enter='ease-in-out duration-500'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='ease-in-out duration-500'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                  >
                    <div className='absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4'>
                      <button
                        type='button'
                        className='relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white'
                        onClick={onClose}
                      >
                        <span className='absolute -inset-2.5' />
                        <span className='sr-only'>Close panel</span>
                        <XMarkIcon className='h-6 w-6' aria-hidden='true' />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className='flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl'>
                    <div className='px-4 sm:px-6'>
                      <Dialog.Title className='text-base font-semibold leading-6 text-gray-900'>Compliance</Dialog.Title>
                    </div>
                    <div className='relative mt-6 flex-1 px-4 border-t pt-2 sm:px-6'>
                      {isLoading && <LoadingWheel />}
                      {!isLoading && (
                        <form id='edit-compliance-form' onSubmit={handleSubmit(onSubmit)}>
                          <div className='flex-2'>
                            <JSelect
                              dataTestid='compliance-form-applicable'
                              label='Applicable'
                              name='applicable'
                              placeholder='Select Applicable'
                              control={control}
                              options={applicableOptions}
                            />
                          </div>
                          {formValues?.applicable === true && (
                            <div className='flex-1'>
                              <JSelect
                                dataTestid='compliance-form-maturity'
                                label='Maturity'
                                name='maturity'
                                placeholder='Select Maturity'
                                disabled={true}
                                control={control}
                                rules={{ required: true }}
                                options={maturityOptions}
                                errorMessage={errors?.maturity ? 'Required' : null}
                              />
                            </div>
                          )}
                          <AdditionnalFieldsForm
                            customFields={userInfo.customFields?.compliance}
                            control={control}
                            errors={errors}
                            register={register}
                          />

                          <div className='flex justify-end mt-2'>
                            <ActionButton dataTestid='compliance-form-save' disabled={!isValid} styleType='primary' type='submit'>
                              Save
                            </ActionButton>
                          </div>
                        </form>
                      )}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
