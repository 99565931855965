import { Controller } from 'react-hook-form'
import { SchedulerInput } from './scheduler'
import React from 'react'

// Radio scheduler
export const RadioExecutionScheduler = (props) => {
  const { value, register_fn, control, rules, disabled } = props

  return (
    <div className='px-4 space-y-2 mt-2'>
      <div className={`p-4 ${value === 'scheduled_control' ? 'border-2 border-indigo-800 border-dashed rounded-md' : ''}`}>
        <div className='flex items-center gap-x-3'>
          <input
            id='scheduled_control'
            value='scheduled_control'
            data-testid='radio-scheduled'
            disabled={disabled}
            type='radio'
            className={`h-4 w-4 border-gray-300 ${disabled ? 'text-gray-600 focus:ring-gray-600' : 'text-indigo-600 focus:ring-indigo-600'}`}
            {...register_fn()}
          />
          <div className='text-sm leading-6'>
            <label htmlFor='scheduled_control' className='cursor-pointer block text-sm font-medium leading-6 text-gray-900'>
              Scheduled Execution
            </label>
            <p className='text-gray-500'>
              This option allows for automatic, recurring scheduling of control activities. Specify the initial date and frequency, and the
              system will handle the subsequent scheduling.
            </p>
          </div>
        </div>
        {value === 'scheduled_control' && (
          <Controller
            control={control}
            name='control_schedule'
            rules={rules}
            render={({ field: { value, onChange } }) => <SchedulerInput onChange={onChange} value={value} disabled={disabled} />}
          />
        )}
      </div>
      <div className={`flex items-center gap-x-3 p-4  ${value === 'nocontrol' ? 'border-2 border-indigo-800 border-dashed rounded-md' : ''}`}>
        <input
          id='nocontrol'
          value='nocontrol'
          disabled={disabled}
          data-testid='radio-adhoc'
          type='radio'
          className={`h-4 w-4 border-gray-300 ${disabled ? 'text-gray-600 focus:ring-gray-600' : 'text-indigo-600 focus:ring-indigo-600'}`}
          {...register_fn()}
        />
        <div className='text-sm leading-6'>
          <label htmlFor='nocontrol' className='cursor-pointer block text-sm font-medium leading-6 text-gray-900'>
            Ad Hoc
          </label>
          <p className='text-gray-500' htmlFor='nocontrol'>
            Select this option if you prefer to manage control activities without a set schedule.
          </p>
        </div>
      </div>
    </div>
  )
}
