// useTask.js
import { useEffect, useRef, useState } from 'react';
import { policyService } from '../../services/policyService';

export const usePolicyData = (supabase, id) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const hasFetchedData = useRef(false);

  const fetchData = async () => {
    if (!data) setIsLoading(true);
    try {
      const data = await policyService.getPolicyById(supabase, id);

      if (data && data.all_version) {
        data.all_version.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      }

      if (data && data.framework_safeguard) {
        data.framework_safeguard = data.framework_safeguard.map((safeguard) => ({
          ...safeguard,
          framework_name: safeguard.framework?.name || 'Unknown Framework', // Add framework name to each safeguard
        }))
      }
      const dataFinal = {...data, ...data.custom_values};
      setData(dataFinal ?? {})
    } catch (error) {
      setError(error);
    }
    if (!data) setIsLoading(false);
  };

  useEffect(() => {
    if (!hasFetchedData.current && id) {
      fetchData();
      hasFetchedData.current = true;
    }
  }, [supabase, id]);

  return { data, isLoading, error, refetch: fetchData };
};
