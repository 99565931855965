import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { AuthCard } from '../../components/auth/auth-card';
import { jToaster } from '../../misc/j-toaster';
import { useAuth } from '../../supabase';
import { useForm } from 'react-hook-form';
import { ActionButton } from '../../components/form/action-button';
import { TextInput } from '../../components/form/inputs';

// MFA fields configuration
const mfaFields = [
  {
    label: 'MFA Code',
    name: 'mfa',
    type: 'text',
    rules: {
      required: 'MFA code is required',
      minLength: { value: 6, message: 'MFA code must be 6 characters' },
      maxLength: { value: 6, message: 'MFA code must be 6 characters' },
      pattern: {
        value: /^[0-9]{6}$/,
        message: 'MFA code must be 6 digits',
      },
    },
  },
];

const FormField = ({ label, name, type = 'text', rules, register, errors }) => (
  <TextInput
    label={label}
    name={name}
    type={type}
    errorMessage={errors?.[name]?.message}
    register_fn={() => register(name, rules)}
  />
);

const AuthForm = ({ fields, submitForm, isLoading, buttonLabel }) => {
  const { register, handleSubmit, formState: { errors, isValid } } = useForm({ mode: 'all' });

  return (
    <form onSubmit={handleSubmit(submitForm)} className="flex flex-col items-center gap-4 w-full">
      {fields.map(({ label, name, type, rules }) => (
        <FormField key={name} label={label} name={name} type={type} rules={rules} register={register} errors={errors} />
      ))}
      <ActionButton disabled={!isValid} loading={isLoading} className="w-full justify-center mt-4" type="submit">
        {buttonLabel}
      </ActionButton>
    </form>
  );
};

// Step 3: MFA Verification Page Component
export const MfaVerificationPage = () => {
  const navigate = useNavigate();
  let { factorId:  factorIdparam } = useParams();

  const { supabase } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [qrCode, setQrCode] = useState(null);
  const [factorId, setFactorId] = useState((!factorIdparam || factorIdparam === 'null') ? null : factorIdparam);

  const handleMfaVerification = async (values) => {
    setIsLoading(true);
    try {
      const { data, error } = await supabase.auth.mfa.challengeAndVerify({
        factorId,
        code: values.mfa,
      });
      if (error) throw new Error('Invalid MFA code');

      jToaster.success('MFA verification OK');
      navigate('/app/todo');
    } catch (err) {
      setErrorMessage(err.message || 'MFA verification failed');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const enrollMfa = async () => {
      setIsLoading(true);
      try {
        const { data: mfadata, error: mfaError } = await supabase.auth.mfa.enroll({
          factorType: 'totp',
          friendlyName: 'MFA',
          issuer: 'Keepya.IO'
        });
        if (mfaError) throw mfaError;

        setQrCode(mfadata?.totp?.qr_code);
        setFactorId(mfadata?.id)
      } catch (err) {
        console.error('Failed to enroll in MFA', err)
        setErrorMessage('Failed to enroll in MFA');
      } finally {
        setIsLoading(false);
      }
    };

    if(!factorId){
      enrollMfa();
    }
  }, [supabase]);

  return (
    <div className="h-screen bg-slate-50 flex items-center justify-center">
      <AuthCard>
        {errorMessage && <span className="text-red-500 text-center">{errorMessage}</span>}
        {qrCode && (
          <>
            <p>Please scan this QR code with your MFA app:</p>
            <img src={qrCode} alt="Scan the QR code" className="w-full" />
          </>
        )}
        <AuthForm fields={mfaFields} submitForm={handleMfaVerification} isLoading={isLoading} buttonLabel="Verify MFA" />
      </AuthCard>
    </div>
  );
};
