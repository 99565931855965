import React from 'react';

export const TablePagination = ({ total, itemsPerPage, currentPage, onPageChange }) => {
  const totalPages = Math.ceil(total / itemsPerPage);

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      onPageChange(page);
    }
  }

  return (
    <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-1 sm:px-6">
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <p className="text-sm text-gray-700">
          Showing <span className="font-medium">{(currentPage - 1) * itemsPerPage + 1}</span> to{' '}
          <span className="font-medium">{Math.min(currentPage * itemsPerPage, total)}</span> of{' '}
          <span className="font-medium">{total}</span> results
        </p>
        <div className="flex gap-2">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className={`px-4 py-2 hover:font-bold uppercase text-xs font-semibold hover:underline text-sm font-medium ${currentPage === 1 ? 'text-gray-400' : 'text-gray-700 hover:text-blue-600'}`}
          >
            Previous
          </button>
          <select
            id="page-select"
            className="mt-1 block pl-3  pr-10 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-sm rounded-md"
            value={currentPage}
            onChange={(e) => handlePageChange(Number(e.target.value))}
          >
            {Array.from({ length: totalPages }, (_, i) => (
              <option key={i + 1} value={i + 1}>
                Page {i + 1}
              </option>
            ))}
          </select>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className={`px-4 py-2 hover:font-bold font-semibold hover:underline uppercase text-xs ${currentPage === totalPages ? 'text-gray-400' : 'text-gray-700 hover:text-blue-600'}`}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
