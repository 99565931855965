import { useContext, useEffect, useState } from 'react';
import { UserInfoContext } from '../../page/root-page';
import { useAuth } from '../../supabase';
import { ComboBox } from './combo-box';

export function AddWorkflowInput({ value, onChange, disabled }) {
  const { supabase } = useAuth();
  const { userInfo } = useContext(UserInfoContext);

  const [isLoading, setIsLoading] = useState(true);
  const [options, setOptions] = useState([]);
  const [queryError, setQueryError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const { data: wfTemplateData, error } = await supabase
          .from('workflow_template')
          .select('*')
          .eq('fk_project_id', userInfo?.project?.id);

        if (error) throw error;

        const processedWfTemplateData = [
          { id: 'no-validation', comboboxName: 'NO VALIDATION' }, // Default record
          ...(wfTemplateData ?? []).map(wf => ({
            ...wf,
            comboboxName: wf.name, // Assuming comboboxName is the display name for ComboBox
          }))
        ];

        setOptions(processedWfTemplateData);
      } catch (error) {
        console.error('Failed to fetch data:', error);
        setQueryError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [supabase, userInfo?.project?.id]); // Ensured only necessary dependencies trigger the effect

  if (isLoading) {
    return <div>Loading...</div>; // Placeholder loading state
  }

  if (queryError) {
    return <div className="text-red-500">Error: {queryError.message}</div>; // Simple error message
  }

  return (
    <ComboBox 
      options={options} 
      value={value} 
      onChange={onChange} 
      disabled={disabled} 
    />
  );
}
