import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router'
import { UserInfoContext } from '../../page/root-page'
import { useAuth } from '../../supabase'
import { ElementDetail, RenderFileUploaded } from '../element/element-detail'
import { JFormatDate, jFormatDateAndTime, RenderGroupOrUser, StatusTag } from '../misc/columns_def'
import { TabHeader } from '../misc/tab-header'
import { ConnectionTable } from '../table/connection-table'
import { TasksTable } from '../table/tasks-table'
import { PolicyVersioning } from './policy-versioning'

const tabs = [
  { name: 'Description', href: '' },
  { name: 'Versions', href: '' },
  { name: 'Tasks', href: '' },
  { name: 'Connections', href: '' }
]

const getPolicyDetail = (navigate, policyData, customFields) => {
  return [
    { key: 'tag', label: 'Tag', render: StatusTag },
    { key: 'created_at', label: 'Creation Date', render: (e) => <span>{jFormatDateAndTime(e?.created_at)}</span>, },
    { type: 'divider', label: 'description', editFn: () => { navigate('/app/policy/' + policyData.id + '/edit'); }, },
    { key: 'owner', label: 'Owner', render: (e) => <RenderGroupOrUser user={e?.user} group={e.group} />, },
    { key: 'userdef_id', label: 'Policy ID' },
    { key: 'title', label: 'Title' },

    //Versioning
    {
      type: 'divider',
      label: policyData.current_version ? 'Current Version' : 'No Version Available',
      ...(policyData.current_version && {
        editFn: () => {
          navigate('/app/policy/' + policyData.id + '/' + policyData.current_version.id + '/edit');
        },
      }),
    },
    ...(policyData.current_version
      ? [
        {
          key: 'version',
          label: 'Version ID',
          render: (e) => <span>{e.current_version.userdef_id}</span>,
        },
        {
          key: 'description',
          label: 'Description',
          render: (e) => <span className='whitespace-pre-line'>{e.current_version.description}</span>,
        },
        {
          key: 'attachments',
          label: 'Attachments',
          render: (e) => <RenderFileUploaded fileList={e.current_version.af_policy_version_attachment} />,
        },
        //Custom fields
        ...(customFields && customFields.length > 0
          ? [
            { type: 'divider', label: 'Custom Fields', key: 'divcustomfields' },
            ...customFields.map((field) => ({
              key: `current_version.custom_values.${field.field_name}`,
              label: field.field_name,
              render: field.field_type === 'date' ? (e) => (<span>{JFormatDate(e?.current_version?.custom_values[field.field_name])}</span>) : undefined, 
            }))
          ]
          : []
        ),
      ]
      : []),
  ]
}

export const PolicyDetail = (props) => {
  const { userInfo } = useContext(UserInfoContext)
  const { supabase } = useAuth()
  const navigate = useNavigate()

  const { policyData, refetch } = props

  const [selectedTab, setSelectedTab] = useState('Description')
  const policy_spec = getPolicyDetail(navigate, policyData, userInfo?.customFields?.policy)

  const handleNewConnection = async (type, value) => {
    if (type === 'compliance') {
      await supabase.from('af_policy_compliance').upsert({
        fk_policy_id: policyData.id,
        fk_framework_safeguard_id: value.id,
        fk_project_id: userInfo.project.id
      })
    } else if (type === 'risk') {
      await supabase.from('af_policy_risk').upsert({
        fk_risk_id: value.id,
        fk_policy_id: policyData.id,
        fk_project_id: userInfo.project.id
      })
    } else if (type === 'control') {
      await supabase.from('af_policy_control').upsert({
        fk_control_id: value.id,
        fk_policy_id: policyData.id,
        fk_project_id: userInfo.project.id
      })
    }
    await refetch();
  }

  const handleDeleteConnection = async (type, value) => {
    if (type === 'compliance') {
      await supabase
        .from('af_policy_compliance')
        .delete()
        .eq('fk_policy_id', policyData.id)
        .eq('fk_framework_safeguard_id', value.id)
    } else if (type === 'risk') {
      await supabase
        .from('af_policy_risk')
        .delete()
        .eq('fk_policy_id', policyData.id)
        .eq('fk_risk_id', value.id)
    } else if (type === 'control') {
      await supabase
        .from('af_policy_control')
        .delete()
        .eq('fk_control_id', value.id)
        .eq('fk_policy_id', policyData.id)
    }
    await refetch();
  }

  return (
    <>
      <TabHeader
        tabs={tabs}
        selectedTab={selectedTab}
        onSelectedTabChange={(e) => setSelectedTab(e)}
      />
      <div>
        {selectedTab === 'Description' &&
          <ElementDetail
            detailSpec={policy_spec}
            data={policyData}
          />}
        {selectedTab === 'Versions' &&
          <PolicyVersioning
            policyData={policyData}
            refetch={refetch}
          />
        }

        <TasksTable
          hidden={selectedTab !== 'Tasks'}
          associatedId={policyData?.id}
        />

        {selectedTab === 'Connections' &&
          <ConnectionTable
            canEditData={true}
            value={policyData}

            onNewConnection={handleNewConnection}
            onDeleteConnection={handleDeleteConnection}
          />
        }
      </div>
    </>
  )
}
