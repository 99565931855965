
export const policyService = {
  // Fetch a single policy by ID
  async getPolicyById(supabase, id) {
    const { data, error } = await supabase
      .from('policy')
      .select(`
        *, 
        framework_safeguard(*, framework(name)), 
        control(*), 
        risk(*), 
        user(*),
        group(*),
        current_version:policy_version!policy_fk_active_version_fkey (*, af_policy_version_attachment(*)),
        all_version:policy_version!policy_version_fk_policy_id_fkey (*, af_policy_version_attachment(*))
      `)
      .eq('id', id)
      .order('created_at', { foreignTable: 'policy_version', descending: true })
      .single();

    if (error) {
      throw error;
    }

    return data;
  },

  // Update the status of a policy
  async updatePolicyStatus(supabase, id, status) {
    const { data, error } = await supabase
      .from('policy')
      .update({ status })
      .eq('id', id);

    if (error) {
      console.error('Error updating policy status:', error);
      throw error;
    }

    return data;
  },

  // Delete a policy
  async deletePolicy(supabase, id) {
    const { error } = await supabase
      .from('policy')
      .delete()
      .eq('id', id);

    if (error) {
      console.error('Error deleting policy:', error);
      throw error;
    }
  },

  async getAllPolicies(supabase, project_id) {
    const { data, error } = await supabase
      .from('policy')
      .select(`
        *, 
        group(*), 
        user(*), 
        policy_version!policy_version_fk_policy_id_fkey(count)
      `)
      .eq('fk_project_id', project_id)
      .order('created_at', { ascending: false })

    if (error) throw error;

    return data
  }
};
