import React from 'react'

import { Controller } from 'react-hook-form'
import { AddStakeholderInput } from '../form/add-stakeholder-input'
import { AdditionnalFieldsForm } from '../form/addtionnal-fields-form'
import { FileUploader } from '../form/file-uploader'
import { TextInput } from '../form/inputs'

export const PolicyGeneralFormPanel = (props) => {
  const { register, errors, control, hidden } = props

  return (
    <div className='space-y-4' hidden={hidden}>
      <div>
        <label className='block text-sm font-medium text-gray-700 mb-1'>Owner</label>
        <Controller
          control={control}
          name='owner'
          rules={{ required: 'Owner is required' }}
          errorMessage={errors?.owner ? 'This field is required' : null}
          render={({ field: { value, onChange } }) =>
            <AddStakeholderInput value={value} onChange={onChange}
            />}
        />
        {errors?.owner && <p className='text-red-500 text-xs italic'>{errors.owner.message}</p>}
      </div>

      <TextInput
        label='ID'
        name='userdef_id'
        dataTestid='policy-form-userdef'
        errorMessage={errors?.userdef_id ? 'This field is required, maximum 12 characters' : null}
        register_fn={() => {
          return register('userdef_id', { required: true, maxLength: 12 })
        }}
      />
      <TextInput
        label='Name'
        name='title'
        dataTestid='policy-form-title'
        errorMessage={errors?.title ? 'This field is required, maximum 128 characters' : null}
        register_fn={() => {
          return register('title', { required: true, maxLength: 128 })
        }}
      />

    </div>
  )
}

export const PolicyRevisionFormPanel = (props) => {
  const { register, errors, control, hidden, customFields } = props

  return (
    <div className='space-y-4' hidden={hidden}>
      <TextInput
        label='Revision Name'
        name='revision_userdef_id'
        dataTestid='form-revision-userdefid'
        errorMessage={errors?.revision_userdef_id ? 'This field is required, maximum 32 characters' : null}
        register_fn={() => {
          return register('revision_userdef_id', { required: true, maxLength: 32 })
        }}
      />
      <TextInput
        label='Description'
        dataTestid='form-revision-description'
        name='description'
        rows={15}
        errorMessage={errors?.description ? 'This field is required' : null}
        register_fn={() => {
          return register('description', { required: true })
        }}
      />
      <div>
        <label className='block text-sm font-medium text-gray-700 mb-1'>Attachments</label>
        <Controller
          control={control}
          dataTestid='form-revision-attachments'
          name='attachments'
          render={({ field: { value, onChange } }) => <FileUploader name='attachments' value={value} onChange={onChange} />}
        />
      </div>
      <AdditionnalFieldsForm
        control={control}
        register={register}
        errors={errors}
        customFields={customFields}
      />
    </div>
  )
}