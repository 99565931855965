import React from 'react'

export const Radio = (props) => {
  const { name, options, value, onChange } = props

  return (
    <div className='px-4 space-y-2 mt-2'>
      {options.map((o) => (
        <div
          key={o.value}
          className={`flex items-center gap-x-3 p-2 cursor-pointer ${value === o.value ? 'ring-2 ring-indigo-600 rounded-md' : ''}`}
        >
          <input
            data-testid={`radio-${o.value}`}
            value={o.value}
            onChange={onChange}
            name={name}
            checked={o.value === value}
            type='radio'
            className='h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600'
          />
          <div className='text-sm leading-6'>
            <label htmlFor={o.value} className='block text-sm font-medium leading-6 text-gray-900 cursor-pointer'>
              {o.primary}
            </label>
            <label htmlFor={o.value} className='text-gray-500 cursor-pointer'>
              {o.secondary}
            </label>
          </div>
        </div>
      ))}
    </div>
  )
}
