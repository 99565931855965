import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ElementDetail } from '../components/element/element-detail';
import { ObjectHeader } from '../components/header/object-header';
import { jFormatDateAndTime } from '../components/misc/columns_def';
import { FullPageCard } from '../components/misc/full-page-card';
import { LoadingWheel } from '../components/misc/loading-wheel';
import { WorkflowDefinition } from '../components/workflow/workflow-definition';
import { WorkflowForm } from '../components/workflow/workflow-form';
import { useWorkflowData } from '../hooks/db/useWorkflowData';
import { deleteWorkflow, saveWorkflow } from '../services/workflowService';
import { useAuth } from '../supabase';
import { UserInfoContext } from './root-page';
import { useDeleteConfirmationDialog } from '../hooks/useDeleteConfirmationDialog';
import { jToaster } from '../misc/j-toaster';

const workflow_spec = [
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'description',
    label: 'Description',
  },
  {
    key: 'created_at',
    label: 'Creation Date',
    render: (e) => <span>{jFormatDateAndTime(e?.created_at)}</span>
  },
  {
    key: 'div1',
    type: 'divider'
  },
  {
    key: 'workflow',
    label: 'Workflow',
    render: (row) => (
      <WorkflowDefinition
        value={row.workflow_template_steps}
        edit={false}
      />
    )
  },

];

export const WorkflowPage = () => {
  const { id: paramId } = useParams();
  const navigate = useNavigate();
  
  const { supabase } = useAuth();
  const { userInfo } = useContext(UserInfoContext);

  // Determine if it's a new workflow or editing an existing one
  const isNewWorkflow = paramId === 'new';
  const [isEditing, setIsEditing] = useState(isNewWorkflow);
  const [isSaving, setIsSaving] = useState(false);

  const { openDialog, DeleteConfirmationDialog } = useDeleteConfirmationDialog();
  const { workflowData, isLoading, error, refetch } = useWorkflowData(!isNewWorkflow ? paramId : undefined);
  const errorShownRef = useRef(false);

  // Effect to reset `isEditing` if we are editing an existing workflow
  useEffect(() => {
    if (!isNewWorkflow) {
      setIsEditing(false);
    }
  }, [paramId, isNewWorkflow]);

  const handleDelete = async () => {
    openDialog(async () => {
      try {
        await deleteWorkflow(supabase, paramId);

        jToaster.success(`Workflow "${workflowData?.name}" has been deleted.`)
        navigate('/app/workflow');
      } catch (error) {
        jToaster.error('Failed to delete the workflow. Error code: ' + error?.code);
      }
    })
  };

  const handleSave = async (workflowValues) => {
    setIsSaving(true)
    try {
      await saveWorkflow(supabase, workflowValues, userInfo.project.id);

      if (isNewWorkflow) {
        setIsEditing(false);  // Exit edit mode after saving
        setIsSaving(false)
        jToaster.success(`Workflow "${workflowValues?.name}" has been successfully created.`);
        navigate(`/app/workflow/${workflowValues.id}`);
      }

      else {
        await refetch();
        setIsSaving(false)
        setIsEditing(false);  // Exit edit mode after saving
      }
    } catch (error) {
      jToaster.error('Failed to save workflow. Error code:' + error?.code)
    }
  };

  const handleEdit = () => setIsEditing(true);

  const handleCancelEdit = () => {
    if (isNewWorkflow) navigate('/app/workflow')
    else {
      setIsEditing(false);
    }
  }

  if (error && !errorShownRef.current) {
    jToaster.error('Failed to load workflow information. Error code: ' + error.code)
    errorShownRef.current = true;
  }

  if (isLoading || isSaving) return <LoadingWheel />;
  if (error) return <></>

  return (
    <FullPageCard>
      <ObjectHeader
        title={workflowData?.name ? `Workflow: ${workflowData?.name}` : 'New Workflow'}
        backUrl={-1}
        secondaryButtonText={!isEditing && !isNewWorkflow ? 'Delete' : undefined}
        onSecondaryButtonClick={!isEditing && !isNewWorkflow ? handleDelete : undefined}

        primaryButtonText={!isEditing ? 'Edit' : undefined}
        onPrimaryButtonClick={!isEditing ? handleEdit : undefined}
      />

      <DeleteConfirmationDialog />

      <div className="flex flex-col gap-4">
        {!isEditing &&
          <ElementDetail
            detailSpec={workflow_spec}
            data={workflowData}
          />
        }
        {isEditing &&
          <WorkflowForm
            initialData={workflowData}
            onSubmit={handleSave}
            onCancel={handleCancelEdit}
          />
        }
      </div>
    </FullPageCard>
  );
};
