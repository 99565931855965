import React, { useContext, useEffect } from 'react'

import { Controller, useForm } from 'react-hook-form'
import { ActionButton } from '../form/action-button'
import { AddStakeholderInput } from '../form/add-stakeholder-input'
import { AdditionnalFieldsForm } from '../form/addtionnal-fields-form'
import { TextInput } from '../form/inputs'
import { JSelect } from '../form/j-select'
import { UserInfoContext } from '../../page/root-page'

const PanelDescription = (props) => {
  const { register, errors, control, hidden, watch, setValue, customFields } = props
  const { userInfo } = useContext(UserInfoContext)

  const riskProbabilityOption = userInfo.enumData?.risk_probability
  const riskImpactOption = userInfo.enumData?.risk_impact
  const riskScoreOption = userInfo.enumData?.risk_score
  const riskStrategyOption = userInfo.enumData?.risk_strategy

  return (
    <div className='space-y-4' hidden={hidden}>
      <div>
        <label className='block text-sm font-medium text-gray-700 mb-1'>Owner</label>
        <Controller
          control={control}
          name='owner'
          rules={{ required: 'Owner is required' }}
          errorMessage={errors?.owner ? 'This field is required' : null}
          render={({ field: { value, onChange } }) =>
            <AddStakeholderInput value={value} onChange={onChange}
            />}
        />
        {errors?.owner && <p className='text-red-500 text-xs italic'>{errors.owner.message}</p>}
      </div>

      <div className="my-6 h-0.5 bg-gradient-to-r from-gray-300 via-gray-400 to-gray-500"></div>

      <TextInput
        label='ID'
        name='userdef_id'
        dataTestid='risk-form-id'
        errorMessage={errors?.userdef_id ? 'This field is required, maximum 12 characters' : null}
        register_fn={() => {
          return register('userdef_id', { required: true, maxLength: 12 })
        }}
      />
      <TextInput
        label='Title'
        name='title'
        dataTestid='risk-form-title'
        errorMessage={errors?.title ? 'This field is required, maximum 128 characters' : null}
        register_fn={() => {
          return register('title', { required: true, maxLength: 128 })
        }}
      />

      {/* Probability, Impact, and Risk Score on the same row */}
      <div className='flex space-x-4 w-full'>
        <div className='flex-1'>
          <JSelect
            label='Probability'
            name='probability'
            dataTestid='risk-form-probability'
            placeholder='Select Probability'
            control={control}
            rules={{ required: true }}
            options={riskProbabilityOption}
            errorMessage={errors?.probability ? 'Required' : null}
          />
        </div>
        <div className='flex-1'>
          <JSelect
            label='Impact'
            name='impact'
            placeholder='Select Impact'
            dataTestid='risk-form-impact'
            control={control}
            rules={{ required: true }}
            options={riskImpactOption}
            errorMessage={errors?.impact ? 'Required' : null}
          />
        </div>
        <div className='flex-1'>
          <JSelect
            label='Score'
            name='risk_score'
            placeholder='Select Score'
            dataTestid='risk-form-score'
            control={control}
            rules={{ required: true }}
            options={riskScoreOption}
            errorMessage={errors?.risk_score ? 'Required' : null}
          />
        </div>
        <div className='flex-1'>
          <JSelect
            label='Strategy'
            name='strategy'
            placeholder='Select Strategy'
            dataTestid='risk-form-strategy'
            control={control}
            rules={{ required: true }}
            options={riskStrategyOption}
            errorMessage={errors?.strategy ? 'Required' : null}
          />
        </div>
      </div>
      <TextInput
        label='Description'
        name='description'
        rows={15}
        dataTestid='risk-form-description'
        errorMessage={errors?.description ? 'This field is required' : null}
        register_fn={() => {
          return register('description', { required: true })
        }}
      />
      <AdditionnalFieldsForm
        control={control}
        register={register}
        errors={errors}
        customFields={customFields}
      />
    </div>
  )
}

export const RiskForm = (props) => {
  const { initialData, customFields, onSubmit, onCancel } = props

  const defaultValues = {
    id: initialData?.id || undefined,
    userdef_id: initialData?.userdef_id || '',
    title: initialData?.title || '',
    description: initialData?.description || '',
    strategy: initialData?.strategy || 'Mitigate',
    impact: initialData?.impact || 1,
    probability: initialData?.probability || 1,
    risk_score: initialData?.risk_score || 1,
    custom_values: initialData?.custom_values || {},
    owner: initialData?.group
      ? { ...initialData.group, comboboxName: initialData.group.name, group: true } : initialData?.user
        ? { ...initialData.user, comboboxName: initialData.user.name, group: false }
        : null,
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    watch,
    control
  } = useForm({ defaultValues: defaultValues, mode: 'onBlur' })

  return (
    <>
      <form id='policy-edit-form' onSubmit={handleSubmit(onSubmit)}>
        <PanelDescription
          watch={watch}
          setValue={setValue}
          register={register}
          control={control}
          errors={errors}
          customFields={customFields}
        />

        <div className='flex justify-end gap-2 mt-2 border-t pt-2'>
          <ActionButton onClick={onCancel} styleType='secondary' type='button'>
            Cancel
          </ActionButton>
          <ActionButton dataTestid='risk-form-save' disabled={!isValid} styleType='primary' type='submit'>
            Save
          </ActionButton>
        </div>
      </form>
    </>
  )
}
