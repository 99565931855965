import React from 'react'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const TabHeader = (props) => {
  const { tabs, selectedTab, onSelectedTabChange } = props

  return (
    <div className='border-b'>
      <div className='sm:hidden'>
        <select
          id='current-tab'
          name='current-tab'
          className='block w-full rounded-md border-0 py-1.5 pl-3 pr-10 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600'
          defaultValue={selectedTab}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className='hidden sm:block'>
        <nav className='-mb-px ml-2 flex space-x-8'>
          {tabs.map((tab) => (
            <a
              key={tab.name}
              className={classNames(
                selectedTab === tab.name
                  ? 'border-indigo-500 text-indigo-600 '
                  : `border-transparent ${!tab.disabled ? 'text-gray-500 hover:border-indigo-300 hover:text-indigo-700 cursor-pointer' : 'text-gray-200'}`,
                'whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium'
              )}
              aria-current={tab.current ? 'page' : undefined}
              onClick={() => {
                if (!tab.disabled) {
                  onSelectedTabChange(tab.name);
                }
              }}
              style={{ pointerEvents: tab.disabled ? 'none' : 'auto' }}
            >
              {tab.name}
            </a>
          ))}
        </nav>
      </div>
    </div>
  )
}
