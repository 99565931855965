import { createClient } from '@supabase/supabase-js'
import React, { createContext, useContext, useEffect, useState, useMemo } from 'react'

const SUPABASE_URL = process.env.REACT_APP_SUPABASE_URL
const SUPABASE_API_KEY = process.env.REACT_APP_SUPABASE_API_KEY 

const supabase = createClient(SUPABASE_URL, SUPABASE_API_KEY)

const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const [session, setSession] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchSession = async () => {
      const { data: { session } } = await supabase.auth.getSession()
      setSession(session)
      setLoading(false)
    }

    fetchSession()

    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
    })

    return () => subscription?.unsubscribe()
  }, [])

  const value = useMemo(() => ({
    session,
    supabase
  }), [session])

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  return useContext(AuthContext)
}
