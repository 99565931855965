export const FullPageCard = (props) => {
  const { children, noPXY, addClassname } = props
  return (
    <div
      direction='vertical'
      size='middle'
      className={`overflow-visible my-4 w-full ${noPXY ? '' : 'py-4 px-4'} mx-4  bg-white shadow-lg rounded-lg ${addClassname ? addClassname : 'max-w-screen-xl'}`}
    >
      {children}
    </div>
  )
}
