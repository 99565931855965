import React, { useContext, useRef } from 'react';
import { useNavigate } from 'react-router';
import { ObjectHeader } from '../components/header/object-header';
import { FullPageCard } from '../components/misc/full-page-card';
import { LoadingWheel } from '../components/misc/loading-wheel';
import { JTable } from '../components/table/j-table';
import { useAllFrameworkData } from '../hooks/db/useAllFrameworkData';
import { jToaster } from '../misc/j-toaster';
import { Tag } from '../misc/tag';
import { UserInfoContext } from './root-page';

const columns = [
  { key: 'name', label: 'Framework', style: 'text-center break-words ' },
  { key: 'created_at', label: 'Creation Date', render: (e) => <span>{e.created_at.split('T')[0]}</span>, style: 'text-center whitespace-nowrap' },
  { key: 'applicableSafeguardsCount', label: 'Applicable', style: 'text-center whitespace-nowrap', render: (e) => <Tag text={e?.applicableSafeguardsCount + '/' + e?.totalSafeguardsCount} /> },
  { key: 'tag', label: 'Tag', style: 'text-center', render: (e) => <Tag text={e.tag} /> },
]

export const AllFrameworkPage = (navigation, route) => {
  const navigate = useNavigate()

  const { userInfo } = useContext(UserInfoContext)

  const { data, isLoading, error, refetch } = useAllFrameworkData(userInfo?.project?.id);
  const errorShownRef = useRef(false);

  const onRowClick = (e) => {
    navigate('/app/framework/' + e.id)
  }

  if (error && !errorShownRef.current) {
    jToaster.error('Failed to load all framework information. Error code: ' + error.code)
    errorShownRef.current = true;
  }

  if (isLoading) return <LoadingWheel />;
  if (error) return <></>

  return (
    <FullPageCard addClassname="max-w-3xl">
      <ObjectHeader title='Frameworks' />
      <JTable
        columns={columns}
        value={data}
        pagination={false}
        defaultSortedColumn={'creation_date'}
        defaultSortOrder='asc'
        handleRowClick={onRowClick}
        isLoading={isLoading}
      />
    </FullPageCard>
  )
}