import { useEffect, useState } from 'react';

export const useGenericFetchData = (fetchFunction, dependencies = [], skip = false) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    if (!data) setIsLoading(true);
    try {
      const fetchedData = await fetchFunction(...dependencies);

      let finalData = fetchedData
      if (!Array.isArray(fetchedData)) {
        if (fetchedData?.custom_values)
          finalData = { ...fetchedData, ...fetchedData?.custom_values }
      }

      setData(finalData || []);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error);
    }
    if (!data) setIsLoading(false);
  };

  useEffect(() => {
    if (!skip) {
      fetchData();
    } else {
      setIsLoading(false);  // Set loading to false immediately if skipping
    }
  }, dependencies);

  return { data, isLoading, error, refetch: fetchData };
};
