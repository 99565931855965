import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { ObjectHeader } from '../components/header/object-header';
import { ObjectSearchFilter } from '../components/header/object-search-filter';
import { FullPageCard } from '../components/misc/full-page-card';
import { JTable } from '../components/table/j-table';
import { useAllWorkflowData } from '../hooks/db/useAllWorkflowData';
import { Tag } from '../misc/tag';
import { jToaster } from '../misc/j-toaster';
import { LoadingWheel } from '../components/misc/loading-wheel';

// Move workflow columns outside the component to avoid re-creation on every render
const workflowColumns = [
  { label: 'Title', key: 'name', style: 'break-words w-full' },
  { label: 'Creation Date', key: 'created_at', render: (e) => <span>{e.created_at.split('T')[0]}</span>, style: 'text-center whitespace-nowrap' },
  { label: 'Step Count', key: 'step_count', sortable: true, style: 'text-center whitespace-nowrap', render: (e) => <Tag text={e?.step_count} /> },
];

export const AllWorkflowPage = () => {
  const navigate = useNavigate();

  // Load all workflow data
  const { allWorkflowData, isLoading, error, refetch } = useAllWorkflowData();
  const errorShownRef = useRef(false);

  const [searchTerm, setSearchTerm] = useState('');
  const [filteredValues, setFilteredValues] = useState([]);

  // Handle filter change immediately without debounce
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.checked);
  };

  useEffect(() => {
    if (allWorkflowData && Array.isArray(allWorkflowData)) { // Ensure allWorkflowData is an array
      let filtered = allWorkflowData;

      if (searchTerm) {
        const lowerSearchTerm = searchTerm.toLowerCase();
        filtered = allWorkflowData.filter((item) =>
          item?.name?.toLowerCase().includes(lowerSearchTerm)  // Proper case-insensitive filtering
        );
      }

      setFilteredValues(filtered);
    } else {
      setFilteredValues([]); // Fallback to empty if allWorkflowData is undefined or not an array
    }
  }, [allWorkflowData, searchTerm]);

  const onRowClick = (workflow) => {
    navigate(`/app/workflow/${workflow.id}`);
  };

  const handleNewWorkflowClick = () => {
    navigate('/app/workflow/new');
  };

  if (error && !errorShownRef.current) {
    jToaster.error('Failed to load all workflow information. Error code: ' + error.code)
    errorShownRef.current = true;
  }

  if (isLoading) return <LoadingWheel />;
  if (error) return <></>

  return (
    <FullPageCard addClassname="max-w-3xl">
      <ObjectHeader
        title="Workflows"
        primaryButtonText="New Workflow"
        onPrimaryButtonClick={handleNewWorkflowClick}
      />
      <ObjectSearchFilter
        onFilterChange={handleSearchChange}
        searchValue={searchTerm}
      />

      <JTable
        columns={workflowColumns}
        value={filteredValues}
        handleRowClick={onRowClick}
        pagination={true} // Explicitly set to `true` instead of 'True'
        isLoading={isLoading}
      />
    </FullPageCard>
  );
};
