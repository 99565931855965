// useTask.js
import { useEffect, useState } from 'react';
import { fetchTasksByExecutor } from '../../services/taskService';

export const useTaskByExecutor = (supabase, userId, groupIds) => {
  const [taskData, setTaskData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const data = await fetchTasksByExecutor(supabase, userId, groupIds);
      setTaskData(data)
    } catch (error) {
      console.error('Error fetching task data:', error);
      setError(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (userId) fetchData();
  }, [supabase, userId, groupIds]);


  return { taskData, isLoading, error, refetch: fetchData };
};
