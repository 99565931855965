import React, { useContext, useRef, useState } from 'react'

import { useNavigate, useParams } from 'react-router'
import uuid from 'react-uuid'
import { ObjectHeader } from '../components/header/object-header'
import { FullPageCard } from '../components/misc/full-page-card'
import { LoadingWheel } from '../components/misc/loading-wheel'
import { RiskDetail } from '../components/risk/risk-detail'
import { RiskForm } from '../components/risk/risk-form'
import { useRiskData } from '../hooks/db/useRiskData'
import { useDeleteConfirmationDialog } from '../hooks/useDeleteConfirmationDialog'
import { jToaster } from '../misc/j-toaster'
import { riskService } from '../services/riskService'
import { useAuth } from '../supabase'
import { UserInfoContext } from './root-page'

export const RiskPage = () => {
  const navigate = useNavigate()
  let { id } = useParams()

  if (id === 'new') id = undefined

  const { supabase } = useAuth()
  const { userInfo } = useContext(UserInfoContext);

  const { data, error, isLoading, refetch } = useRiskData(supabase, id, id === undefined);
  const errorShownRef = useRef(false);

  const { openDialog, DeleteConfirmationDialog } = useDeleteConfirmationDialog();
  const [isEditing, setIsEditing] = useState(!id)
  const [isSaving, setIsSaving] = useState(false)

  const handleEditClick = () => setIsEditing(true)

  const handleCancelEdit = () => {
    if (id === undefined) navigate('/app/risk')

    else setIsEditing(false);
  }

  const handleTagChange = async (status) => {
    try {
      const { _data, error } = await supabase.from('risk').update({ status: status }).eq('id', id)
      if (error) throw error
      refetch()
    } catch (error) {
      console.error('Error updating risk:', error)
    }
  }

  const handleDelete = async () => {
    openDialog(async () => {
      try {
        const { error } = await supabase
          .from('risk') // Assuming 'user' is your table name
          .delete()
          .match({ id: id }) // Matching the user with the id

        if (error) throw error;

        jToaster.success(`Risk has been deleted.`)
        navigate('/app/risk')
      } catch (error) {
        jToaster.error('Failed to delete the risk. Error code: ' + error?.code);
        console.error('Failed to delete the risk', error)
      }
    })
  }

  const handleSave = async (riskValues) => {
    setIsSaving(true)
    try {
      const isNew = riskValues?.id === undefined
      const riskId = isNew ? uuid() : riskValues?.id

      let payload = {
        id: riskId,
        userdef_id: riskValues.userdef_id,
        title: riskValues.title,
        description: riskValues.description,

        impact: riskValues.impact,
        probability: riskValues.probability,
        risk_score: riskValues.risk_score,
        strategy: riskValues.strategy,

        fk_project_id: userInfo.project.id,
        fk_owner_group_id: riskValues.owner.group ? riskValues.owner.id : null,
        fk_owner_user_id: !riskValues.owner.group ? riskValues.owner.id : null,

        custom_values: riskValues?.custom_values,
      }

      await riskService.upsertRisk(supabase, payload);

      if (isNew) jToaster.success(`Risk "${riskValues?.userdef_id}" has been successfully created.`);

      if (isNew) {
        setIsEditing(false)
        setIsSaving(false)
        navigate(`/app/risk/${riskId}`);
      }

      else {
        await refetch();
        setIsSaving(false)
        setIsEditing(false);
      }
    } catch (error) {
      jToaster.error('Failed to save risk. Error code:' + error?.code)
      console.error('Failed to save risk', error)
    }
  }

  const actions_buttons = [
    {
      text: 'Active',
      onClickHandler: () => {
        handleTagChange('ACTIVE')
      },
      disabled: data?.status === 'ACTIVE'
    },
    {
      text: 'Draft',
      onClickHandler: () => {
        handleTagChange('DRAFT')
      },
      disabled: data?.status === 'DRAFT'
    },
    {
      text: 'Archived',
      onClickHandler: () => {
        handleTagChange('ARCHIVED')
      },
      disabled: data?.status === 'ARCHIVED'
    }
  ]

  if (error && !errorShownRef.current) {
    jToaster.error('Failed to load risk information. Error code: ' + error.code)
    console.error('Failed to load risk information. Error code: ', error)
    errorShownRef.current = true;
  }

  if (isLoading || isSaving) return <LoadingWheel />;
  if (error) return <></>

  return (
    <FullPageCard>
      <DeleteConfirmationDialog />

      {!isEditing && data &&
        <>
          <ObjectHeader
            title={data?.userdef_id ? data.userdef_id : 'Risk'}
            backUrl={-1}
            actionButtonText={'Tag'}
            actionButtonOptions={actions_buttons}

            secondaryButtonText={'Delete'}
            onSecondaryButtonClick={handleDelete}

            primaryButtonText={'Edit'}
            onPrimaryButtonClick={handleEditClick}

            noBorderBottom={true}
          />

          <RiskDetail
            riskData={data}
            refetch={refetch}
          />
        </>
      }

      {isEditing &&
        <>
          <ObjectHeader title={"Edit Risk"} />
          <RiskForm
            initialData={data}
            customFields={userInfo.customFields?.risk}
            onSubmit={handleSave}
            onCancel={handleCancelEdit}
          />
        </>
      }
    </FullPageCard>
  )
}
