import React, { useEffect, useRef, useState } from 'react';

import { useNavigate, useParams } from 'react-router';
import { ElementDetail } from '../components/element/element-detail';
import { ObjectHeader } from '../components/header/object-header';
import { FullPageCard } from '../components/misc/full-page-card';
import { LoadingWheel } from '../components/misc/loading-wheel';
import { UserForm } from '../components/stakeholder/user-form';
import { JTable } from '../components/table/j-table';
import { useAuth } from '../supabase';
import { useDeleteConfirmationDialog } from '../hooks/useDeleteConfirmationDialog';
import { jToaster } from '../misc/j-toaster';
import { userService } from '../services/userService';
import { useGenericFetchData } from '../hooks/db/useGenericFetchData';
import { jFormatDateAndTime } from '../components/misc/columns_def';
import { Tag } from '../misc/tag';

const jtable_group_columns = [
  {
    key: "name",
    label: "name",
    style: "text-center w-full",
  },
]

const user_spec = [
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'email',
    label: 'Email',
  },
  {
    key: 'department',
    label: 'Department',
  },

  {
    key: 'created_at',
    label: 'Creation Date',
    render: (e) => <span>{jFormatDateAndTime(e?.created_at)}</span>
  },
  {
    key: 'div1',
    type: 'divider',
    label: 'Security'
  },
  {
    key: 'mfa',
    label: 'MFA',
    render: (e) => {
      if (!e?.mfa_required) return <Tag text='NOT REQUIRED' type='yellow' />
      else if (e?.mfa_required && !e?.mfa_factor_id) return <Tag text='REQUIRED BUT NOT SET' type='yellow' />
      else if (e?.mfa_required && e?.mfa_factor_id) return <Tag text='ACTIVE' />
    }
  },
  {
    key: 'type',
    label: 'Type',
  },
  {
    key: 'group',
    label: 'Groups',
    render: (data) => (
      <JTable
        columns={jtable_group_columns}
        value={data?.group}
        canEditData={false}
      />
    )
  },
]

export const UserDetailPage = (props) => {
  const navigate = useNavigate();
  let { id } = useParams();

  if (id === "new") id = undefined;

  const { supabase } = useAuth();
  const [isEditing, setIsEditing] = useState(id ? false : true);
  const [isSaving, setIsSaving] = useState(false);

  const { openDialog, DeleteConfirmationDialog } = useDeleteConfirmationDialog();
  const { data, isLoading, error, refetch } = useGenericFetchData(userService.getUserById, [supabase, id], id === undefined);
  const errorShownRef = useRef(false);

  const actions_buttons = [
    {
      text: 'Reset MFA',
      onClickHandler: async () => {
        const { data, error } = await supabase.functions.invoke('user_reset_mfa', { body: { id } })

        if (error) {
          jToaster.error('An unexpected error occured')
          console.error('An unexpected error occured', data)
        }
        else {
          jToaster.success('The MFA factor for the user has been successfully removed. The user will be prompted to register a new MFA during their next login.');
          refetch()
        }
      },
    },
    {
      text: 'Reset Password',
      onClickHandler: async () => {
        const { data, error } = await supabase.functions.invoke('user_reset_password', { body: { id } })

        if (error) {
          jToaster.error('An unexpected error occured')
          console.error('An unexpected error occured', data)
        }
        else {
          jToaster.success("The user's password has been successfully reset. They have been sent a new invitation link to set a new password.");
          refetch()
        }
      },
    },
  ]

  const handleDelete = async () => {
    openDialog(async () => {
      try {
        const { error } = await supabase.functions.invoke('user_delete', { body: JSON.stringify({ id: id }) });

        if (error) throw error;

        jToaster.success(`User "${data?.name}" has been deleted.`)
        navigate('/app/stakeholders')
      } catch (error) {
        jToaster.error('Failed to delete the user. Error code: ' + error?.code);
      }
    })
  }

  const handleCancelEdit = () => {
    if (id === undefined) navigate('/app/stakeholders')

    else setIsEditing(false);
  }

  const handleSave = async (values) => {
    setIsSaving(true)

    //Insert
    try {
      if (values?.id === undefined) {
        const { error } = await supabase.functions.invoke('user_insert', {
          body: {
            name: values?.name,
            email: values?.email,
            department: values?.department,
            type: values?.type,
            mfa_required: values?.mfa_required === 'enabled' ? true : false
          },
        })
        if (error) throw error

        jToaster.success(`User "${values?.name}" has been successfully created.`)
        navigate('/app/stakeholders')
      }

      else {
        const { error } = await supabase.functions.invoke('user_update', {
          body: {
            id: values?.id,
            name: values?.name,
            group: values?.group,
            department: values?.department,
            type: values?.type,
            mfa_required: values?.mfa_required === 'enabled' ? true : false
          },
        })

        if (error) throw error

        jToaster.success(`User "${values?.name}" has been successfully updated.`)
        navigate('/app/stakeholders')
      }
    } catch (error) {
      jToaster.error(`Failed to update user "${values?.name}".`)
      console.error('Failed to update user', error, data)
    }
    setIsSaving(false)
  }

  if (error && !errorShownRef.current) {
    jToaster.error('Failed to load user information. Error code: ' + error.code)
    errorShownRef.current = true;
  }

  if (isLoading || isSaving) return <LoadingWheel />;
  if (error) return <></>

  return (
    <FullPageCard>
      <DeleteConfirmationDialog />

      {!isEditing &&
        <>
          <ObjectHeader
            title={"User"}
            backUrl={-1}

            actionButtonText={'Action'}
            actionButtonOptions={actions_buttons}

            secondaryButtonText={"Delete"}
            onSecondaryButtonClick={handleDelete}

            primaryButtonText={"Edit"}
            onPrimaryButtonClick={() => { setIsEditing(true) }}
          />
          <ElementDetail
            detailSpec={user_spec}
            data={data}
          />
        </>
      }
      {isEditing &&
        <>
          <ObjectHeader
            title={"User"}
            backUrl={-1}
          />
          <UserForm
            initialData={data}
            onSubmit={handleSave}
            onCancel={handleCancelEdit}
          />
        </>
      }
    </FullPageCard>
  );
}