import uuid from "react-uuid";

export const fetchTaskData = async (supabase, id = null, objectId = null, is_scheduled = null) => {
  let query = supabase
    .from('task')
    .select(`
      *,
      af_task_result_attachment(*),
      workflow_template(*),
      control!left(*),
      real_executor:fk_real_executor!left(name, id), 
      executor_group:fk_executor_group_id!left(name, id),  
      executor_user:fk_executor_user_id!left(name, id),
      task_validation_step(
        *, 
        assigned_user:task_validation_step_fk_assigned_user_id_fkey(*),
        assigned_group:task_validation_step_fk_assigned_group_id_fkey(*),
        user_validated:task_validation_step_fk_user_validated_id_fkey(*)
      )
    `)
    .order('created_at', { ascending: false });

  // If id is provided, fetch a specific task by id
  if (id) {
    query = query.eq('id', id);
  }

  // If objectId is provided, fetch tasks related to the specific object
  if (objectId) {
    query = query.eq('fk_associated_object_id', objectId);
  }

  if (is_scheduled !== null) {
    query = query.eq('is_scheduled', is_scheduled);
  }

  const { data, error } = await query;

  if (error) {
    throw error;
  }

  // Process all tasks in the result
  const processedData = (data ?? []).map((task) => {
    // If task_validation_step exists, sort and find the next pending step
    if (task?.task_validation_step && task.task_validation_step.length > 0) {
      // Sort task_validation_step by step_count in ascending order
      task.task_validation_step = task.task_validation_step.sort((a, b) => a.step_number - b.step_number);
    }
    return task;
  });

  return processedData;
};

export const fetchTasksByExecutor = async (supabase, userId, groupIds) => {
  const { data, error } = await supabase
    .from('task')
    .select(`
      *,
      executor_user:fk_executor_user_id!left(name),
      executor_group:fk_executor_group_id!left(name),  
      task_validation_step(*)
    `)
    .or(`fk_executor_user_id.eq.${userId},fk_executor_group_id.in.(${groupIds})`)
    .eq('status', 'TODO'); // Filter to only get pending tasks

  if (error) {
    throw error;
  }

  return data;
};

export const fetchTasksWithPendingValidationStep = async (supabase, userId, groupIds) => {
  const { data, error } = await supabase
    .from('user_tasks_view')  // Query the view
    .select('*')
    .or(`fk_assigned_user_id.eq.${userId}, fk_assigned_group_id.in.(${groupIds})`)
    .eq('task_status', 'PENDING_APPROVAL')
    .eq('step_status', 'PENDING');

  if (error) {
    throw error;
  } else {
    return data;
  }
};

export const updateTaskStatus = async (supabase, id, status, userId) => {
  const payload = {
    fk_real_approbator: userId,
    last_update: 'now()',
    status: status
  };

  const { data, error } = await supabase
    .from('task')
    .update(payload)
    .eq('id', id);

  if (error) {
    throw error;
  }

  return data;
};

export const deleteTask = async (supabase, id) => {
  const { error } = await supabase.from('task').delete().eq('id', id);

  if (error) {
    throw error;
  }
};

export const fetchAllTask = async (supabase, project_id) => {
  const { data, error } = await supabase
    .from('task')
    .select(`
    *,
    executor_group:group(name, id),
    executor_user:user!task_fk_executor_user_id_fkey(name, id)
  `)
    .eq('fk_project_id', project_id)
    .order('created_at', { ascending: false })

  if (error) throw error;
  return data
}