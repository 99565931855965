// useTask.js
import { useEffect, useRef, useState } from 'react';
import { riskService } from '../../services/riskService';

export const useRiskData = (supabase, id, skip = false) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    if (!data) setIsLoading(true);
    try {
      const data = await riskService.getRiskById(supabase, id);

      // Map the safeguards and add the framework name to each safeguard
      if (data && data.framework_safeguard) {
        data.framework_safeguard = data.framework_safeguard.map((safeguard) => ({
          ...safeguard,
          framework_name: safeguard.framework?.name || 'Unknown Framework', // Add framework name to each safeguard
        }))
      }
      const dataFinal = {...data, ...data.custom_values};

      setData(dataFinal ?? {})
    } catch (error) {
      setError(error);
    }
    if (!data) setIsLoading(false);
  };

  useEffect(() => {
    if (!skip && id) {
      fetchData();
      //hasFetchedData.current = true;
    } else {
      setIsLoading(false);  // Set loading to false immediately if skipping
    }
  }, [supabase, id]);

  return { data, isLoading, error, refetch: fetchData };
};
