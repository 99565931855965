import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { jToaster } from '../../misc/j-toaster'
import { UserInfoContext } from '../../page/root-page'
import { useAuth } from '../../supabase'
import { ElementDetail } from '../element/element-detail'
import { LoadingWheel } from '../misc/loading-wheel'

const Drawer = (props) => {
  const {children, open, onClose} = props;

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter='ease-in-out duration-500'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in-out duration-500'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 overflow-hidden'>
          <div className='absolute inset-0 overflow-hidden'>
            <div className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10'>
              <Transition.Child
                as={Fragment}
                enter='transform transition ease-in-out duration-500 sm:duration-700'
                enterFrom='translate-x-full'
                enterTo='translate-x-0'
                leave='transform transition ease-in-out duration-500 sm:duration-700'
                leaveFrom='translate-x-0'
                leaveTo='translate-x-full'
              >
                <Dialog.Panel className='pointer-events-auto relative w-screen max-w-screen-md'>
                  <Transition.Child
                    as={Fragment}
                    enter='ease-in-out duration-500'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='ease-in-out duration-500'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                  >
                    <div className='absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4'>
                      <button
                        type='button'
                        className='relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white'
                        onClick={onClose}
                      >
                        <span className='absolute -inset-2.5' />
                        <span className='sr-only'>Close panel</span>
                        <XMarkIcon className='h-6 w-6' aria-hidden='true' />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className='flex h-full flex-col overflow-y-scroll bg-white py-4 shadow-xl'>
                    <div className='px-4 sm:px-6'>
                      <Dialog.Title className='text-base font-semibold leading-6 uppercase text-gray-900'>AUDIT LOG</Dialog.Title>
                    </div>
                    <div className='relative mt-6 flex-1 px-4 border-t pt-2 sm:px-6'>
                      {children}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

const detail_spec = [
  {
    key: 'id',
    label: 'ID'
  },
  {
    key: 'table_name',
    label: 'Table name'
  },
  {
    key: 'changed_by',
    label: 'Changed By',
  },
  {
    key: 'operation_type',
    label: 'Operation Type'
  },
  {
    key: 'time',
    label: 'Time'
  },
  {
    key: 'new_value',  
    label: 'New Value',
    render: (e) => (<pre>{JSON.stringify(e?.new_value, null, 2)}</pre>)
  },
  {
    key: 'old_value',  
    label: 'Old Value',
    render: (e) => (<pre>{JSON.stringify(e?.old_value, null, 2)}</pre>)
  }
];

export const AuditDrawer = (props) => {
  const { supabase } = useAuth()

  const { open, onClose, auditId } = props

  const [isLoading, setIsLoading] = useState(false);
  const [auditData, setAuditData] = useState({})

  const fetchData = async () => {
    const { data, error } = await supabase
      .from('audit_log')
      .select(`*`)
      .eq('id', auditId)
      .single()

    if (error){
      jToaster.error('Unexpected error. Error code:' + error?.code)
      console.error('Unexpected error. Error code:' + error?.code)
    }
      
    setAuditData(data ?? {})
    setIsLoading(false)
  }

  useEffect(() => {
    if (auditId) {
      fetchData()
    }
  }, [supabase, auditId])

  useEffect(()=>{
    setIsLoading(true)
  }, [open])

  return (
    <Drawer open={open} onClose={onClose}>
      {isLoading && <LoadingWheel />}
      {!isLoading &&
        <ElementDetail
          data={auditData}
          detailSpec={detail_spec}
        />
      }
    </Drawer>
  )
}
