import React, { useContext, useEffect, useState } from 'react'

import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router'
import { ActionButton } from '../components/form/action-button'
import { ObjectHeader } from '../components/header/object-header'
import { FullPageCard } from '../components/misc/full-page-card'
import { LoadingWheel } from '../components/misc/loading-wheel'
import { PolicyGeneralFormPanel, PolicyRevisionFormPanel } from '../components/policy/policy-form'
import { jToaster } from '../misc/j-toaster'
import { useAuth } from '../supabase'
import { UserInfoContext } from './root-page'

export const EditPolicyPage = () => {
  const navigate = useNavigate()
  const { supabase } = useAuth()
  const { userInfo } = useContext(UserInfoContext)
  let { id, revisionid } = useParams()
  const isNewPolicy = id === undefined

  const defaultValues = {
    userdef_id: '',
    title: '',
    description: '',
    revision_userdef_id: '',
    attachments: [],
    owner: null,
  }

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    watch,
    control
  } = useForm({ defaultValues: defaultValues, mode: 'onBlur' })

  const [isLoading, setIsLoading] = useState(id ? true : false)
  const handleClickCancel = () => { navigate(-1) }

  const onSubmit = async (data) => {
    try {
      setIsLoading(true)

      // Upsert policy revision
      if (revisionid) {
        const payload = {
          id: revisionid,
          description: data.description,
          userdef_id: data.revision_userdef_id,
          fk_policy_id: id,
          attachments: data.attachments ?? [],
          custom_values: data?.custom_values
        };

        const { error: error_upsert } = await supabase
          .functions
          .invoke('policy_version_upsert', { body: JSON.stringify(payload) });

        if (error_upsert) throw error_upsert
        jToaster.success('Policy version updated')
      }

      // Upsert policy general
      else {
        const policyPayload = {
          ...(data?.id && { id: data.id }),
          title: data.title,
          userdef_id: data.userdef_id,
          fk_project_id: userInfo.project.id,
          fk_owner_group_id: data.owner.group ? data.owner.id : null,
          fk_owner_user_id: !data.owner.group ? data.owner.id : null,
        }

        const { error: errorRevision } = await supabase.from('policy').upsert({ ...policyPayload })
        if (errorRevision) throw errorRevision
        jToaster.success(`Policy ${data?.userdef_id} has been successfully created`)
      }

      setIsLoading(false)
      navigate(-1)
    } catch (e) {
      console.error('Failed to edit policy. Error', e)
      jToaster.error('Failed to edit policy. Error code: ' + e?.code)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    //modifie une revision
    if (revisionid) {
      supabase
        .from('policy_version')
        .select('userdef_id, description, custom_values, af_policy_version_attachment(*)')
        .eq('id', revisionid)
        .single()
        .then(({ data, error }) => {
          if (error) {
            console.error('Error fetching revision:', error)
            return
          }

          setValue('id', data.id)
          setValue('revision_userdef_id', data.userdef_id)
          setValue('description', data.description)
          setValue('attachments', data.af_policy_version_attachment)
          setValue('custom_values', data.custom_values)
          setIsLoading(false)
        })
    }
    //modifie une politique
    else if (id) {
      supabase
        .from('policy')
        .select('*, user(*), group(*)')
        .eq('id', id)
        .single()
        .then(({ data, error }) => {
          if (error) {
            console.error('Error fetching group:', error)
            return
          }

          setValue('id', data.id)
          setValue('userdef_id', data.userdef_id)
          setValue('title', data.title)
          setValue('description', data.description)
          if (data.group) {
            setValue('owner', { ...data.group, comboboxName: data.group.name, group: true })
          }
          if (data.user) {
            setValue('owner', { ...data.user, comboboxName: data.user.name, group: false })
          }
          setIsLoading(false)
        })
    }
  }, [id, supabase])

  return (
    <FullPageCard>
      <ObjectHeader
        title={'Policy'}
        backUrl={-1}
      />
      <form id='policy-edit-form' onSubmit={handleSubmit(onSubmit)}>
        {isLoading && <LoadingWheel />}

        {!isLoading && revisionid && !isNewPolicy &&
          <PolicyRevisionFormPanel
            customFields={userInfo.customFields?.policy}
            register={register}
            control={control}
            errors={errors}
          />
        }

        {!isLoading && !revisionid &&
          <PolicyGeneralFormPanel
            register={register}
            control={control}
            errors={errors}
          />
        }

        <div className='flex justify-end gap-2 mt-2 border-t pt-2'>
          <ActionButton
            disabled={isLoading}
            onClick={handleClickCancel}
            styleType='secondary'
            type='button'
          >
            Cancel
          </ActionButton>
          <ActionButton
            disabled={isLoading || !isValid}
            styleType='primary'
            type='submit'
            dataTestid='policy-form-save'
          >
            Save
          </ActionButton>
        </div>
      </form>
    </FullPageCard>
  )
}
