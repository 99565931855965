import React, { useContext, useEffect, useRef, useState } from 'react'

import { useNavigate } from 'react-router'
import { ObjectHeader } from '../components/header/object-header'
import { AuditColumns, ControlColumnFull } from '../components/misc/columns_def'
import { FullPageCard } from '../components/misc/full-page-card'
import { JTable } from '../components/table/j-table'
import { useAuth } from '../supabase'
import { UserInfoContext } from './root-page'
import { AuditDrawer } from '../components/modal/audit-drawer'
import { useGenericFetchData } from '../hooks/db/useGenericFetchData'
import { auditService } from '../services/auditService'
import { LoadingWheel } from '../components/misc/loading-wheel'
import { jToaster } from '../misc/j-toaster'

export const AllAuditPage = (navigation, route) => {
  const { supabase, session } = useAuth()
  const { userInfo } = useContext(UserInfoContext)

  const errorShownRef = useRef(false);
  const { data, error, isLoading } = useGenericFetchData(
    auditService.getAllAudit,
    [supabase, userInfo?.project?.id]
  );

  //Drawer
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [drawerAuditId, setDrawerAuditId] = useState(null);

  const handleRowClick = (e) => {
    setDrawerAuditId(e?.id)
    setIsDrawerOpen(true)
  }

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
  }

  if (error && !errorShownRef.current) {
    jToaster.error('Failed to load all control information. Error code: ' + error.code)
    errorShownRef.current = true;
  }

  if (isLoading) return <LoadingWheel />;
  if (error) return <></>
  
  return (
    <FullPageCard>
      <ObjectHeader title='Audit' />

      <AuditDrawer
        open={isDrawerOpen}
        auditId={drawerAuditId}
        onClose={handleCloseDrawer}
      />
      <JTable
        columns={AuditColumns}
        value={data}
        pagination='True'
        isLoading={isLoading}
        handleRowClick={handleRowClick}
      />
    </FullPageCard>
  )
}
