import { DeleteOutlined, SortAscendingOutlined } from '@ant-design/icons';
import React, { useCallback, useMemo, useState } from 'react';
import { SmallButton } from '../form/action-button';
import { LoadingWheel } from '../misc/loading-wheel';
import { AddGroupPalette } from '../modal/add-group-palette';
import { AddUserPalette } from '../modal/add-user-palette';
import { TablePagination } from './pagination';

// Custom hook for managing sorting logic
const useTableSort = (defaultSortedColumn, defaultSortOrder, columns) => {
  const [sortField, setSortField] = useState(defaultSortedColumn || null);
  const [sortDirection, setSortDirection] = useState(defaultSortOrder || 'asc');

  const handleSort = useCallback((field) => {
    setSortDirection((prev) => (sortField === field ? (prev === 'asc' ? 'desc' : 'asc') : 'asc'));
    setSortField(field);
  }, [sortField]);

  const sortedData = useMemo(() => {
    if (!sortField) return (data) => data;
    return (data) => {
      const sorted = [...data];
      const column = columns[sortField];
      sorted.sort(column?.sort_fn
        ? (a, b) => column.sort_fn(sortDirection, a, b)
        : (a, b) => (a[sortField] < b[sortField] ? -1 : 1) * (sortDirection === 'asc' ? 1 : -1)
      );
      return sorted;
    };
  }, [sortField, sortDirection, columns]);

  return { sortedData, handleSort, sortField, sortDirection };
};

// Custom hook for managing add modal logic
const useAddModal = (onAddUser, currentItems) => {
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);

  const handleAddUser = useCallback(() => setIsAddUserModalOpen(true), []);
  const handleCancel = useCallback(() => setIsAddUserModalOpen(false), []);
  const handleSaveUser = useCallback((newUser) => {
    if (newUser?.id) onAddUser([...currentItems, newUser]);
    handleCancel();
  }, [onAddUser, currentItems, handleCancel]);

  return {
    isAddUserModalOpen,
    handleAddUser,
    handleSaveUser,
    handleCancel
  };
};

export const JTable = ({
  title, pagination, columns, value = [], canEditData, handleRowClick, defaultSortedColumn, defaultSortOrder,
  isLoading, onChange, AddElementModalType, additionalDeleteFunction, addText
}) => {

  const itemsPerPage = 15;
  const [currentPage, setCurrentPage] = useState(1);
  const { sortedData, handleSort, sortField } = useTableSort(defaultSortedColumn, defaultSortOrder, columns);
  const { isAddUserModalOpen, handleAddUser, handleSaveUser, handleCancel } = useAddModal(onChange, value);

  const handleRowDeleteClick = useCallback((e, row) => {
    e.stopPropagation();
    if (additionalDeleteFunction) {
      additionalDeleteFunction(row);
    } else {
      onChange(value.filter((item) => item.id !== row.id));
    }
  }, [value, additionalDeleteFunction, onChange]);

  const paginatedData = useMemo(() => {
    const sorted = sortedData(value);

    if (!pagination) return sorted;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    return sorted.slice(indexOfFirstItem, indexOfLastItem);
  }, [sortedData, value, pagination, currentPage, itemsPerPage]);

  return (
    <div className='flex flex-col'>
      <div className='inline-block min-w-full align-middle'>
        <table data-testid='j-table' className='min-w-full divide-y divide-gray-300 border border-gray-300'>
          <thead className='cursor-default uppercase'>
            {title && (
              <tr className='bg-gray-200 border-b-2'>
                <th colSpan={columns.length + (canEditData ? 1 : 0)} className='px-3 py-3 text-left text-xs font-bold text-gray-900'>
                  {title}
                </th>
              </tr>
            )}
            <tr className='bg-gray-100'>
              {columns.map((col) => (
                <th
                  key={col.key}
                  scope='col'
                  className={`
                    px-3 py-2 text-xs font-bold uppercase text-gray-900 text-center
                    ${col.style} 
                    ${col.sortable ? 'cursor-pointer hover:bg-gray-200' : ''} 
                    ${sortField === col.key ? 'bg-gray-300' : ''}
                  `}
                  onClick={() => col.sortable && handleSort(col.key)}
                >
                  <div className='flex items-center justify-center'>
                    {col.label}
                    {col.sortable && <SortAscendingOutlined className='ml-2' />}
                  </div>
                </th>
              ))}
              {canEditData && <th key={'delete'} className='w-2'></th>}
            </tr>
          </thead>
          <tbody className='divide-y divide-gray-200 bg-white'>
            {paginatedData.map((rowData) => (
              <tr key={rowData.id} className='hover:bg-gray-100 cursor-pointer' onClick={() => handleRowClick && handleRowClick(rowData)}>
                {columns.map((column) => (
                  <td key={column.key} className={`py-1 pr-3 text-sm text-gray-700 px-4 ${column.style}`}>
                    {column.render ? column.render(rowData) : rowData[column.key]}
                  </td>
                ))}
                {canEditData && (
                  <td className='h-full px-4 w-2'>
                    <SmallButton onClick={(e) => handleRowDeleteClick(e, rowData)}>
                      <DeleteOutlined />
                    </SmallButton>
                  </td>
                )}
              </tr>
            ))}
            {!isLoading && value.length === 0 && (
              <tr>
                <td colSpan={columns.length + (canEditData ? 1 : 0)} className='text-center py-4'>
                  <h3 className='text-sm font-medium text-gray-900'>No Data</h3>
                </td>
              </tr>
            )}
            {isLoading && (
              <tr>
                <td colSpan={columns.length + (canEditData ? 1 : 0)} className='text-center py-4'>
                  <LoadingWheel />
                </td>
              </tr>
            )}
          </tbody>
        </table>

        {pagination && (
          <TablePagination
            itemsPerPage={itemsPerPage}
            total={value.length}
            currentPage={currentPage}
            onPageChange={setCurrentPage}
          />
        )}
      </div>

      {canEditData && (
        <div className='mt-2 self-end'>
          <SmallButton data-testid='jtable-add-button' onClick={handleAddUser}>
            {addText || 'Add'}
          </SmallButton>
          {AddElementModalType === 'user' && (
            <AddUserPalette
              onAdd={handleSaveUser}
              onClose={handleCancel}
              isOpen={isAddUserModalOpen}
              alreadyused={value}
            />
          )}
          {AddElementModalType === 'group' && (
            <AddGroupPalette
              onAdd={handleSaveUser}
              onClose={handleCancel}
              isOpen={isAddUserModalOpen}
              alreadyused={value}
            />
          )}
        </div>
      )}
    </div>
  );
};
