import { useContext, useEffect, useState } from 'react'
import { UserInfoContext } from '../../page/root-page'
import { useAuth } from '../../supabase'
import { AddPalette } from './add-palette'

export const AddCompliancePalette = (props) => {
  const { onAdd, onClose, isOpen, alreadyused } = props

  const { supabase } = useAuth()
  const { userInfo } = useContext(UserInfoContext)

  const [isLoading, setIsLoading] = useState(true)
  const [frameworkOptions, setFrameworkOptions] = useState([])  // For framework palette
  const [selectedFramework, setSelectedFramework] = useState(null)  // For selected framework
  const [sfOptions, setSfOptions] = useState([])  // For framework_safeguard options

  const fetchFrameworks = async () => {
    try {
      const { data: complianceData, error: userError } = await supabase
        .from('framework')
        .select('*, framework_safeguard(id, userdef_id, title)')
        .eq('fk_project_id', userInfo?.project?.id)
        .order('order', { foreignTable: 'framework_safeguard', ascending: true })

      if (userError) throw userError

      // Map frameworks to options for the first AddPalette
      setFrameworkOptions(
        complianceData.map((f) => ({
          ...f,
          comboboxName: f.name
        }))
      )
    } catch (error) {
      console.error('Failed to fetch frameworks:', error)
    }

    setIsLoading(false)
  }

  const handleFrameworkSelect = (framework) => {
    setSelectedFramework(framework)

    // Filter out already used safeguards
    const filteredSafeguards = framework.framework_safeguard.filter((safeguard) => 
      !alreadyused.map((e) => e.userdef_id).includes(safeguard.userdef_id)
    )

    // Set filtered safeguards for the second AddPalette
    setSfOptions(
      filteredSafeguards.map((sf) => ({
        ...sf,
        comboboxName: sf.userdef_id + ': ' + sf.title
      }))
    )
  }

  useEffect(() => {
    if (isOpen) {
      fetchFrameworks()
    }
  }, [isOpen])

  return (
    <>
      {/* First AddPalette for selecting Framework */}
      <AddPalette 
        options={frameworkOptions} 
        onClick={handleFrameworkSelect}  // When framework is selected
        loading={isLoading} 
        open={isOpen && !selectedFramework}  // Only show if no framework is selected
        onClose={onClose} 
      />

      {/* Second AddPalette for selecting associated SF (Safeguards) */}
      {selectedFramework && (
        <AddPalette 
          options={sfOptions} 
          onClick={(sf) => onAdd(sf)}  // Pass both framework and SF
          loading={isLoading} 
          open={isOpen && !!selectedFramework}  // Only show if a framework is selected
          onClose={() =>{ setSelectedFramework(null); onClose()}}  // Close and reset framework selection
        />
      )}
    </>
  )
}
