import React, { useContext, useState } from 'react'
import { ElementDetail } from '../element/element-detail'
import { ConnectionTable } from '../table/connection-table'
import { UserInfoContext } from '../../page/root-page'
import { useAuth } from '../../supabase'
import { ComplianceApplicableTagRender, ComplianceMaturity } from '../misc/columns_def'

const tabs = [
  { name: 'Description', href: '' },
  { name: 'Connections', href: '' }
]

const getComplianceSpec = (customFields) => {
  return [
    {
      key: 'applicable',
      label: 'Applicable',
      render: (e) => ComplianceApplicableTagRender(e?.applicable)
    },
    {
      key: 'maturity',
      label: 'Maturity',
      render: (e) => ComplianceMaturity(e?.maturity)
    },
    { type: 'divider', label: 'description', },
    { key: 'userdef_id', label: 'Safeguard ID', },
    { key: 'title', label: 'Title', },
    { key: 'description', label: 'Description', },
    ...(customFields && customFields.length > 0
      ? [
        { type: 'divider', label: 'Custom Fields', key: 'divcustomfields' },
        ...customFields.map((field) => ({
          key: field.field_name,
          label: field.field_name,
          render: field.field_type === 'date' ? (e) => (<span>{JFormatDate(e[field.field_name])}</span>) : undefined, // Only define render if the field is a date
        }))
      ]
      : []
    ),
  ]
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const ComplianceDetail = (props) => {
  const { userInfo } = useContext(UserInfoContext)
  const { supabase } = useAuth()

  const { complianceData, refetch } = props

  const compliance_spec = getComplianceSpec(userInfo?.customFields?.compliance)
  const [selectedTab, setSelectedTab] = useState('Description')

  const onSelectedTabChange = (e) => {
    setSelectedTab(e)
  }

  const handleNewConnection = async (type, value) => {
    if (type === 'policy') {
      await supabase.from('af_policy_compliance').upsert({
        fk_framework_safeguard_id: complianceData.id,
        fk_policy_id: value.id,
        fk_project_id: userInfo.project.id
      })
    } else if (type === 'risk') {
      await supabase.from('af_compliance_risk').upsert({
        fk_risk_id: value.id,
        fk_framework_safeguard_id: complianceData.id,
        fk_project_id: userInfo.project.id
      })
    } else if (type === 'control') {
      await supabase.from('af_compliance_control').upsert({
        fk_control_id: value.id,
        fk_framework_safeguard_id: complianceData.id,
        fk_project_id: userInfo.project.id
      })
    }
    await refetch();
  }

  const handleDeleteConnection = async (type, value) => {
    if (type === 'policy') {
      await supabase
        .from('af_policy_compliance')
        .delete()
        .eq('fk_framework_safeguard_id', complianceData.id)
        .eq('fk_policy_id', value.id)
    } else if (type === 'risk') {
      await supabase
        .from('af_compliance_risk')
        .delete()
        .eq('fk_risk_id', value.id)
        .eq('fk_framework_safeguard_id', complianceData.id)
    } else if (type === 'control') {
      await supabase
        .from('af_compliance_control')
        .delete()
        .eq('fk_framework_safeguard_id', complianceData.id)
        .eq('fk_control_id', value.id)
    }
    await refetch();
  }

  return (
    <>
      <div className='border-b'>
        <div className='sm:hidden'>
          <select
            id='current-tab'
            name='current-tab'
            className='block w-full rounded-md border-0 py-1.5 pl-3 pr-10 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600'
            defaultValue={selectedTab}
          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className='hidden sm:block'>
          <nav className='-mb-px ml-2 flex space-x-8'>
            {tabs.map((tab) => (
              <a
                key={tab.name}
                className={classNames(
                  selectedTab === tab.name
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium'
                )}
                aria-current={tab.current ? 'page' : undefined}
                onClick={() => {
                  onSelectedTabChange(tab.name)
                }}
              >
                {tab.name}
              </a>
            ))}
          </nav>
        </div>
      </div>

      <div>
        {selectedTab === 'Description' &&
          <ElementDetail
            detailSpec={compliance_spec}
            data={complianceData}
          />
        }
        {selectedTab === 'Connections' &&
          <ConnectionTable
            value={complianceData}
            canEditData={true}

            onNewConnection={handleNewConnection}
            onDeleteConnection={handleDeleteConnection}
          />
        }
      </div>
    </>
  )
}
