import './App.css'


import React from 'react'
import { Route, Routes } from 'react-router'

import { ToastContainer } from 'react-toastify'
import { AllAuditPage } from './page/all-audit-page'
import { AllControlPage } from './page/all-control-page'
import { AllFrameworkPage } from './page/all-framework-page'
import { AllPolicyPage } from './page/all-policy-page'
import { AllRiskPage } from './page/all-risk-page'
import { AllTaskPage } from './page/all-task'
import { AllWorkflowPage } from './page/all-workflow-page'
import { LoginPage } from './page/auth/login-page'
import { UpdatePasswordPage } from './page/auth/update-password-page'
import { CompliancePage } from './page/compliance-page'
import { ControlPage } from './page/control-page'
import { EditPolicyPage } from './page/edit-policy-page'
import { FrameworkPage } from './page/framework-page'
import { GroupDetailPage } from './page/group-detail-page'
import { PolicyPage } from './page/policy-page'
import { ReportPage } from './page/report-page'
import { RiskPage } from './page/risk-page'
import { RootPage } from './page/root-page'
import { StakeholderPage } from './page/stakeholder-page'
import { TaskPage } from './page/task-page'
import { TodoPage } from './page/todo-page'
import { UserDetailPage } from './page/user-detail-page'
import { WorkflowPage } from './page/workflow-page'

import 'react-toastify/dist/ReactToastify.css'
import { MfaVerificationPage } from './page/auth/mfa-verification-page'

function App() {
  return (
    <>
      <ToastContainer />
      <Routes>
        {/* Public */}
        <Route path='/auth/'>
          <Route index path='login' element={<LoginPage />} />
          <Route index path='mfa-verification' element={<MfaVerificationPage />} />
          <Route index path='mfa-verification/:factorId' element={<MfaVerificationPage />} />
          <Route index path='update-password' element={<UpdatePasswordPage />} />
        </Route>

        {/* Protected */}
        <Route path='/app/' element={<RootPage />}>
          <Route key='blank' index path='blank' element={<div></div>} />
          <Route key='allframeworkpage' index path='framework' element={<AllFrameworkPage />} />
          <Route key='frameworkpage' index path='framework/:id' element={<FrameworkPage />} />
          <Route key='compliancepage' path='compliance/:id' element={<CompliancePage />} />
          <Route key='todopage' index path='todo' element={<TodoPage />} />
          <Route key='allauditpage' index path='audit' element={<AllAuditPage />} />
          <Route key='compliancepage' path='compliance/:id' element={<CompliancePage />} />
          <Route key='reportpage' index path='report' element={<ReportPage />} />

          <Route key='allworkflowpage' index path='workflow' element={<AllWorkflowPage />} />
          <Route key='workflowpage' index path='workflow/:id' element={<WorkflowPage />} />

          <Route key='allpolicypage' index path='policy' element={<AllPolicyPage />} />
          <Route key='newpolicypage' path='policy/new' element={<EditPolicyPage />} />
          <Route key='editpolicy' path='policy/:id/:revisionid/edit' element={<EditPolicyPage />} />
          <Route key='editpolicy' path='policy/:id/edit' element={<EditPolicyPage />} />
          <Route key='policypage' path='policy/:id' element={<PolicyPage />} />

          <Route key='allcontrolpage' index path='control' element={<AllControlPage />} />
          <Route key='controlpage' path='control/:id' element={<ControlPage />} />
          <Route key='alltaskpage' index path='task' element={<AllTaskPage />} />
          <Route key='taskpage' path='task/:id' element={<TaskPage />} />
          <Route key='allriskpage' index path='risk' element={<AllRiskPage />} />
          <Route key='riskpage' path='risk/:id' element={<RiskPage />} />
          <Route key='stakeholderpage' index path='stakeholders' element={<StakeholderPage />} />
          <Route key='groupdetailpage' path='group/:id' element={<GroupDetailPage />} />
          <Route key='userdetailpage' path='user/:id' element={<UserDetailPage />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;