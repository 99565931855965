import { Controller, useForm } from "react-hook-form";
import { ActionButton } from "../form/action-button";
import { AddStakeholderInput } from "../form/add-stakeholder-input";
import { TextInput } from "../form/inputs";
import { JScheduler } from "../form/j-scheduler";
import { AddWorkflowInput } from "../form/add-workflow-input";

const errorMessages = {
  required: "This field is required",
  maxLength: (len) => `Maximum length is ${len} characters`,
};

export const TaskForm = ({ onSubmit }) => {
  const defaultValues = {
    userdef_id: "",
    description: "",
    duedate: "",
    executor: "",
    workflow_template_id: "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    control,
  } = useForm({
    defaultValues,
    mode: "onBlur",
  });

  return (
    <form id="new-task-form" onSubmit={handleSubmit(onSubmit)} className="space-y-4">
      <TextInput
        label="ID *"
        name="userdef_id"
        errorMessage={errors?.userdef_id ? errorMessages.maxLength(12) : null}
        register_fn={() => {
          return register("userdef_id", { required: true, maxLength: 12 });
        }}
      />
      <TextInput
        label="Title *"
        name="title"
        errorMessage={errors?.title ? errorMessages.maxLength(128) : null}
        register_fn={() => {
          return register("title", { required: true, maxLength: 128 });
        }}
      />
      <TextInput
        label="Description"
        name="description"
        rows={5}
        register_fn={() => {
          return register("description", { required: false });
        }}
      />

      <div className="my-6 h-0.5 bg-gradient-to-r from-gray-300 via-gray-400 to-gray-500"></div>

      <Controller
        control={control}
        name="duedate"
        render={({ field: { value, onChange } }) => (
          <JScheduler label="Due Date" value={value} onChange={onChange} />
        )}
      />

      <label htmlFor={"executor"} className='block text-sm font-medium text-gray-800'>
        Assigned to *
      </label>
      <Controller
        control={control}
        name="executor"
        rules={{ required: errorMessages.required }}
        render={({ field: { value, onChange } }) => (
          <AddStakeholderInput value={value} onChange={onChange} />
        )}
      />
      {errors.executor && <p className="text-red-500 text-xs italic">{errors.executor.message}</p>}

      <label htmlFor={"workflow_template_id"} className='block text-sm font-medium text-gray-800'>
         Validation Workflow *
      </label>
      <Controller
        control={control}
        name="workflow_template_id"
        rules={{ required: errorMessages.required }}
        render={({ field: { value, onChange } }) => (
          <AddWorkflowInput value={value} onChange={onChange} />
        )}
      />
      {errors.workflow_template_id && <p className="text-red-500 text-xs italic">{errors.workflow_template_id.message}</p>}

      <div className="flex justify-end mt-2">
        <ActionButton type="submit" disabled={!isValid}>
          Create Task
        </ActionButton>
      </div>
    </form>
  );
};
