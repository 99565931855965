
export const userService = {
  // Fetch a single policy by ID
  async getUserById(supabase, id) {
    const { data, error } = await supabase
      .from('user')
      .select('*, group(*)')
      .eq('id', id)
      .single()

    if (error) throw error;
    return data;
  },

  async getGroupById(supabase, id) {
    const { data, error } = await supabase
      .from('group')
      .select('*, user(*)')
      .eq('id', id)
      .single()

    if (error) throw error;
    return data;
  },

  async getAllGroup(supabase, project_id) {
    const { data, error } = await supabase
      .from('group')
      .select('*, user(name, id)')
      .eq('fk_project_id', project_id);

    if (error) throw error;
    return data;
  },

  async getAllUser(supabase, project_id) {
    const { data, error } = await supabase
      .from('user')
      .select('*, group(*)')
      .eq('fk_project_id', project_id);

    if (error) throw error;
    return data;
  }
}