import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import { Tag } from '../../misc/tag'
import { UserInfoContext } from '../../page/root-page'
import { useAuth } from '../../supabase'
import { ObjectSearchFilter } from '../header/object-search-filter'
import { Tooltip } from '../misc/tooltip'
import { JTable } from '../table/j-table'
import { useGenericFetchData } from '../../hooks/db/useGenericFetchData'
import { userService } from '../../services/userService'
import { jToaster } from '../../misc/j-toaster'

const columns = [
  { key: 'name', sortable: true, label: 'Name', style: '' },
  { key: 'email', sortable: true, label: 'Email', style: ' whitespace-nowrap' },
  {
    key: 'created_at',
    sortable: true,
    label: 'Creation Date',
    style: 'text-center whitespace-nowrap',
    render: (e) => {
      const date = new Date(e.created_at)
      const date_f = date.toISOString().split('T')[0]
      return <>{date_f}</>
    }
  },
  {
    key: 'group',
    label: 'Groups',
    style: 'text-center whitespace-nowrap w-4',
    render: (e) => (
      <>
        {e?.group?.length > 0 && (
          <Tooltip
            message={
              <div className='flex flex-col gap-1'>
                {e.group.map((g) => (
                  <span key={g.id}>{g.name}</span>
                ))}
              </div>
            }
          >
            <Tag text={e.group.length} />
          </Tooltip>
        )}
        {e?.group?.length <= 0 && <Tag text={e.group.length} />}
      </>
    )
  },
  {
    key: 'type',
    label: 'Type',
    sortable: true,
    render: (e) => (
      <>
        {e.type === 'NO_ACCESS' && <Tag text={e.type} type='gray' />}
        {e.type === 'CONTRIBUTOR' && <Tag text={e.type} />}
        {e.type === 'SUPERUSER' && <Tag text={e.type} type={'error'} />}
      </>
    ),
    style: 'text-center whitespace-nowrap w-2'
  }
]

// Update filterOptions with the unique values
const filterOptions = [
  {
    section_title: 'Type',
    key: 'type',
    data: [
      { value: 'SUPERUSER', label: 'Superuser' },
      { value: 'CONTRIBUTOR', label: 'Contributor' },
    ]
  }
]

const initializeActiveFilters = () => {
  // Try to get filters from localStorage or set defaults if not found
  const storedFilters = localStorage.getItem('activeFiltersUserTable')
  const initialFilters = storedFilters ? JSON.parse(storedFilters) : {}

  if (!storedFilters) {
    filterOptions.forEach((section) => {
      section.data.forEach((filter) => {
        initialFilters[filter.value] = false // Default state can be set to false or true as needed
      })
    })
  }
  return initialFilters
}

export const StakeholderUserTable = () => {
  const navigate = useNavigate()

  const { supabase } = useAuth()
  const { userInfo } = useContext(UserInfoContext)

  const errorShownRef = useRef(false);
  const { data, isLoading, error } = useGenericFetchData(
    userService.getAllUser,
    [supabase, userInfo?.project?.id]
  );

  const [forceUpdate, setForceUpdate] = useState(false)
  const [filteredValues, setFilteredValues] = useState([])
  const [activeFilters, setActiveFilters] = useState(initializeActiveFilters())

  const handleFilterChange = (e) => {
    const updatedFilters = {
      ...activeFilters,
      [e.target.name]: e.target.checked
    }

    setActiveFilters(updatedFilters)
    localStorage.setItem('activeFiltersUserTable', JSON.stringify(updatedFilters))
  }

  //Filter
  useEffect(() => {
    let filtered = data || []

    // Filter by type
    const activeFilterKeys = Object.keys(activeFilters).filter((key) => activeFilters[key])
    if (activeFilterKeys.length > 0) {
      filtered = filtered.filter((item) => activeFilterKeys.includes(item.type))
    }

    // Filter by search term if applicable
    if (activeFilters['search'] && activeFilters['search'] !== '') {
      filtered = filtered.filter((item) => item?.name?.toLowerCase().includes(activeFilters['search'].toLowerCase()))
    }

    setForceUpdate(!forceUpdate)
    setFilteredValues(filtered)
  }, [data, activeFilters])

  if (error && !errorShownRef.current) {
    jToaster.error('Failed to load user information. Error code: ' + error.code)
    errorShownRef.current = true;
  }
  if (error) return <></>

  return (
    <>
      <ObjectSearchFilter
        onFilterChange={handleFilterChange}
        filterOptions={filterOptions}
        activeFilters={activeFilters}
      />

      <JTable
        columns={columns}
        value={filteredValues}
        handleRowClick={(e) => { navigate('/app/user/' + e.id) }}
        pagination='True'
        defaultSortOrder='asc'
        key={forceUpdate}
        isLoading={isLoading}
      />
    </>
  )
}
