import React, { useEffect, useState } from 'react'

import { RRule } from 'rrule'
import { formatDateToDateWithoutTimezone, formatDateToRRULEString } from '../misc/time.js'
import { JScheduler } from './j-scheduler'

const getRecurrentRule = (value) => {
  let rrule = ''

  rrule += 'DTSTART=' + formatDateToRRULEString(value.dtstart) + ';'
  rrule += 'FREQ=' + value.freq + ';'
  rrule += 'INTERVAL=' + value.interval + ';'

  //DAILY nothing to add
  if (value.freq === 'Weekly' && value.byday?.length > 0) {
    rrule += 'BYDAY=' + value.byday.map((e) => e) + ';'
  } else if (value.freq === 'Monthly') {
    rrule += 'BYMONTHDAY=' + value.bymonthday + ';'
  } else if (value.freq === 'Yearly') {
    rrule += 'BYMONTH=' + value.bymonth + ';'
    rrule += 'BYMONTHDAY=' + value.bymonthday + ';'
  }

  return rrule.slice(0, -1)
}

const getValueFromRecurrentRule = (schedule) => {
  let schedule_dict = new Object()

  schedule.recurrent_str.split(';').forEach((e) => {
    schedule_dict[e.split('=')[0].toLowerCase()] = e.split('=')[1]
  })

  schedule_dict = {
    ...schedule_dict,
    byday: schedule_dict?.byday != undefined ? schedule_dict.byday.split(',') : []
  }

  if (schedule_dict?.dtstart) {
    const isoString = schedule_dict.dtstart.replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3')

    schedule_dict.dtstart = new Date(isoString)
  }

  return schedule_dict
}

const availableFreq = ['Daily', 'Weekly', 'Monthly', 'Yearly']

const availableByDay = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU']

const isInteger = (value) => {
  return /^\+?(0|[1-9]\d*)$/.test(value)
}

export const SchedulerInput = (props) => {
  const { value: initialValue, onChange, disabled } = props

  const [scheduleText, setScheduleText] = useState('')
  const [errorText, setErrorText] = useState('')

  const [value, setValue] = useState(
    initialValue?.recurrent_str
      ? getValueFromRecurrentRule(initialValue)
      : {
        freq: 'Daily',
        interval: '1',
        byday: [],
        bymonthday: '1',
        bymonth: '1',
        dtstart: new Date(new Date().setHours(0, 0, 0, 0))
      }
  )

  useEffect(
    (e) => {
      let isError = false

      if (value.freq === 'Daily' || value.freq === 'Weekly' || value.freq === 'Monthly' || value.freq === 'Yearly') {
        isError = !isInteger(value.interval)
      }

      if (value.freq === 'Monthly' || value.freq === 'Yearly') {
        isError = isError || !isInteger(value.bymonthday)
      }

      if (isError) {
        setErrorText('Must be a valid positive number')
        onChange(null)
        return
      }

      setErrorText(null)
      const recurrent_str = getRecurrentRule(value)

      let r = RRule.parseString(recurrent_str)
      const rule = new RRule(r)

      const schedule_str =
        rule.toText()?.charAt(0)?.toUpperCase() +
        rule.toText()?.slice(1) +
        '- starting from ' +
        formatDateToDateWithoutTimezone(value.dtstart)

      onChange({
        schedule_str: schedule_str,
        recurrent_str: recurrent_str
      })

      setScheduleText(schedule_str)
    },
    [value]
  )

  const handleChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value
    })
  }

  const handleDayClick = (v) => {
    if (value.byday.find((d) => d === v)) {
      setValue({
        ...value,
        byday: value.byday.filter((d) => d !== v)
      })
    } else {
      setValue({
        ...value,
        byday: [...value.byday, v]
      })
    }
  }

  const handleFreqChange = (c) => {
    setValue({
      ...value,
      freq: c
    })
  }

  return (
    <div className='w-full flex flex-col items-center mt-8 gap-y-4'>
      <span className='isolate inline-flex rounded-md shadow-sm'>
        {availableFreq.map((freq) => (
          <button
            type='button'
            disabled={disabled}
            data-testid={`scheduler-${freq}`}
            key={freq}
            className={`
              relative -ml-px inline-flex items-center px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300  focus:z-10
              ${value.freq === freq ? 'bg-blue-500 text-white hover:bg-blue-700' : 'bg-white text-gray-900 hover:bg-gray-100'} 
              ${disabled ? 'bg-gray-100 text-black hover:bg-gray-100' : ''} `}
            onClick={() => handleFreqChange(freq)}
          >
            {freq}
          </button>
        ))}
      </span>

      {value.freq === 'Daily' && (
        <div className='flex rounded-md shadow-sm'>
          <span className='inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 sm:text-sm bg-gray-100'>
            Every:
          </span>
          <input
            type='text'
            disabled={disabled}
            name='interval'
            value={value.interval}
            onChange={(e) => handleChange(e)}
            className='block w-full min-w-0 flex-1 rounded-none border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
            placeholder='X'
          />
          <span className='inline-flex items-center rounded-r-md border border-l-0 border-gray-300 px-3 sm:text-sm bg-gray-100'>
            day(s)
          </span>
        </div>
      )}
      {value.freq === 'Weekly' && (
        <span className='isolate inline-flex rounded-md shadow-sm'>
          {availableByDay.map((day) => (
            <button
              type='button'
              disabled={disabled}
              key={day}
              data-testid={`scheduler-${day}`}
              className={`relative -ml-px inline-flex items-center ${value?.byday?.find((d) => d === day)
                ? 'bg-blue-500 text-white hover:bg-blue-700'
                : 'bg-white text-gray-900 hover:bg-gray-100'
                } px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300  focus:z-10`}
              onClick={() => handleDayClick(day)}
            >
              {day}
            </button>
          ))}
        </span>
      )}
      {value.freq === 'Weekly' && (
        <div className='flex rounded-md shadow-sm'>
          <span className='inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 sm:text-sm bg-gray-100'>
            Every:
          </span>
          <input
            type='text'
            disabled={disabled}
            name='interval'
            value={value.interval}
            onChange={(e) => handleChange(e)}
            className='block w-full min-w-0 flex-1 rounded-none border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
            placeholder='X'
          />
          <span className='inline-flex items-center rounded-r-md border border-l-0 border-gray-300 px-3 sm:text-sm bg-gray-100'>
            week(s)
          </span>
        </div>
      )}
      {value.freq === 'Monthly' && (
        <div className='flex rounded-md shadow-sm'>
          <span className='inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 sm:text-sm bg-gray-100'>
            On the:
          </span>
          <input
            type='text'
            disabled={disabled}
            name='bymonthday'
            value={value.bymonthday}
            onChange={(e) => handleChange(e)}
            className='block w-full min-w-0 w-8 flex-1 rounded-none border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
            placeholder='0-31'
          />
          <span className='inline-flex items-center border border-r-0 border-gray-300 px-3 sm:text-sm bg-gray-100 bg-gray-100'>every:</span>
          <input
            type='text'
            disabled={disabled}
            name='interval'
            value={value.interval}
            onChange={(e) => handleChange(e)}
            className='w-4 min-w-0 flex-1 rounded-none border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
            placeholder='X'
          />
          <span className='inline-flex items-center rounded-r-md border border-l-0 border-gray-300 px-3 sm:text-sm bg-gray-100'>
            month(s)
          </span>
        </div>
      )}
      {value.freq === 'Yearly' && (
        <div className='flex rounded-md shadow-sm'>
          <span className='inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 sm:text-sm bg-gray-100'>
            On the:
          </span>
          <input
            type='text'
            disabled={disabled}
            name='bymonthday'
            value={value.bymonthday}
            onChange={(e) => handleChange(e)}
            className='block w-full min-w-0 w-16 flex-1 rounded-none border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
            placeholder='1-31'
          />
          <span className='inline-flex items-center border border-r-0 border-gray-300 px-3 sm:text-sm bg-gray-100'>of:</span>
          <div>
            <select
              name='bymonth'
              value={value.bymonth}
              onChange={(e) => handleChange(e)}
              disabled={disabled}
              className='block w-full rounded-none border-0  pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6'
            >
              {[
                { value: '1', label: 'January' },
                { value: '2', label: 'February' },
                { value: '3', label: 'March' },
                { value: '4', label: 'April' },
                { value: '5', label: 'May' },
                { value: '6', label: 'June' },
                { value: '7', label: 'July' },
                { value: '8', label: 'August' },
                { value: '9', label: 'September' },
                { value: '10', label: 'October' },
                { value: '11', label: 'November' },
                { value: '12', label: 'December' }
              ].map((month) => (
                <option key={month.value} value={month.value}>
                  {month.label}
                </option>
              ))}
            </select>
          </div>
          <span className='inline-flex items-center border border-r-0 border-gray-300 px-3 sm:text-sm bg-gray-100 bg-gray-100'>every:</span>
          <input
            type='text'
            disabled={disabled}
            name='interval'
            value={value.interval}
            onChange={(e) => handleChange(e)}
            className='w-4 min-w-0 flex-1 rounded-none border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
            placeholder='X'
          />
          <span className='inline-flex items-center rounded-r-md border border-l-0 border-gray-300 px-3 sm:text-sm bg-gray-100'>
            year(s)
          </span>
        </div>
      )}

      <div className="flex gap-4">
        <div className="flex-grow">
          <JScheduler
            label="First due date:"
            value={value.dtstart}
            onChange={(e) => handleChange({ target: { name: "dtstart", value: e } })}
            disabled={disabled}
          />
        </div>
      </div>


      {!errorText && (
        <div className='bg-indigo-100 border-l-4 border-indigo-500 text-indigo-700 p-2 w-full'>
          <p className='font-bold'>Schedule</p>
          <p data-testid='schedule-text'>{scheduleText}</p>
        </div>
      )}
      {errorText && (
        <div className='bg-red-100 border-l-4 border-red-500 text-red-700 p-2 w-full' role='alert'>
          <p className='font-bold'>Error</p>
          <p>{errorText}</p>
        </div>
      )}
    </div>
  )
}
