import { useState } from 'react'
import DatePicker from 'tailwind-datepicker-react'

export const JScheduler = (props) => {
  const { label, value, onChange, disabled } = props

  const [show, setShow] = useState(false)

  const handleClose = (show) => {
    if (!disabled) setShow(show)
  }

  const options = {
    clearBtn: false,
    defaultDate: value && !isNaN(Date.parse(value)) ? new Date(value) : value,
    theme: {
      input: 'rounded-none rounded-r-md border border-l-0 border-gray-300 bg-white'
    },
    inputPlaceholderProp: 'Select Date',
    inputDateFormatProp: {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    }
  }

  return (
    <div className='flex rounded-md shadow-sm w-full'>
      <span className='flex whitespace-nowrap items-center rounded-l-md border border-r-0 border-gray-300 px-3 sm:text-sm bg-gray-100'>
        {label}
      </span>
      <DatePicker show={show} setShow={handleClose} onChange={onChange} options={options} disabled={disabled} />
    </div>
  )
}
