import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import { useDeleteConfirmationDialog } from '../../hooks/useDeleteConfirmationDialog';
import { jToaster } from '../../misc/j-toaster';
import { Tag } from '../../misc/tag';
import { useAuth } from '../../supabase';
import { RenderFileUploaded } from '../element/element-detail';
import { SmallButton } from '../form/action-button';
import { NewPolicyVersionDrawer } from './new-policy-version-drawer';
import { UserInfoContext } from '../../page/root-page';
import { JFormatDate } from '../misc/columns_def';

export const PolicyVersioning = (props) => {
  const { policyData, refetch } = props;
  const { supabase } = useAuth();
  const navigate = useNavigate();

  const { openDialog, setLoading, DeleteConfirmationDialog } = useDeleteConfirmationDialog();
  const [newModalVisible, setIsNewModalVisible] = useState(false);
  const [openIndices, setOpenIndices] = useState([]);

  // Toggle New Policy Drawer
  const toggleNewModalVisibility = async (shouldRefetch = false) => {
    if (shouldRefetch) await refetch();
    setIsNewModalVisible(!newModalVisible);
  };

  const handleEdit = (e) => {
    navigate(`${e.id}/edit`);
  };

  const handleDelete = async (e) => {
    openDialog(async () => {
      try {
        const { error } = await supabase
          .from('policy_version')
          .delete()
          .match({ id: e.id });

        if (error) throw error;

        await refetch();

        jToaster.success('Policy version deleted successfully');
      } catch (error) {
        jToaster.error('Failed to delete the policy version. Error code: ' + error?.code);
      }
    });
  };

  const handleSetCurrent = async (e) => {
    try {
      const { error } = await supabase
        .from('policy')
        .update({ fk_active_version: e.id })
        .eq('id', policyData.id);
      if (error) throw error;
      await refetch();
    } catch (error) {
      console.error('Error updating policy:', error);
    }
  };

  // Toggle accordion section allowing multiple to be open
  const toggleAccordion = (index) => {
    if (openIndices.includes(index)) {
      setOpenIndices(openIndices.filter(i => i !== index)); // Close the accordion if already open
    } else {
      setOpenIndices([...openIndices, index]); // Open the accordion
    }
  };

  const renderPolicyVersion = (e, index) => {
    const { userInfo } = useContext(UserInfoContext)

    const isOpen = openIndices.includes(index);
    const isActive = policyData.fk_active_version === e?.id;

    return (
      <div key={e.id} className="hs-accordion bg-white border border-gray-300 my-2 -mt-px" id={`hs-bordered-heading-${index}`}>
        <button
          onClick={() => toggleAccordion(index)}
          className={`bg-slate-50 hover:bg-slate-100 hs-accordion-toggle ${isOpen ? 'text-blue-600 border-b' : ''} inline-flex items-center gap-x-3 w-full font-semibold text-start text-gray-800 py-3 px-5 hover:text-gray-500`}
          aria-expanded={isOpen}
          aria-controls={`hs-basic-bordered-collapse-${index}`}
        >
          <div className="flex items-center w-full">
            <div className="flex items-center gap-x-2 flex-grow">
              <span>{e.userdef_id}</span>
              <span className="text-gray-500 text-xs">- Created {e.created_at.split('T')[0]}</span>
              {isActive && <Tag text={'CURRENT'} />}
            </div>

            <div className="ml-auto flex gap-x-2">
              <a
                data-testid='policy-version-delete'
                className={`hover:underline text-blue-600 hover:text-blue-800 cursor-pointer font-bold uppercase text-xs ${!isActive ? '' : 'disabled text-gray-400 hover:text-gray-400 cursor-default'}`}
                onClick={(event) => { event.stopPropagation(); if (!isActive) handleDelete(e); }}
              >
                Delete
              </a>
              <a
                data-testid='policy-version-edit'
                className="hover:underline text-blue-600 hover:text-blue-800 cursor-pointer font-bold uppercase text-xs border-l px-2 border-r"
                onClick={(event) => { event.stopPropagation(); handleEdit(e); }}
              >
                Edit
              </a>
              <a
                data-testid='policy-version-setcurrent'
                className={`hover:underline text-blue-600 hover:text-blue-800 cursor-pointer font-bold uppercase text-xs ${!isActive ? '' : 'disabled text-gray-400 hover:text-gray-400 cursor-default'}`}
                onClick={(event) => { event.stopPropagation(); if (!isActive) handleSetCurrent(e); }}
              >
                Set Current
              </a>
            </div>
          </div>
        </button>
        <div id={`hs-basic-bordered-collapse-${index}`} className={`hs-accordion-content w-full overflow-hidden transition-[height] duration-300 ${isOpen ? 'max-h-screen' : 'max-h-0'}`} role="region" aria-labelledby={`hs-bordered-heading-${index}`}>
          <div className="pb-2 py-4 px-4">
            <span className="uppercase font-bold text-xs">Description</span>
            <p className="text-gray-800 pt-2 pb-4 whitespace-pre-line">{e.description}</p>

            {userInfo?.customFields.policy
              ?.map((field) => (
                <div key={field.field_name}>
                  <span className="uppercase font-bold text-xs">{field.field_name}</span>
                  <p className="text-gray-800 pt-2 pb-4 whitespace-pre-line">
                    {field.field_type === 'date' && <span>{JFormatDate(e?.current_version?.custom_values[field.field_name])}</span>}
                    {field.field_type !== 'date' && <span>{e.custom_values[field.field_name] || 'N/A'}</span>}
                  </p>
                </div>
              ))
            }

            {e.af_policy_version_attachment && e.af_policy_version_attachment.length > 0 ? (
              <div>
                <span className="uppercase font-bold text-xs">Attachments</span>
                <RenderFileUploaded fileList={e.af_policy_version_attachment} />
              </div>
            ) : (
              <span className="uppercase font-bold text-xs">No Attachment</span>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <DeleteConfirmationDialog />

      <NewPolicyVersionDrawer
        open={newModalVisible}
        onClose={toggleNewModalVisibility}
        associatedId={policyData.id}
      />
      <div className="my-2">
        <SmallButton data-testid='button-new-version' onClick={() => toggleNewModalVisibility(false)}>
          New Version
        </SmallButton>
      </div>

      <div className="hs-accordion-group">
        {policyData?.all_version.map((e, index) => renderPolicyVersion(e, index))}
      </div>
    </>
  );
};
