import { useState, useCallback } from 'react';

export const useJDrawer = () => {
  const [open, setOpen] = useState(false);

  // Function to open the drawer
  const openDrawer = useCallback(() => {
    setOpen(true);
  }, []);

  // Function to close the drawer
  const closeDrawer = useCallback(() => {
    setOpen(false);
  }, []);

  return {
    open,
    openDrawer,
    closeDrawer,
  };
};
