import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { AuthCard } from '../../components/auth/auth-card';
import { ActionButton } from '../../components/form/action-button';
import { TextInput } from '../../components/form/inputs';
import { useAuth } from '../../supabase';

const FormField = ({ dataTestid, label, name, type = 'text', rules, register, errors }) => (
  <TextInput
    label={label}
    name={name}
    type={type}
    dataTestid={dataTestid}
    errorMessage={errors?.[name]?.message}
    register_fn={() => register(name, rules)}
  />
);

const AuthForm = ({ fields, submitForm, isLoading, buttonLabel }) => {
  const { register, handleSubmit, formState: { errors, isValid } } = useForm({ mode: 'all' });

  return (
    <form onSubmit={handleSubmit(submitForm)} className="flex flex-col items-center gap-4 w-full">
      {fields.map(({ label, dataTestid, name, type, rules }) => (
        <FormField dataTestid={dataTestid} key={name} label={label} name={name} type={type} rules={rules} register={register} errors={errors} />
      ))}
      <ActionButton dataTestid='login-button-connect' disabled={!isValid} loading={isLoading} className="w-full justify-center mt-4" type="submit">
        {buttonLabel}
      </ActionButton>
    </form>
  );
};

// Login fields configuration
const loginFields = [
  {
    label: 'Email',
    name: 'email',
    type: 'email',
    dataTestid: 'login-email',
    rules: {
      required: 'Email is required',
      pattern: {
        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        message: 'Invalid email address',
      },
    },
  },
  {
    label: 'Password',
    name: 'password',
    type: 'password',
    dataTestid: 'login-password',
    rules: {
      required: 'Password is required',
      maxLength: { value: 64, message: 'Password cannot exceed 64 characters' },
      validate: (value) => value.length >= 8 || 'Password does not meet requirements',
    },
  },
];

export const LoginPage = () => {
  const navigate = useNavigate();
  const { supabase } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const [params] = useSearchParams();
  const [errorMessage, setErrorMessage] = useState(params.get('error_description') || '');

  const handleLogin = async (values) => {
    setIsLoading(true);
    try {
      const { data, error } = await supabase.auth.signInWithPassword(values);
      if (error) throw new Error('Invalid login credentials');

      const user = data.user;

      // Check if MFA is required from user data
      const { data: userData, error: userError } = await supabase
        .from('user')
        .select('mfa_required, mfa_factor_id')
        .eq('fk_auth_id', user.id)
        .single();

      if (userError) throw userError;

      if (!userData.mfa_required) {
        navigate('/app/todo');
      } else if (userData.mfa_required && userData.mfa_factor_id) {
        // If MFA is required, redirect to MFA Verification Page
        navigate(`/auth/mfa-verification/${userData.mfa_factor_id}`);
      } else {
        // If MFA registration is required, redirect to MFA Registration Page
        navigate(`/auth/mfa-verification`);
      }
    } catch (err) {
      setErrorMessage(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="h-screen bg-slate-50 flex items-center justify-center">
      <AuthCard>
        {errorMessage && <span data-testid='error-message' className="text-red-500 text-center">{errorMessage}</span>}
        <AuthForm fields={loginFields} submitForm={handleLogin} isLoading={isLoading} buttonLabel="Login" />
      </AuthCard>
    </div>
  );
};
