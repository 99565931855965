import React from 'react'

export const Stepper = (props) => {
  const { steps, errorByPanel, activeStep, onStepperChange } = props

  if (!steps) return <></>
  return (
    <nav className='mx-auto w-full max-w-7xl bg-gray-50'>
      <ol className='grid grid-flow-col auto-cols-fr gap-5 pb-2'>
        {steps.map((step, index) => (
          <li
            key={step.id}
            className='col-span-full sm:col-auto'
            onClick={() => {
              onStepperChange(index)
            }}
          >
            {/* :ERROR */}
            {errorByPanel[step.id] && (
              <a className='group p-2 flex flex-col items-start border-b-4 sm:border-b-0 sm:border-b-4 border-red-500 hover:border-red-900'>
                {/* ::Step number */}
                <span className='text-sm text-red-500 font-semibold uppercase tracking-wide group-hover:text-red-900'>{`STEP ${index + 1}`}</span>
                {/* ::Step title */}
                <span className='text-base text-gray-700 font-semibold'>{step.title}</span>
              </a>
            )}

            {/* :STATUS COMPLETED */}
            {!errorByPanel[step.id] && index <= activeStep && (
              <a className='group p-2 flex flex-col items-start border-b-4 sm:border-b-0 sm:border-b-4 border-blue-500 hover:border-blue-900'>
                {/* ::Step number */}
                <span className='text-sm text-blue-500 font-semibold uppercase tracking-wide group-hover:text-blue-900'>{`STEP ${index + 1}`}</span>
                {/* ::Step title */}
                <span className='text-base text-gray-700 font-semibold'>{step.title}</span>
              </a>
            )}

            {/* :STATUS UPCOMING */}
            {!errorByPanel[step.id] && index > activeStep && (
              <a className='group p-2 flex flex-col items-start border-b-4 sm:border-b-0 sm:border-b-4 border-gray-300 hover:border-gray-500'>
                {/* ::Step number */}
                <span className='text-sm text-gray-500 font-semibold uppercase tracking-wide group-hover:text-gray-600'>{`STEP ${index + 1}`}</span>
                {/* ::Step title */}
                <span className='text-base text-gray-700 font-semibold'>{step.title}</span>
              </a>
            )}
          </li>
        ))}
      </ol>
    </nav>
  )
}
