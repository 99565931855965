import React, { useState } from 'react'
import { ElementDetail, RenderFileUploaded } from '../element/element-detail'
import { jFormatDateAndTime, RenderGroupOrUser, TaskStatusTag } from '../misc/columns_def'
import { TabHeader } from '../misc/tab-header'
import { WorkflowHistory } from '../workflow/workflow-history'
import { TaskResultForm } from './task-result-form'

const getDetailSpec = (checkData) => {
  return ([
    {
      key: 'tag',
      label: 'Tag',
      render: TaskStatusTag,
    },
    {
      key: 'created_at',
      label: 'Creation Date',
      render: (e) => <span>{jFormatDateAndTime(e?.created_at)}</span>
    },
    {
      key: 'duedate',
      label: 'Due Date'
    },
    {
      key: 'type',
      label: 'Classification',
      render: (e) => (
        <>
          {e?.control && <span>{'Scheduled Task (initiated by ' + e.control.userdef_id + ')'}</span>}
          {e?.control === null && <span>Unscheduled</span>}
        </>
      )
    },
    //Add only if control is set
    ...(checkData?.control ? [
      {
        type: 'divider',
        key: 'div2',
        label: 'Associated Control'
      },
      {
        key: 'id',
        label: 'ID',
        render: (e) => <span>{e?.control?.userdef_id}</span>
      },
      {
        key: 'name',
        label: 'Name',
        render: (e) => <span>{e?.control?.title}</span>
      },
      {
        key: 'control_strategy',
        label: 'Schedule Strategy',
        render: (e) => (
          <>
            {e?.control.control_strategy === 'scheduled_control' && (
              <label htmlFor='requireApproval' className='px-2 py-1 font-bold bg-slate-50 border uppercase text-xs text-sm leading-6 text-gray-900'>
                Automated Schedule
              </label>
            )}
            {
              e?.control.control_strategy === 'nocontrol' && (
                <label htmlFor='requireApproval' className='px-2 py-1 font-bold bg-slate-50 border uppercase text-xs text-sm leading-6 text-gray-900'>
                  Manual Scheduling
                </label>
              )
            }
          </>
        )
      },
      {
        key: 'readable_control_schedule_str',
        label: 'Schedule',
        render: (e) => <span>{e?.control.readable_control_schedule_str}</span>
      },
    ] : []),
    {
      type: 'divider',
      key: 'div2',
      label: 'Description'
    },
    {
      key: 'userdef_id',
      label: 'ID'
    },
    {
      key: 'title',
      label: 'Name'
    },
    {
      key: 'description',
      label: 'Description'
    },
  ])
}

const result_before_submit_spec = [
  {
    key: 'executor',
    label: 'Assigned To',
    render: (e) => <RenderGroupOrUser user={e?.executor_user} group={e.executor_group} />
  },
  {
    key: 'approbator',
    label: 'Validation Workflow',
    render: (e) => (
      <label className='px-2 py-1 font-bold bg-slate-50 border uppercase text-xs text-sm leading-6 text-gray-900'>
        {e?.workflow_template?.name ? e?.workflow_template?.name : 'NO-VALIDATION'}
      </label>
    )
  },
  {
    type: 'divider',
    key: 'div1',
    label: 'Result'
  },
]

const result_spec = [
  {
    key: 'executor',
    label: 'Assigned To',
    render: (e) => <RenderGroupOrUser user={e?.executor_user} group={e.executor_group} />
  },
  {
    key: 'approbator',
    label: 'Validation Workflow',
    render: (e) => (
      <label className='px-2 py-1 font-bold bg-slate-50 border uppercase text-xs text-sm leading-6 text-gray-900'>
        {e?.workflow_template?.name ? e?.workflow_template?.name : 'NO-VALIDATION'}
      </label>
    )
  },
  {
    type: 'divider',
    key: 'div1',
    label: 'Result'
  },
  {
    key: 'last_update',
    label: 'Date',
    render: (e) => <span>{jFormatDateAndTime(e?.last_update)}</span>
  },
  {
    key: 'Executor',
    label: 'Executor',
    render: (e) => (<RenderGroupOrUser user={e?.real_executor} />)
  },
  {
    key: 'result',
    label: 'Result'
  },
  {
    key: 'attachments',
    label: 'Attached Documents',
    render: (e) => <RenderFileUploaded fileList={e?.af_task_result_attachment} />
  },
  {
    type: 'divider',
    key: 'div2',
    label: 'Validation'
  },
]

const getTabs = (checkData) => {
  return [
    { name: 'Task', disabled: false, href: '' },
    { name: 'Execution', disabled: false, href: '' },
  ]
}

export const TaskDetail = (props) => {
  const { taskData, isLoading, onSubmitStepReview, onSubmitTaskSubmission } = props

  const tabs = getTabs(taskData);
  const [selectedTab, setSelectedTab] = useState('Task')

  const detailSpec = getDetailSpec(taskData)

  const onSelectedTabChange = (e) => {
    setSelectedTab(e)
  }

  return (
    <>
      <TabHeader
        tabs={tabs}
        selectedTab={selectedTab}
        onSelectedTabChange={onSelectedTabChange}
      />
      {selectedTab === 'Task' &&
        <ElementDetail detailSpec={detailSpec} data={taskData} />
      }
      {selectedTab === 'Execution' &&
        <>
          {taskData.status !== 'TODO' &&
            <>
              <ElementDetail detailSpec={result_spec} data={taskData} />
              <WorkflowHistory
                data={taskData?.task_validation_step}
                onSubmit={onSubmitStepReview}
                isLoading={isLoading}
              />
            </>
          }
          {taskData.status === 'TODO' &&
            <>
              <ElementDetail
                detailSpec={result_before_submit_spec}
                data={taskData}
              />
              <TaskResultForm
                onSubmit={onSubmitTaskSubmission}
              />
            </>
          }
        </>
      }
    </>
  )
}
