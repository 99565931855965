import { useState, useEffect } from 'react';
import { useAuth } from '../../supabase';

export const useAllFrameworkData = (project_id) => {
  const { supabase } = useAuth();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    try {
      const { data, error } = await supabase
        .from('framework')
        .select(
          `
          *,
          framework_safeguard (
            applicable
          )
        `
        )
        .eq('fk_project_id', project_id)
      if (error) throw error;

      const frameworksWithApplicableCount = data.map((framework) => {
        const applicableSafeguardsCount = framework.framework_safeguard.filter(safeguard => safeguard.applicable).length;

        return {
          ...framework,
          applicableSafeguardsCount, // Add the count of applicable safeguards
          totalSafeguardsCount: framework.framework_safeguard.length, // Add the count of applicable safeguards
        };
      });

      setData(frameworksWithApplicableCount ?? []);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [supabase, project_id]);

  return { data, isLoading, error, refetch: fetchData };
};
