import React, { useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { ObjectHeader } from '../components/header/object-header'
import { FullPageCard } from '../components/misc/full-page-card'
import { LoadingWheel } from '../components/misc/loading-wheel'
import { TaskDetail } from '../components/task/task-detail'
import { useTaskData } from '../hooks/db/useTaskData'
import { useDeleteConfirmationDialog } from '../hooks/useDeleteConfirmationDialog'
import { jToaster } from '../misc/j-toaster'
import { deleteTask } from '../services/taskService'
import { useAuth } from '../supabase'

export const TaskPage = () => {
  const navigate = useNavigate()
  let { id } = useParams()

  const { supabase } = useAuth()

  const { openDialog, DeleteConfirmationDialog } = useDeleteConfirmationDialog();
  const { taskData, isLoading, error, refetch } = useTaskData(supabase, id)
  const errorShownRef = useRef(false);

  const [isSaving, setIsSaving] = useState(false)

  const handleDelete = async () => {
    openDialog(async () => {
      try {
        await deleteTask(supabase, id)
        jToaster.success('Task deleted successfully')
        navigate(-1)
      } catch (error) {
        jToaster.error('Failed to delete the task. Error code: ' + error?.code);
      }
    })
  }

  const handleOnSubmitTaskSubmission = async (values) => {
    setIsSaving(true)
    try {
      const { error } = await supabase.functions.invoke('save-task-initial-submission', {
        body: {
          taskId: taskData?.id,
          taskData: { ...values }
        }
      })

      if (error) throw error
      jToaster.success('Task submitted successfully')
      await refetch()
    }
    catch (error) {
      const errorMessage = await error.context.json();
      jToaster.error('Error while submitting the task')
      console.error('Error while submitting the task', errorMessage)
    }
    finally {
      setIsSaving(false)
    }
  }

  const handleOnSubmitStepReview = async (stepId, formData) => {
    const action = document.activeElement.value; // Get the button value (approve/decline)
    setIsSaving(true)
    console.log('handleOnSubmitStepReview')
    try {
      const {data} = await supabase.functions.invoke('updatetask', {
        body: {
          stepId: stepId,
          newStatus: action,
          note: formData?.note
        },
      })
      await refetch()

      if(!data?.ok) jToaster.error('Task update error: ', data?.error)
      else jToaster.success('Task updated successfully')
    }
    catch (e) {
      jToaster.error('Error while updating successfully')
      console.error('Error while updating successfully', errorMessage)
    }
    finally { setIsSaving(false) }
  };

  if (error && !errorShownRef.current) {
    jToaster.error('Failed to load task information. Error code: ' + error.code)
    errorShownRef.current = true;
  }

  if (isLoading || isSaving) return <LoadingWheel />;
  if (error) return <></>

  return (
    <FullPageCard>
      <ObjectHeader
        title={'Task: ' + taskData?.userdef_id}
        backUrl={-1}

        secondaryButtonText={'Delete'}
        onSecondaryButtonClick={handleDelete}
      />

      <DeleteConfirmationDialog />

      <TaskDetail
        taskData={taskData}
        isLoading={isSaving}
        onSubmitStepReview={handleOnSubmitStepReview}
        onSubmitTaskSubmission={handleOnSubmitTaskSubmission}
      />
    </FullPageCard>
  )
}
