import { useContext, useEffect, useState } from 'react'
import { UserInfoContext } from '../../page/root-page'
import { useAuth } from '../../supabase'
import { AddPalette } from './add-palette'

export const AddRiskPalette = (props) => {
  const { onAdd, onClose, isOpen, alreadyused } = props

  const { supabase, session } = useAuth()
  const { userInfo } = useContext(UserInfoContext)

  const [isLoading, setIsLoading] = useState(true)
  const [options, setOptions] = useState([])
  const [filteredOptions, setFilteredOptions] = useState([])

  //Query the information of the user and associated project then pass it to all the sub pages
  const fetchData = async () => {
    try {
      // Query user information
      const { data: riskData, error: userError } = await supabase.from('risk').select('*').eq('fk_project_id', userInfo?.project?.id)

      if (userError) throw userError

      setOptions(riskData.map((e) => ({ ...e, comboboxName: e.userdef_id + ': ' + e.title })))
    } catch (error) {
      console.error('Failed to fetch data:', error)
    }

    setIsLoading(false)
  }

  useEffect(() => {
    const newFilteredOptions = options.filter((option) => !alreadyused.map((e) => e.id).includes(option.id))

    setFilteredOptions([...newFilteredOptions])
  }, [alreadyused, options, isOpen])

  useEffect(() => {
    fetchData()
  }, [supabase]) // Dependency on supabase context or client

  return <AddPalette options={filteredOptions} onClick={onAdd} loading={isLoading} open={isOpen} onClose={onClose} />
}
