import { Controller } from "react-hook-form"
import { JScheduler } from "./j-scheduler"
import { TextInput } from "./inputs"

export const AdditionnalFieldsForm = ({ customFields, control, errors, register }) => {
  if (customFields?.length === undefined) return <></>
  
  return (
    <>
      <div className="my-6 h-0.5 bg-gradient-to-r from-gray-300 via-gray-400 to-gray-500"></div>
      {customFields.map((field) => (
        <div key={field.field_name}>
          <label className='block text-sm font-medium text-gray-700 mb-1'>{field.field_name}</label>

          {field.field_type === 'text' && (
            <TextInput
              name={`custom_values.${field.field_name}`}
              dataTestid={`custom_values.${field.field_name}`}
              register_fn={() => register(`custom_values.${field.field_name}`, { required: field.is_required })}
            />
          )}
          {field.field_type === 'textlong' && (
            <TextInput
              rows={5}
              name={`custom_values.${field.field_name}`}
              dataTestid={`custom_values.${field.field_name}`}
              register_fn={() => register(`custom_values.${field.field_name}`, { required: field.is_required })}
            />
          )}
          {field.field_type === 'date' && (
            <Controller
              control={control}
              name={`custom_values.${field.field_name}`}
              render={({ field: { value, onChange } }) => (
                <JScheduler
                  label={field.field_name}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          )}

          {field.field_type !== 'text' || field.field_type !== 'textlong' || field.field_type !== 'date' &&
            <span>Error unsupported custom fields type: {field.field_type}</span>
          }

          {errors[`custom_values.${field.field_name}`] && <p className='text-red-500 text-xs italic'>This field is required</p>}
        </div>
      ))}
    </>
  )
}