import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Combobox } from '@headlessui/react'
import { useState } from 'react'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const ComboBox = (props) => {
  const { value, onChange, options, RenderComponent, disabled } = props

  const [query, setQuery] = useState('')

  const filteredOptions =
    query === ''
      ? options
      : options.filter((o) => {
          return o.comboboxName.toLowerCase().includes(query.toLowerCase())
        })

  return (
    <Combobox as='div' value={value} onChange={onChange} disabled={disabled}>
      <div className={`relative mt-2 ${disabled ? 'opacity-50' : ''}`}>
        <Combobox.Input
          className={`w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${disabled ? 'cursor-not-allowed' : ''}`}
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(o) => o?.comboboxName}
          disabled={disabled}
          autoComplete='off'
        />
        <Combobox.Button
          className={`absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none ${disabled ? 'cursor-not-allowed' : ''}`}
        >
          <FontAwesomeIcon icon={faChevronDown} className='h-5 w-5 text-gray-400' aria-hidden='true' />
        </Combobox.Button>

        {!disabled && filteredOptions.length > 0 && (
          <Combobox.Options className='absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
            {filteredOptions.map((o) => (
              <Combobox.Option
                key={o.id}
                value={o}
                className={({ active }) =>
                  classNames('relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-indigo-600 text-white' : 'text-gray-900')
                }
              >
                {({ active, selected }) => (
                  <>
                    {RenderComponent && <RenderComponent row={o} />}
                    {!RenderComponent && <span>{o.comboboxName}</span>}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  )
}
