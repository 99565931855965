export const auditService = {
  async getAllAudit(supabase, project_id) {
    const { data, error } = await supabase
      .from('audit_log')
      .select(
        `
        id,
        changed_by,
        table_name,
        operation_type,
        time
        `
      )
      .eq('fk_project_id', project_id)
      .order('time', { ascending: false })

    if (error) throw error
    return data
  }
}