import React, { useContext, useRef } from 'react';
import { useNavigate } from 'react-router';
import { ElementDetail } from '../components/element/element-detail';
import { ObjectHeader } from '../components/header/object-header';
import { TaskFullColumn } from '../components/misc/columns_def';
import { FullPageCard } from '../components/misc/full-page-card';
import { JTable } from '../components/table/j-table';
import { useTaskByExecutor } from '../hooks/db/useTaskByExecutor';
import { useTaskWithPendingValidationStep } from '../hooks/db/useTaskWithPendingValidationStep';
import { useAuth } from '../supabase';
import { UserInfoContext } from './root-page';
import { LoadingWheel } from '../components/misc/loading-wheel';

const user_spec = [
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'type',
    label: 'Type',
  },
  {
    key: 'department',
    label: 'Department',
  },
  {
    key: 'email',
    label: 'Email',
  },
  {
    key: 'associated_groups',
    label: 'Associated Groups',
    render: (e) => (
      <ul>
        {e?.group?.map((g => (
          <li key={g?.name}>{g?.name ?? g.name}</li>
        )))
        }
      </ul>
    )
  }
];

export const TodoPage = (navigation, route) => {
  const { supabase } = useAuth();
  const navigate = useNavigate();

  const { userInfo } = useContext(UserInfoContext);
  const groupIdsStr = userInfo?.group.map((e) => (e.id)).join(',')

  const { taskData: taskPendingExecution, isLoading: isLoading1, error: error1 } = useTaskByExecutor(supabase, userInfo.id, groupIdsStr);
  const { taskData: taskPendingValidation, isLoading: isLoading2, error: error2 } = useTaskWithPendingValidationStep(supabase, userInfo.id, groupIdsStr);
  const errorShownRef = useRef(false);

  const handleRowClick = (row) => {
    navigate('/app/task/' + row?.id);
  }

  if ((error1 || error2) && !errorShownRef.current) {
    jToaster.error('Failed to load task information. Error code: ' + error1?.code + ' ' + error2?.code)
    errorShownRef.current = true;
  }

  if (error1 || error2) return <></>

  return (
    <FullPageCard>
      <ObjectHeader
        title={'TODO: ' + userInfo.name}
        allowFilter={false}
      />
      <ElementDetail
        className='mb-2 border-b'
        detailSpec={user_spec}
        data={userInfo}
      />
      <div className='mt-4 flex flex-col gap-4'>
        <JTable
          title='Pending execution'
          value={taskPendingExecution ?? []}
          columns={TaskFullColumn}
          pagination={true}
          handleRowClick={(e) => { handleRowClick(e) }}
          isLoading={isLoading1}
        />
        <JTable
          title='Pending approval'
          value={taskPendingValidation ?? []}
          columns={TaskFullColumn}
          pagination={true}
          handleRowClick={(e) => { handleRowClick(e) }}
          isLoading={isLoading2}
        />
      </div>
    </FullPageCard>
  )
}