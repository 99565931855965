import { useState, useEffect } from 'react';
import { useAuth } from '../../supabase';

export const useAllWorkflowData = () => {
  const { supabase } = useAuth();
  const [allWorkflowData, setAllWorkflowData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    try {
      const { data, error } = await supabase.from('workflow_template')
        .select('*,workflow_template_steps(count)')
        .order('created_at', { ascending: false })

      if (error) throw error;

      const templatesWithStepCount = data?.map(template => ({
        ...template,
        step_count: template.workflow_template_steps.length > 0
          ? template.workflow_template_steps[0].count
          : 0
      }));

      setAllWorkflowData(templatesWithStepCount ?? []);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { allWorkflowData, isLoading, error, refetch: fetchData };
};
