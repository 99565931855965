import React, { useContext, useEffect, useRef, useState } from 'react'

import { useNavigate } from 'react-router'
import { ObjectHeader } from '../components/header/object-header'
import { ObjectSearchFilter } from '../components/header/object-search-filter'
import { RiskColumnFull } from '../components/misc/columns_def'
import { FullPageCard } from '../components/misc/full-page-card'
import { JTable } from '../components/table/j-table'
import { useAuth } from '../supabase'
import { UserInfoContext } from './root-page'
import { jToaster } from '../misc/j-toaster'
import { useGenericFetchData } from '../hooks/db/useGenericFetchData'
import { riskService } from '../services/riskService'
import { LoadingWheel } from '../components/misc/loading-wheel'

// Update filterOptions with the unique values
const filterOptions = [
  {
    section_title: 'Tag',
    key: 'TAg',
    data: [
      {
        label: 'Active',
        value: 'status_active'
      },
      {
        label: 'Draft',
        value: 'status_draft'
      },
      {
        label: 'Archived',
        value: 'status_archived'
      }
    ]
  },
  {
    section_title: 'Strategy',
    key: 'strategy',
    data: [
      {
        label: 'Accept',
        value: 'strategy_accept'
      },
      {
        label: 'Mitigate',
        value: 'strategy_mitigate'
      },
      {
        label: 'Transfer',
        value: 'strategy_transfer'
      },
      {
        label: 'Avoid',
        value: 'strategy_avoid'
      }
    ]
  }
]

const initializeActiveFilters = () => {
  // Try to get filters from localStorage or set defaults if not found
  const storedFilters = localStorage.getItem('activeFiltersRiskPage')
  const initialFilters = storedFilters ? JSON.parse(storedFilters) : {}

  if (!storedFilters) {
    filterOptions.forEach((section) => {
      section.data.forEach((filter) => {
        initialFilters[filter.value] = false // Default state can be set to false or true as needed
      })
    })
  }
  return initialFilters
}

export const AllRiskPage = (navigation, route) => {
  const navigate = useNavigate()

  const { supabase, session } = useAuth()
  const { userInfo } = useContext(UserInfoContext)

  const { data, isLoading, error, refetch } = useGenericFetchData(
    riskService.getAllRisk,
    [supabase, userInfo?.project?.id]
  );
  const errorShownRef = useRef(false);

  const [forceUpdate, setForceUpdate] = useState(false)
  const [filteredValues, setFilteredValues] = useState([])
  const [activeFilters, setActiveFilters] = useState(initializeActiveFilters())

  const handleFilterChange = (e) => {
    const updatedFilters = {
      ...activeFilters,
      [e.target.name]: e.target.checked
    }
    // Create a copy of updatedFilters excluding the 'search' field
    const { search, ...filtersToSave } = updatedFilters;

    setActiveFilters(updatedFilters)
    localStorage.setItem('activeFiltersRiskPage', JSON.stringify(filtersToSave))
  }

  //Filter
  useEffect(() => {
    let filtered = data || []
    
    filtered = filtered.filter(
      (item) =>
        (activeFilters['status_active'] && item.status === 'ACTIVE') ||
        (activeFilters['status_draft'] && item.status === 'DRAFT') ||
        (activeFilters['status_archived'] && item.status === 'ARCHIVED') ||
        (!activeFilters['status_active'] && !activeFilters['status_draft'] && !activeFilters['status_archived'])
    )

    filtered = filtered.filter(
      (item) =>
        (activeFilters['strategy_accept'] && item.strategy === 'Accept') ||
        (activeFilters['strategy_mitigate'] && item.strategy === 'Mitigate') ||
        (activeFilters['strategy_transfer'] && item.strategy === 'Transfer') ||
        (activeFilters['strategy_avoid'] && item.strategy === 'Avoid') ||
        (!activeFilters['strategy_accept'] &&
          !activeFilters['strategy_mitigate'] &&
          !activeFilters['strategy_transfer'] &&
          !activeFilters['strategy_avoid'])
    )

    // Filter by search term if applicable
    if (activeFilters['search'] && activeFilters['search'] !== '') {
      filtered = filtered.filter((item) => {
        const searchTerm = activeFilters['search'].toLowerCase()

        const titleMatch = item?.title?.toLowerCase().includes(searchTerm)
        const userdefIdMatch = item?.userdef_id?.toString().toLowerCase().includes(searchTerm)

        return titleMatch || userdefIdMatch
      })
    }

    setForceUpdate(!forceUpdate)
    setFilteredValues(filtered)
  }, [data, activeFilters])

  const onRowClick = (e) => {
    navigate('/app/risk/' + e.id)
  }

  const handleNewRiskClick = () => {
    navigate('/app/risk/new')
  }

  if (error && !errorShownRef.current) {
    jToaster.error('Failed to load all task information. Error code: ' + error.code)
    errorShownRef.current = true;
  }

  if (isLoading) return <LoadingWheel />;
  if (error) return <></>

  return (
    <FullPageCard>
      <ObjectHeader title='Risks' primaryButtonText='New Risk' onPrimaryButtonClick={handleNewRiskClick} />
      <ObjectSearchFilter onFilterChange={handleFilterChange} filterOptions={filterOptions} activeFilters={activeFilters} />

      <JTable
        columns={RiskColumnFull}
        value={filteredValues}
        handleRowClick={onRowClick}
        pagination='True'
        key={forceUpdate}
        isLoading={isLoading}
      />
    </FullPageCard>
  )
}
