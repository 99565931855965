
export const complianceService = {
  async getComplianceById(supabase, id) {
    const { data, error } = await supabase
      .from('framework_safeguard')
      .select(
        `
        *, 
        policy(*),
        risk(*),
        control(*)
      `
      )
      .eq('id', id)
      .single()
    if (error) throw error
    return data
  }
}