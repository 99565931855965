import React from 'react'
import { DeleteOutlined } from '@ant-design/icons'
import { useState } from 'react'
import { useNavigate } from 'react-router'
import { SmallButton } from '../form/action-button'
import { DropdownButton } from '../form/dropdown-button'
import { ComplianceColumnShort, ControlColumnShort, PolicyColumnsShort, RiskColumnShort } from '../misc/columns_def'
import { AddCompliancePalette } from '../modal/add-compliance-palette'
import { AddControlPalette } from '../modal/add-control-palette'
import { AddPolicyPalette } from '../modal/add-policy-palette'
import { AddRiskPalette } from '../modal/add-risk-palette'

const RelationshipTable = (props) => {
  const { name, value, canEditData, columns, onDeleteRow, onRowClick } = props

  return (
    <>
      <tr className='bg-gray-100 border-b-2 text-left'>
        <th className='py-2 font-bold text-gray-900 pl-4' colSpan={canEditData ? 4 : 3}>
          {name}
        </th>
      </tr>

      {value?.length === 0 && (
        <tr className='border-b-2'>
          <th className='py-2 text-xs' colSpan={canEditData ? 4 : 3}>
            No Data
          </th>
        </tr>
      )}

      {value?.length > 0 &&
        value.map((rowData) => (
          <tr
            key={rowData.id}
            className='hover:bg-gray-100 cursor-pointer'
            onClick={(e) => {
              onRowClick(e, rowData)
            }}
          >
            {columns.map((column) => (
              <td key={column.key} className={`py-2 text-sm text-gray-700 px-4 ${column.style}`}>
                {column.render ? column.render(rowData) : rowData[column.key]}
              </td>
            ))}

            {canEditData && (
              <td className='h-full px-4 w-2'>
                <SmallButton onClick={(e) => onDeleteRow(e, rowData)}>
                  <DeleteOutlined />
                </SmallButton>
              </td>
            )}
          </tr>
        ))}
    </>
  )
}

export const ConnectionTable = (props) => {
  const { value, canEditData, onChange, onNewConnection, onDeleteConnection } = props

  const navigate = useNavigate()

  /*----Add connection drawer-----*/
  const [isAddPaletteOpen, setIsAddPaletteOpen] = useState(false)

  const handleCancelAddConnection = () => {
    setIsAddPaletteOpen(false)
  }

  const add_buttons = [
    {
      text: 'Compliance',
      disabled: value?.framework_safeguard === undefined,
      onClickHandler: () => {
        setIsAddPaletteOpen('compliance')
      }
    },
    {
      text: 'Policy',
      disabled: value?.policy === undefined,
      onClickHandler: () => {
        setIsAddPaletteOpen('policy')
      }
    },
    {
      text: 'Risk',
      disabled: value?.risk === undefined,
      onClickHandler: () => {
        setIsAddPaletteOpen('risk')
      }
    },
    {
      text: 'Control',
      disabled: value?.control === undefined,
      onClickHandler: () => {
        setIsAddPaletteOpen('control')
      }
    }
  ]

  const handleDeleteRow = (e, type, row) => {
    e.stopPropagation()
    onDeleteConnection(type, row);
  }

  const handleRowClick = (type, row) => {
    if (type === 'policy') {
      navigate('/app/policy/' + row.id)
    }
    if (type === 'compliance') {
      navigate('/app/compliance/' + row.id)
    }
    if (type === 'risk') {
      navigate('/app/risk/' + row.id)
    }
    if (type === 'control') {
      navigate('/app/control/' + row.id)
    }
  }

  const handleSaveNewConnection = (type, newConnection) => {
    onNewConnection(type, newConnection)
    setIsAddPaletteOpen(false)
  }

  return (
    <>
      <div className='inline-block min-w-full align-middle '>
        <table className='min-w-full divide-y divide-gray-300 border border-gray-300 '>
          <tbody className='divide-y divide-gray-200 bg-white cursor-default'>
            {value?.framework_safeguard && (
              <RelationshipTable
                name='Compliance'
                canEditData={canEditData}
                onDeleteRow={(e, row) => { handleDeleteRow(e, 'compliance', row) }}
                onRowClick={(e, row) => { handleRowClick('compliance', row) }}
                value={value.framework_safeguard}
                columns={ComplianceColumnShort}
              />
            )}
            {value?.risk && (
              <RelationshipTable
                name='Risks'
                canEditData={canEditData}
                onDeleteRow={(e, row) => {
                  handleDeleteRow(e, 'risk', row)
                }}
                onRowClick={(e, row) => {
                  handleRowClick('risk', row)
                }}
                value={value.risk}
                columns={RiskColumnShort}
              />
            )}
            {value?.policy && (
              <RelationshipTable
                name='Policies'
                canEditData={canEditData}
                onDeleteRow={(e, row) => {
                  handleDeleteRow(e, 'policy', row)
                }}
                onRowClick={(e, row) => {
                  handleRowClick('policy', row)
                }}
                value={value.policy}
                columns={PolicyColumnsShort}
              />
            )}
            {value?.control && (
              <RelationshipTable
                name='Controls'
                canEditData={canEditData}
                onDeleteRow={(e, row) => {
                  handleDeleteRow(e, 'control', row)
                }}
                onRowClick={(e, row) => {
                  handleRowClick('control', row)
                }}
                value={value.control}
                columns={ControlColumnShort}
              />
            )}
          </tbody>
        </table>
      </div>
      <div className='flex flex-col'>
        <div className='inline-block min-w-full align-middle '></div>

        {canEditData && (
          <div className='mt-2 self-end overflow-visible'>
            <DropdownButton text='Add Connection' options={add_buttons} />
            {isAddPaletteOpen === 'policy' && (
              <AddPolicyPalette
                onAdd={(v) => {
                  handleSaveNewConnection('policy', v)
                }}
                isOpen={isAddPaletteOpen === 'policy'}
                alreadyused={value?.policy ? value.policy : []}
                onClose={handleCancelAddConnection}
              />
            )}
            {isAddPaletteOpen === 'compliance' && (
              <AddCompliancePalette
                onAdd={(v) => {
                  handleSaveNewConnection('compliance', v)
                }}
                isOpen={isAddPaletteOpen === 'compliance'}
                alreadyused={value?.framework_safeguard ? value.framework_safeguard : []}
                onClose={handleCancelAddConnection}
              />
            )}
            {isAddPaletteOpen === 'risk' && (
              <AddRiskPalette
                onAdd={(v) => {
                  handleSaveNewConnection('risk', v)
                }}
                isOpen={isAddPaletteOpen === 'risk'}
                alreadyused={value?.risk ? value.risk : []}
                onClose={handleCancelAddConnection}
              />
            )}
            {isAddPaletteOpen === 'control' && (
              <AddControlPalette
                onAdd={(v) => {
                  handleSaveNewConnection('control', v)
                }}
                isOpen={isAddPaletteOpen === 'control'}
                alreadyused={value?.control ? value.control : []}
                onClose={handleCancelAddConnection}
              />
            )}
          </div>
        )}
      </div>
    </>
  )
}
