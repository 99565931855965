import React, { useContext, useRef, useState } from 'react'

import { useNavigate, useParams } from 'react-router'
import { ControlDetail } from '../components/control/control-detail'
import { ControlForm } from '../components/control/control-form'
import { ObjectHeader } from '../components/header/object-header'
import { FullPageCard } from '../components/misc/full-page-card'
import { LoadingWheel } from '../components/misc/loading-wheel'
import { useControlByIdData } from '../hooks/db/useControlByIdData'
import { useDeleteConfirmationDialog } from '../hooks/useDeleteConfirmationDialog'
import { jToaster } from '../misc/j-toaster'
import { useAuth } from '../supabase'
import { UserInfoContext } from './root-page'
import uuid from 'react-uuid'

export const ControlPage = (props) => {
  const navigate = useNavigate()
  let { id } = useParams()

  if (id === 'new') id = undefined

  const { supabase } = useAuth()
  const { userInfo } = useContext(UserInfoContext);
  const { openDialog, DeleteConfirmationDialog } = useDeleteConfirmationDialog();

  const [isEditing, setIsEditing] = useState(id ? false : true)
  const [isSaving, setIsSaving] = useState(false)

  const { data, error, isLoading, refetch } = useControlByIdData(id, !id)

  const errorShownRef = useRef(false);

  const handleEditClick = () => {
    setIsEditing(true)
  }

  const handleTagChange = async (status) => {
    try {
      const { error } = await supabase.from('control').update({ status: status }).eq('id', id)

      if (error) throw error

      refetch()
    } catch (error) {
      console.error('Error control risk:', error)
    }
  }

  const actions_buttons = [
    { text: 'Active', onClickHandler: () => { handleTagChange('ACTIVE') }, disabled: data?.status === 'ACTIVE' },
    { text: 'Draft', onClickHandler: () => { handleTagChange('DRAFT') }, disabled: data?.status === 'DRAFT' },
    { text: 'Archived', onClickHandler: () => { handleTagChange('ARCHIVED') }, disabled: data?.status === 'ARCHIVED' }
  ]

  const handleDelete = async () => {
    openDialog(async () => {
      try {
        const { error } = await supabase
          .from('control') // Assuming 'user' is your table name
          .delete()
          .match({ id: id }) // Matching the user with the id
        if (error) throw error;

        jToaster.success('Control deleted successfully')
        navigate('/app/control')
      } catch (error) {
        jToaster.error('Failed to delete the policy version. Error code: ' + error?.code)

      }
    })
  }

  const handleSave = async (values) => {
    setIsSaving(true)

    try {
      const isNew = values?.id === undefined
      const controlId = isNew ? uuid() : values?.id

      let payload = {
        id: controlId,
        userdef_id: values.userdef_id,
        title: values.title,
        description: values.description,
        fk_project_id: userInfo.project.id,

        fk_owner_group_id: values.owner.group ? values.owner.id : null,
        fk_owner_user_id: !values.owner.group ? values.owner.id : null,

        fk_executor_group_id: values.executor.group ? values.executor.id : null,
        fk_executor_user_id: !values.executor.group ? values.executor.id : null,

        control_strategy: values.control_strategy,
        fk_workflow_template_id:
          !values?.workflow_template_id?.id || values.workflow_template_id.id === 'no-validation'
            ? null
            : values.workflow_template_id.id,
        readable_control_schedule_str: values.control_strategy === 'scheduled_control' ? values.control_schedule.schedule_str : null,
        rrule_control_schedule_str: values.control_strategy === 'scheduled_control' ? values.control_schedule.recurrent_str : null,
        
        custom_values: values?.custom_values,
      }

      const { error } = await supabase.from('control').upsert([{ ...payload }])
      if (error) throw error

      if(isNew) jToaster.success(`Control "${values?.userdef_id}" has been successfully created.`);

      if (isNew) {
        setIsEditing(false)
        setIsSaving(false)
        navigate(`/app/control/${controlId}`);
      }

      else {
        await refetch();
        setIsSaving(false)
        setIsEditing(false);
      }
    } catch (error) {
      jToaster.error('Failed to save control. Error code: ' + error?.code)
      console.error('Failed to save control. Error', error)
      setIsSaving(false)
    }
  }

  const handleCancelEdit = () => {
    if (id === undefined) navigate('/app/control')

    else setIsEditing(false);
  }

  if (error && !errorShownRef.current) {
    jToaster.error('Failed to load control information. Error code: ' + error.code)
    errorShownRef.current = true;
  }

  if (isLoading || isSaving) return <LoadingWheel />;
  if (error) return <></>
  
  return (
    <FullPageCard>
      <DeleteConfirmationDialog />

      {!isEditing && data &&
        <>
          <ObjectHeader
            title={data?.userdef_id ? data.userdef_id : 'Control'}
            backUrl={-1}

            actionButtonText={!isEditing ? 'Tag' : undefined}
            actionButtonOptions={!isEditing ? actions_buttons : undefined}

            secondaryButtonText={!isEditing ? 'Delete' : undefined}
            onSecondaryButtonClick={handleDelete}

            primaryButtonText={!isEditing ? 'Edit' : undefined}
            onPrimaryButtonClick={handleEditClick}

            noBorderBottom={!isEditing}
          />

          <ControlDetail
            controlData={data}
            refetch={refetch}
          />
        </>
      }
      {isEditing &&
        <>
          <ObjectHeader title={"Edit Control"} />
          <ControlForm
            initialData={data}
            customFields={userInfo.customFields?.control}
            onSubmit={handleSave}
            onCancel={handleCancelEdit}
          />
        </>
      }
    </FullPageCard>
  )
}
