import React, { useRef, useState } from 'react'

import { useParams } from 'react-router'
import { ComplianceDetail } from '../components/compliance/compliance-detail'
import { ObjectHeader } from '../components/header/object-header'
import { FullPageCard } from '../components/misc/full-page-card'
import { LoadingWheel } from '../components/misc/loading-wheel'
import { EditComplianceDrawer } from '../components/modal/edit-compliance-drawer'
import { useGenericFetchData } from '../hooks/db/useGenericFetchData'
import { jToaster } from '../misc/j-toaster'
import { complianceService } from '../services/complianceService'
import { useAuth } from '../supabase'

export const CompliancePage = (props) => {
  let { id } = useParams()

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const { supabase, session } = useAuth()

  const { data, isLoading, error, refetch } = useGenericFetchData(
    complianceService.getComplianceById,
    [supabase, id]
  );
  const errorShownRef = useRef(false);

  const handleEditClick = async () => {
    setIsDrawerOpen(true)
  }

  const onDrawerClose = async () => {
    await refetch()
    setIsDrawerOpen(false)
  }

  if (error && !errorShownRef.current) {
    jToaster.error('Failed to load compliance information. Error code: ' + error.code)
    errorShownRef.current = true;
  }

  if (isLoading) return <LoadingWheel />;
  if (error) return <></>

  return (
    <FullPageCard>
      <ObjectHeader
        title={'Compliance'}
        backUrl={-1}
        primaryButtonText='Edit'
        onPrimaryButtonClick={handleEditClick}
      />

      <EditComplianceDrawer
        onClose={onDrawerClose}
        open={isDrawerOpen}
        data={data}
      />

      <ComplianceDetail
        complianceData={data}
        refetch={refetch}
      />
    </FullPageCard>
  )
}
