import React, { Fragment, useContext, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import KeepyaLogo from '../assets/keepya-logo-text-white.svg'

import {
  AuditOutlined,
  CheckCircleOutlined,
  CheckSquareOutlined,
  ControlOutlined,
  FileProtectOutlined,
  FileTextOutlined,
  LogoutOutlined,
  OrderedListOutlined,
  UsergroupAddOutlined,
  WarningOutlined
} from '@ant-design/icons'
import { Dialog, Transition } from '@headlessui/react'

import {
  CodeBracketIcon,
  XMarkIcon
} from '@heroicons/react/24/outline'

import { jToaster } from '../misc/j-toaster'
import { UserInfoContext } from '../page/root-page'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const itemsMenuSuperuser = [
  {
    type: 'divider',
    key: 'div1'
  },/*
  {
    icon: <DashboardOutlined />,
    label: 'Dashboard',
    key: '/app/dashboard',
  },*/
  {
    icon: <FileTextOutlined />,
    label: 'TODO (Current User)',
    key: '/app/todo',
  },
  {
    icon: <FileTextOutlined />,
    label: 'Reports',
    key: '/app/report',
  },
  {
    icon: <AuditOutlined />,
    label: 'Audit',
    key: '/app/audit',
  },
  {
    type: 'divider',
    key: 'div2'
  },
  {
    icon: <CheckCircleOutlined />,
    label: 'Compliance',
    key: '/app/framework',
  },
  {
    icon: <WarningOutlined />,
    label: 'Risks',
    key: '/app/risk',
  },
  {
    icon: <FileProtectOutlined />,
    label: 'Policies',
    key: '/app/policy',
  },
  {
    icon: <ControlOutlined />,
    label: 'Controls',
    key: '/app/control',
  },
  {
    icon: <CheckSquareOutlined />,
    label: 'Tasks',
    key: '/app/task',
  },
  {
    icon: <OrderedListOutlined />,
    label: 'Workflow',
    key: '/app/workflow',
  },
  {
    icon: <UsergroupAddOutlined />,
    label: 'Stakeholders',
    key: '/app/stakeholders',
  },
  {
    type: 'divider',
    key: 'div3'
  },
  {
    icon: <LogoutOutlined />,
    label: 'Signout',
    key: 'signout'
  }
]

const itemsMenuNotSuperuser = [
  {
    type: 'divider',
    key: 'div1'
  },
  {
    icon: <FileTextOutlined />,
    label: 'TODO (Current User)',
    key: '/app/todo',
  },
  {
    type: 'divider',
    key: 'div2'
  },
  {
    icon: <LogoutOutlined />,
    label: 'Signout',
    key: 'signout'
  }
]

const SidebarComponent = (props) => {
  const { userInfo, selectedKey, navigation, onElementClick } = props

  return (
    <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-4 pb-2 ring-1 ring-white/10">
      <div className="flex justify-center items-center w-32 h-32 mx-auto mt-2">
        <img src={KeepyaLogo} alt="Keepya Logo" className="w-full h-full object-contain" />
      </div>
      <div className='bg-white bg-opacity-5 p-4 rounded-md flex flex-col cursor-default justify-between space-y-1.5'>
        <span className='white-font'>Tenant: {userInfo.project.name}</span>
        <span className='gray-font'>Timezone: {userInfo.project.timezone}</span>
        <span className='gray-font'>Subscription: {userInfo.project.subscription_end_date}</span>
        <span className='white-font'>User: {userInfo.name} [{userInfo.type}]</span>
      </div>

      <nav className="flex flex-1 flex-col">
        <ul role="list" className="flex flex-1 flex-col gap-y-7">
          <li>
            <ul key="aezdzadaz" role="list" className="-mx-2 space-y-1">
              {navigation.map((item) => (
                <React.Fragment key={item.key}>
                  {item.type === 'divider' &&
                    <hr className='my-2 border-gray-700' />
                  }
                  {item.type != 'divider' &&
                    <li onClick={() => { onElementClick(item) }}>
                      <a
                        className={classNames(
                          item.key === selectedKey
                            ? 'bg-blue-900 text-white'
                            : 'text-gray-400 hover:text-white hover:bg-gray-800',
                          'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                        )}
                      >
                        {item.icon}
                        {item.label}
                      </a>
                    </li>
                  }
                </React.Fragment>
              ))}
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  )
}

export const MainMenu = (props) => {
  const navigate = useNavigate()
  const { userInfo } = useContext(UserInfoContext)

  if (!userInfo) {
    return <></>
  }

  const { onSignoutClick, isSuperUser } = props

  // Determine the selected key for the menu
  let selectedKey = location.pathname

  if (selectedKey.startsWith('/app/policy')) {
    selectedKey = '/app/policy'
  } else if (selectedKey.startsWith('/app/control')) {
    selectedKey = '/app/control'
  } else if (selectedKey.startsWith('/app/todo')) {
    selectedKey = '/app/todo'
  } else if (selectedKey.startsWith('/app/risk')) {
    selectedKey = '/app/risk'
  } else if (selectedKey.startsWith('/app/stakeholders') || selectedKey.startsWith('/app/user') || selectedKey.startsWith('/app/group')) {
    selectedKey = '/app/stakeholders'
  } else if (selectedKey.startsWith('/app/compliance') || selectedKey.startsWith('/app/framework')) {
    selectedKey = '/app/framework'
  } else if (selectedKey.startsWith('/app/task')) {
    selectedKey = '/app/task'
  } else if (selectedKey.startsWith('/app/workflow')) {
    selectedKey = '/app/workflow'
  }

  const handleClick = (item) => {
    if (item.key === 'signout') {
      onSignoutClick()
    } else {
      navigate(item.key)
    }
  }

  const [sidebarOpen, setSidebarOpen] = useState(false)

  return (
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <SidebarComponent userInfo={userInfo} navigation={isSuperUser ? itemsMenuSuperuser : itemsMenuNotSuperuser} onElementClick={handleClick} selectedKey={selectedKey} />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <SidebarComponent userInfo={userInfo} navigation={isSuperUser ? itemsMenuSuperuser : itemsMenuNotSuperuser} onElementClick={handleClick} selectedKey={selectedKey} />
      </div>

      <button type="button" className="m-2 p-2 bg-blue-400 text-white lg:hidden rounded-xl hover:bg-blue-800" onClick={() => setSidebarOpen(true)}>
        <CodeBracketIcon className='h-6 w-6 text-white' />
      </button>
    </div>
  )
}
