import React from 'react'
import KeepyaLogo from '../../assets/keepya-logo.svg'

export const AuthCard = ({ children }) => {

  return (
    <div className='flex flex-col items-center gap-4 border shadow-xl p-5 rounded-lg mt-4 bg-white w-full max-w-xl'>
      <div className="w-48 h-48">
        <img src={KeepyaLogo} alt="Keepya Logo" className="w-full h-full object-contain" />
      </div>
      <div className='border-t w-full pb-4'></div>
      <div className='flex flex-col items-center gap-4 w-full'>
        {children}
      </div>
    </div>
  )
}
