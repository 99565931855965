import React, { useRef } from 'react'

import { useNavigate, useParams } from 'react-router'
import { ObjectHeader } from '../components/header/object-header'
import { FullPageCard } from '../components/misc/full-page-card'
import { LoadingWheel } from '../components/misc/loading-wheel'
import { PolicyDetail } from '../components/policy/policy-detail'
import { usePolicyData } from '../hooks/db/usePolicyData'
import { useDeleteConfirmationDialog } from '../hooks/useDeleteConfirmationDialog'
import { jToaster } from '../misc/j-toaster'
import { policyService } from '../services/policyService'
import { useAuth } from '../supabase'

export const PolicyPage = () => {
  const navigate = useNavigate()
  let { id } = useParams()

  if (id === 'new') id = undefined

  const { supabase } = useAuth()
  const { openDialog, DeleteConfirmationDialog } = useDeleteConfirmationDialog();

  const { data: policyData, error, isLoading, refetch } = usePolicyData(supabase, id);
  const errorShownRef = useRef(false);

  const handleTagChange = async (status) => {
    try {
      await policyService.updatePolicyStatus(supabase, id, status)
      refetch()
    } catch (error) {
      console.error('Error updating policy:', error)
    }
  }

  const handleDelete = async () => {
    openDialog(async () => {
      try {
        await policyService.deletePolicy(supabase, id)

        jToaster.success('Policy deleted successfully')
        navigate('/app/policy')
      } catch (error) {
        jToaster.error('Failed to delete the policy. Error code: ' + error?.code);
      }
    })
  }

  const actions_buttons = [
    {
      text: 'Active',
      onClickHandler: () => { handleTagChange('ACTIVE') },
      disabled: policyData?.status ? policyData.status === 'ACTIVE' : true
    },
    {
      text: 'Draft',
      onClickHandler: () => { handleTagChange('DRAFT') },
      disabled: policyData?.status ? policyData.status === 'DRAFT' : true
    },
    {
      text: 'Archived',
      onClickHandler: () => { handleTagChange('ARCHIVED') },
      disabled: policyData?.status ? policyData.status === 'ARCHIVED' : true
    }
  ]

  if (error && !errorShownRef.current) {
    jToaster.error('Failed to load policy information. Error code: ' + error.code)
    errorShownRef.current = true;
  }

  if (isLoading) return <LoadingWheel />;
  if (error) return <></>

  return (
    <FullPageCard>
      <ObjectHeader
        title={'Policy: ' + (policyData?.userdef_id ? policyData?.userdef_id : '')}
        backUrl={-1}
        actionButtonText={'Tag'}
        actionButtonOptions={actions_buttons}
        secondaryButtonText={'Delete'}
        onSecondaryButtonClick={handleDelete}
        noBorderBottom={true}
      />
      <DeleteConfirmationDialog />

      <PolicyDetail
        policyData={policyData}
        refetch={refetch}
      />
    </FullPageCard >
  )
}
