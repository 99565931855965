import { Combobox, Dialog, Transition } from '@headlessui/react'
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { Fragment, useState } from 'react'
import { LoadingWheel } from '../misc/loading-wheel'
import React from 'react'
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const AddPalette = (props) => {
  const { options, open, onClick, onClose, loading } = props
  const [query, setQuery] = useState('')

  const flteredOptions =
    query === ''
      ? options
      : options.filter((person) => {
          return person.comboboxName.toLowerCase().includes(query.toLowerCase())
        })

  return (
    <Transition.Root show={open} as={Fragment} afterLeave={() => setQuery('')} appear>
      <Dialog as='div' className='relative z-10' onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 scale-95'
            enterTo='opacity-100 scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 scale-100'
            leaveTo='opacity-0 scale-95'
          >
            <Dialog.Panel className='mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all'>
              {loading && (
                <div className='py-8'>
                  <LoadingWheel />
                </div>
              )}
              {!loading && (
                <Combobox onChange={onClick}>
                  <div className='relative'>
                    <MagnifyingGlassIcon className='pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400' aria-hidden='true' />
                    <Combobox.Input
                      className='h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm'
                      placeholder='Search...'
                      onChange={(event) => setQuery(event.target.value)}
                    />
                  </div>

                  {flteredOptions.length > 0 && (
                    <Combobox.Options static className='max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-800'>
                      {flteredOptions.map((o) => (
                        <Combobox.Option
                          key={o.id}
                          value={o}
                          className={({ active }) =>
                            classNames('cursor-pointer select-none px-4 py-2', active && 'bg-indigo-600 text-white')
                          }
                        >
                          {o.comboboxName}
                        </Combobox.Option>
                      ))}
                    </Combobox.Options>
                  )}

                  {query !== '' && flteredOptions.length === 0 && <p className='p-4 text-sm text-gray-500'>No options found.</p>}
                </Combobox>
              )}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
