import React, { useState } from 'react'
import { ActionButton } from '../form/action-button'
import { FilterDrawer } from '../misc/filter-drawer'

export const ObjectSearchFilter = (props) => {
  const { onFilterChange, activeFilters, filterOptions, extraButtons, onClickExtraButton } = props

  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false)

  const handleClickFilter = () => {
    setFilterDrawerOpen(true)
  }

  const handleFilterDrawerClose = () => {
    setFilterDrawerOpen(false)
  }

  return (
    <div className='my-4'>
      <div className='flex items-center gap-x-4'>
        <input
          type='text'
          name='search'
          className='block w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
          placeholder='Search'
          autoComplete="off"
          onChange={(e) => {
            onFilterChange({ target: { name: 'search', checked: e.target.value } })
          }}
        />
        {extraButtons &&
          <ActionButton onClick={onClickExtraButton} styleType={'error'}>
            {extraButtons}
          </ActionButton>
        }
        {filterOptions && activeFilters && (
          <ActionButton onClick={handleClickFilter}>
            Filter ({activeFilters ? Object.values(activeFilters).filter((value) => value === true)?.length : 0})
          </ActionButton>
        )}
      </div>
      <FilterDrawer
        open={filterDrawerOpen}
        onClose={handleFilterDrawerClose}
        filterOptions={filterOptions}
        onFilterChange={onFilterChange}
        activeFilters={activeFilters}
      />
    </div>
  )
}
