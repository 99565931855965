import { faUser, faUsers } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext, useEffect, useState } from 'react'
import { UserInfoContext } from '../../page/root-page'
import { useAuth } from '../../supabase'
import { ComboBox } from './combo-box'

const RenderComboRow = (props) => {
  const { row } = props

  return (
    <div className=''>
      {row.group && <FontAwesomeIcon icon={faUsers} className='mr-2' />}
      {!row.group && <FontAwesomeIcon icon={faUser} className='mr-2' />}
      {row.comboboxName}
    </div>
  )
}

export function AddStakeholderInput(props) {
  const { value, onChange, disabled } = props

  /*------Query Data-------*/
  const { supabase, session } = useAuth()
  const { userInfo } = useContext(UserInfoContext)

  const [isLoading, setIsLoading] = useState(true)
  const [options, setOptions] = useState([])
  const [queryError, setQuerryError] = useState(null)

  const fetchData = async () => {
    try {
      // Query user information
      const { data: userData, error: userError } = await supabase.from('user').select('*').eq('fk_project_id', userInfo?.project?.id)

      if (userError) throw userError

      // Query group information
      const { data: groupData, error: groupError } = await supabase.from('group').select('*').eq('fk_project_id', userInfo?.project?.id)

      if (groupError) throw groupError

      // Process and combine user and group data
      const processedUserData = (userData ?? []).map((user) => ({ ...user, comboboxName: user.name, group: false }))
      const processedGroupData = (groupData ?? []).map((group) => ({ ...group, comboboxName: group.name, group: true }))
      const combinedData = processedUserData.concat(processedGroupData)

      // Update states
      setOptions(combinedData)
    } catch (error) {
      console.error('Failed to fetch data:', error)
      setQuerryError(error)
    }

    setIsLoading(false)
  }

  // Fetch user and group data
  useEffect(() => {
    fetchData()
  }, [supabase]) // Dependency on supabase context or client

  if (isLoading || queryError) {
  }

  return <ComboBox options={options} value={value} onChange={onChange} RenderComponent={RenderComboRow} disabled={disabled} />
}
