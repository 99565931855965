import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { ActionButton } from '../form/action-button'
import { AddStakeholderInput } from '../form/add-stakeholder-input'
import { TextInput } from '../form/inputs'
import { RadioExecutionScheduler } from '../form/radio-execution-scheduler'
import { Stepper } from '../header/stepper'

import { AddWorkflowInput } from '../form/add-workflow-input'
import { AdditionnalFieldsForm } from '../form/addtionnal-fields-form'

const errorMessages = {
  required: "This field is required",
  maxLength: (len) => `Maximum length is ${len} characters`,
};

const PanelDescription = (props) => {
  const { register, errors, control, hidden, customFields } = props

  return (
    <div className='space-y-4' hidden={hidden}>
      <div>
        <label className='block text-sm font-medium text-gray-700 mb-1'>Owner</label>
        <Controller
          control={control}
          name='owner'
          rules={{ required: 'Owner is required' }}
          errorMessage={errors?.owner ? 'This field is required' : null}
          render={({ field: { value, onChange } }) =>
            <AddStakeholderInput value={value} onChange={onChange}
            />}
        />
        {errors?.owner && <p className='text-red-500 text-xs italic'>{errors.owner.message}</p>}
      </div>

      <div className="my-6 h-0.5 bg-gradient-to-r from-gray-300 via-gray-400 to-gray-500"></div>

      <TextInput
        label='ID'
        name='userdef_id'
        dataTestid='control-userdef_id'
        errorMessage={errors?.userdef_id ? 'This field is required, maximum 12 characters' : null}
        register_fn={() => {
          return register('userdef_id', { required: true, maxLength: 12 })
        }}
      />
      <TextInput
        label='Title'
        name='title'
        dataTestid='control-title'
        errorMessage={errors?.title ? 'This field is required, maximum 128 characters' : null}
        register_fn={() => {
          return register('title', { required: true, maxLength: 128 })
        }}
      />
      <TextInput
        label='Description'
        name='description'
        dataTestid='control-description'
        rows={15}
        errorMessage={errors?.description ? 'This field is required' : null}
        register_fn={() => {
          return register('description', { required: true })
        }}
      />
      <AdditionnalFieldsForm
        control={control}
        register={register}
        errors={errors}
        customFields={customFields}
      />
    </div>
  )
}

const PanelSchedule = (props) => {
  const { register, errors, control, formValues, hidden } = props

  return (
    <div className='space-y-4' hidden={hidden}>
      <div>
        <label className='block text-sm font-medium text-gray-700 mb-1'>Control Operator</label>
        <Controller
          control={control}
          name='executor'
          rules={{ required: 'Control Operator is required' }}
          render={({ field: { value, onChange } }) => <AddStakeholderInput value={value} onChange={onChange} />}
        />
        {errors?.executor && <p className='text-red-500 text-xs italic'>{errors.executor.message}</p>}
      </div>
      <div>
        <label className='block text-sm font-medium text-gray-700 mb-1'>Validation Workflow</label>

        <Controller
          control={control}
          name="workflow_template_id"
          rules={{ required: errorMessages.required }}
          render={({ field: { value, onChange } }) => (
            <AddWorkflowInput value={value} onChange={onChange} />
          )}
        />
        {errors.workflow_template_id && <p className="text-red-500 text-xs italic">{errors.workflow_template_id.message}</p>}
      </div>
      <div>
        <label className='block text-sm font-medium text-gray-700 mb-1'>Scheduling</label>
        <RadioExecutionScheduler
          control={control}
          rules={{ required: true }}
          value={formValues?.control_strategy}
          register_fn={() => {
            return register('control_strategy')
          }}
        />
      </div>
    </div>
  )
}

export const ControlForm = (props) => {
  const { initialData, onSubmit, onCancel, customFields } = props;

  const [activeStep, setActiveStep] = useState(0)
  const steps = [
    { id: 'description', title: 'Description' },
    { id: 'schedule', title: 'Scheduled Tasks' },
  ]

  const defaultValues = {
    id: initialData?.id || undefined,
    userdef_id: initialData?.userdef_id || '',
    title: initialData?.title || '',
    description: initialData?.description || '',
    custom_values: initialData?.custom_values || {},
    owner: initialData?.owner_group
      ? { ...initialData.owner_group, comboboxName: initialData.owner_group.name, group: true }
      : initialData?.owner_user ? { ...initialData.owner_user, comboboxName: initialData.owner_user.name, group: false }
        : null,
    review_schedule: {
      schedule_str: initialData?.readable_schedule_str || '',
      recurrent_str: initialData?.rrule_schedule_str || ''
    },
    control_strategy: initialData?.control_strategy || '',
    workflow_template_id: initialData?.workflow_template
      ? { ...initialData.workflow_template, comboboxName: initialData.workflow_template.name }
      : { id: 'no-validation', comboboxName: 'NO-VALIDATION' },
    executor: initialData?.executor_group
      ? { ...initialData.executor_group, comboboxName: initialData.executor_group.name, group: true }
      : initialData?.executor_user ? { ...initialData.executor_user, comboboxName: initialData.executor_user.name, group: false }
        : null,
    control_schedule: {
      schedule_str: initialData?.readable_control_schedule_str || '',
      recurrent_str: initialData?.rrule_control_schedule_str || ''
    }
  }
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
    control
  } = useForm({ defaultValues: defaultValues, mode: 'onBlur' })

  const values = watch()

  const errorByPanel = {
    description: ['userdef_id', 'title', 'description'].some((field) => errors[field]),
    schedule: ['executor', 'control_schedule'].some((field) => errors[field]),
  }

  const hasNextStep = activeStep < steps.length - 1
  const handleStepperChange = (id) => { setActiveStep(id) }

  return (
    <>
      <Stepper errorByPanel={errorByPanel}
        steps={steps}
        activeStep={activeStep}
        onStepperChange={handleStepperChange}
      />
      <form id='control-edit-form' onSubmit={handleSubmit(onSubmit)}>
        <div className='pt-4'>
          <PanelDescription
            customFields={customFields}
            hidden={activeStep !== 0}
            register={register}
            control={control}
            errors={errors}
          />
          <PanelSchedule
            hidden={activeStep !== 1}
            register={register}
            formValues={values}
            control={control}
            errors={errors}
          />
        </div>

        <div className='flex justify-end gap-2 mt-2 border-t pt-2'>
          <ActionButton onClick={onCancel} styleType='secondary' type='button'>
            Cancel
          </ActionButton>
          {hasNextStep && (
            <ActionButton
              onClick={() => { handleStepperChange(activeStep + 1) }}
              dataTestid='control-form-next'
              styleType='secondary'
              type='button'
            >
              Next
            </ActionButton>
          )}
          {!hasNextStep && (
            <ActionButton dataTestid='control-form-save' disabled={!isValid} styleType='primary' type='submit'>
              Save
            </ActionButton>
          )}
        </div>
      </form>
    </>
  )
}
