
export const riskService = {
  async getRiskById(supabase, id) {
    const { data, error } = await supabase
      .from('risk')
      .select(
        `
        *, 
        framework_safeguard(*, framework(name)), 
        control(*), 
        policy(*),
        user(*),
        group(*)
      `
      )
      .eq('id', id)
      .single()

    if (error) throw error
    return data
  },

  async getRiskWithCustomFields(supabase, id) {
    const { data, error } = await supabase
      .from('risk_with_custom_fields')
      .select(
        `
        *, 
        framework_safeguard(*, framework(name)), 
        control(*), 
        policy(*),
        user(*),
        group(*)
      `
      )
      .eq('risk_id', id)
      .single()

    if (error) throw error
    return data
  },

  async upsertRisk(supabase, values) {
    const { data, error } = await supabase.from('risk').upsert([{ ...values }])
    if (error) throw error

    return data
  },

  async getAllRisk(supabase, project_id) {
    const { data, error } = await supabase
      .from('risk')
      .select('*, group(*), user(*)')
      .eq('fk_project_id', project_id)
      .order('created_at', { ascending: false })

    if (error) throw error
    return data
  }
}