import React, { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'
import uuid from 'react-uuid'
import { UserInfoContext } from '../../page/root-page'
import { useAuth } from '../../supabase'
import { ActionButton } from '../form/action-button'
import { TextInput } from '../form/inputs'
import { LoadingWheel } from '../misc/loading-wheel'
import { JTable } from '../table/j-table'

export const GroupForm = (props) => {
  const { id, UserTableColumns } = props

  const isEditing = id ? true : false
  const [disableButtons, setDisableButtons] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const navigate = useNavigate()
  const { userInfo } = useContext(UserInfoContext)
  const { supabase } = useAuth()

  const defaultValues = {
    user: [],
    name: ''
  }

  const { register, handleSubmit, setValue, errors, control } = useForm({ defaultValues: defaultValues })

  useEffect(() => {
    if (id) {
      // Fetch group data and populate form
      supabase
        .from('group')
        .select('*, user(*)')
        .eq('id', id)
        .single()
        .then(({ data, error }) => {
          if (error) {
            console.error('Error fetching group:', error)
            return
          }
          // Use setValue to update form fields
          setValue('name', data.name)
          setValue('user', data.user)

          setIsLoading(false)
        })
    }
  }, [id, supabase])

  const handleClickCancel = () => {
    navigate('/app/stakeholders')
  }

  const onSubmit = async (data) => {
    setDisableButtons(true)
    const newId = uuid()

    if (isEditing) {
      // Update existing group
      const { error } = await supabase.from('group').update({ name: data.name }).match({ id: id })

      if (error) {
        console.error('Error updating group:', error)
        return
      }

      // Delete any associations for this group that are not in the newUserIds list
      const { error: deleteError } = await supabase.from('af_user_group').delete().eq('fk_group_id', id) // matches the group

      if (deleteError) {
        console.error('Error deleting stale user group relationships:', deleteError)
        return // Early return on error
      }
    } else {
      // Insert new group
      const { data: newGroupData, error } = await supabase
        .from('group')
        .insert([
          {
            id: newId,
            name: data.name,
            fk_project_id: userInfo.project.id
          }
        ])
        .single()

      if (error) {
        console.error('Error creating group:', error)
        return
      }
    }

    // Update af_user_group with the new or updated group ID for each user
    for (const user of data.user) {
      const { error } = await supabase.from('af_user_group').upsert([
        {
          fk_user_id: user.id,
          fk_group_id: id ? id : newId,
          fk_project_id: userInfo.project.id
        }
      ])

      if (error) {
        console.error('Error af_user_group:', error)
        return
      }
    }
    setDisableButtons(false)
    navigate('/app/stakeholders')
  }

  if (isEditing && isLoading) {
    return <LoadingWheel />
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='space-y-4'>
      <TextInput
        label='Name'
        dataTestid='group-name-form'
        name='name'
        errorMessage={errors?.name ? 'This field is required' : null}
        register_fn={() => {
          return register('name', { required: true })
        }}
      />
      <div>
        <label className='block text-sm font-medium text-gray-700 mb-1'>Members</label>
        <Controller
          control={control}
          name='user'
          render={({ field: { value, onChange } }) => (
            <JTable value={value} onChange={onChange} columns={UserTableColumns} canEditData={true} AddElementModalType={'user'} />
          )}
        />
      </div>
      <div className='flex justify-end gap-2'>
        <ActionButton disabled={disableButtons} onClick={handleClickCancel} styleType='secondary' type='button'>
          Cancel
        </ActionButton>
        <ActionButton dataTestid='group-create-button' disabled={disableButtons} type='submit'>
          {isEditing ? 'Save' : 'Create'}
        </ActionButton>
      </div>
    </form>
  )
}
