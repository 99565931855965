import { faPaperclip, faUpload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import uuid from 'react-uuid'

const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const FileUploader = (props) => {
  const { value, onChange } = props

  const handleChange = async (e) => {
    const files = e.target.files; // Get the file(s) selected
    const newFiles = await Promise.all(
      Array.from(files)
        .filter((file) => file.size <= 10485760) // Filter files based on size limit (10MB)
        .map(async (file) => {
          const fileBase64 = await fileToBase64(file); // Convert file to base64
          return {
            name: file.name,
            size: file.size,
            type: file.type,
            file: file, // Original file object
            fileb64: fileBase64, // Base64 version of file content
            attachment_id: uuid(), // Generate unique ID for each file
          };
        })
    );
    // Merge existing files with new files
    const combinedFiles = [...(value || []), ...newFiles];

    // Update the parent component with the new combined list of files
    onChange(combinedFiles);
  };

  const handleRemove = (e) => {
    const updatedFiles = value.filter((file) => file.attachment_id !== e.attachment_id)
    onChange(updatedFiles)
  }

  const fileSizeInMB = (fileSizeInBytes) => (fileSizeInBytes / 1048576).toFixed(1) + 'MB'

  return (
    <dd className='mt-2 text-sm text-gray-900 sm:col-span-2 mt-4'>
      <ul role='list' className='divide-y divide-gray-100 rounded-md border border-gray-200'>
        {value?.map((file) => (
          <li key={file.attachment_id} className='flex items-center justify-between py-2 pl-4 pr-5 text-sm leading-6'>
            <div className='flex w-0 flex-1 items-center'>
              <FontAwesomeIcon icon={faPaperclip} className='h-5 w-5 flex-shrink-0 text-gray-400' aria-hidden='true' />
              <div className='ml-4 flex min-w-0 flex-1 gap-2'>
                <span className='truncate font-medium'>{file.name}</span>
                <span className='flex-shrink-0 text-gray-400'>{fileSizeInMB(file.size)}</span>
              </div>
            </div>
            <div className='ml-4 flex-shrink-0'>
              <a className='font-medium text-indigo-600 hover:text-indigo-500' onClick={() => handleRemove(file)}>
                Remove
              </a>
            </div>
          </li>
        ))}
        <li key={'add'} className='flex items-center py-2 pl-4 pr-5 text-sm leading-6'>
          <label
            htmlFor='file-upload'
            className='flex flex-row -centeritems relative py-2 cursor-pointer w-full bg-white rounded-md text-xs font-bold text-indigo-600 hover:text-indigo-500'
          >
            <FontAwesomeIcon icon={faUpload} className='mx-auto h-4 w-4 text-indigo-600 mr-2' />
            <span>Upload files</span>
            <input id='file-upload' name='file-upload' type='file' multiple className='sr-only' onChange={handleChange} />
          </label>
        </li>
      </ul>
    </dd>
  )
}
