import React, { useContext, useState } from 'react'
import { UserInfoContext } from '../../page/root-page'
import { useAuth } from '../../supabase'
import { ElementDetail } from '../element/element-detail'
import { jFormatDateAndTime, RenderGroupOrUser, StatusTag } from '../misc/columns_def'
import { TabHeader } from '../misc/tab-header'
import { ConnectionTable } from '../table/connection-table'
import { TasksTable } from '../table/tasks-table'

const getControlDetailSpec = (customFields) => {
  return [
    { key: 'tag', label: 'Tag', render: StatusTag },
    { key: 'created_at', label: 'Creation Date', render: (e) => <span>{jFormatDateAndTime(e.created_at)}</span> },
    { type: 'divider', label: 'Description', },
    { key: 'owner', label: 'Owner', render: (e) => <RenderGroupOrUser user={e?.owner_user} group={e?.owner_group} /> },
    { key: 'userdef_id', label: 'Control ID' },
    { key: 'title', label: 'Title' },
    { key: 'description', label: 'Description' },
    ...(customFields && customFields.length > 0
      ? [
        { type: 'divider', label: 'Custom Fields', key: 'divcustomfields' },
        ...customFields.map((field) => ({
          key: field.field_name,
          label: field.field_name,
          render: field.field_type === 'date' ? (e) => (<span>{JFormatDate(e[field.field_name])}</span>) : undefined, // Only define render if the field is a date
        }))
      ]
      : []
    ),
  ];
}

const schedule_spec = [
  {
    key: 'approbator',
    label: 'Validation Workflow',
    render: (e) => (
      <label className='px-2 py-1 font-bold bg-slate-50 border uppercase text-xs text-sm leading-6 text-gray-900'>
        {e?.workflow_template?.name ? e?.workflow_template?.name : 'NO-VALIDATION'}
      </label>
    )
  },
  {
    key: 'control_strategy',
    label: 'Schedule Strategy',
    render: (e) => (
      <>
        {e.control_strategy === 'scheduled_control' && (
          <label htmlFor='requireApproval' className='px-2 py-1 font-bold bg-slate-50 border uppercase text-xs text-sm leading-6 text-gray-900'>
            Automated Schedule
          </label>
        )}
        {
          e.control_strategy === 'nocontrol' && (
            <label htmlFor='requireApproval' className='px-2 py-1 font-bold bg-slate-50 border uppercase text-xs text-sm leading-6 text-gray-900'>
              Manual Scheduling
            </label>
          )
        }
      </>
    )
  },
  { key: 'readable_control_schedule_str', label: 'Schedule' },
  { key: 'executor', label: 'Executor', render: (e) => <RenderGroupOrUser user={e?.executor_user} group={e?.executor_group} /> },
]

export const ControlDetail = (props) => {
  const { userInfo } = useContext(UserInfoContext)
  const { supabase } = useAuth()

  const { controlData, refetch } = props

  const [selectedTab, setSelectedTab] = useState('Description')

  const control_detail_spec = getControlDetailSpec(userInfo?.customFields?.control)

  const tabs = [
    { name: 'Description' },
    { name: 'Schedule', disabled: controlData?.control_strategy === 'nocontrol' },
    { name: 'Tasks' },
    { name: 'Connections' }
  ]

  const handleNewConnection = async (type, value) => {
    if (type === 'compliance') {
      await supabase.from('af_compliance_control').upsert({
        fk_control_id: controlData.id,
        fk_framework_safeguard_id: value.id,
        fk_project_id: userInfo.project.id
      })
    } else if (type === 'risk') {
      await supabase.from('af_control_risk').upsert({
        fk_control_id: controlData.id,
        fk_risk_id: value.id,
        fk_project_id: userInfo.project.id
      })
    } else if (type === 'policy') {
      await supabase.from('af_policy_control').upsert({
        fk_control_id: controlData.id,
        fk_policy_id: value.id,
        fk_project_id: userInfo.project.id
      })
    }
    await refetch();
  }

  const handleDeleteConnection = async (type, value) => {
    if (type === 'compliance') {
      await supabase
        .from('af_compliance_control')
        .delete()
        .eq('fk_control_id', controlData.id)
        .eq('fk_framework_safeguard_id', value.id)
    } else if (type === 'risk') {
      await supabase
        .from('af_control_risk')
        .delete()
        .eq('fk_control_id', controlData.id)
        .eq('fk_risk_id', value.id)
    } else if (type === 'policy') {
      await supabase
        .from('af_policy_control')
        .delete()
        .eq('fk_control_id', controlData.id)
        .eq('fk_policy_id', value.id)
    }
    await refetch();
  }

  return (
    <>
      <TabHeader
        tabs={tabs}
        selectedTab={selectedTab}
        onSelectedTabChange={(e) => setSelectedTab(e)}
      />
      <div>
        {selectedTab === 'Description' &&
          <ElementDetail
            detailSpec={control_detail_spec}
            data={controlData}
          />
        }
        {selectedTab === 'Connections' &&
          <ConnectionTable
            value={controlData}
            canEditData={true}

            onNewConnection={handleNewConnection}
            onDeleteConnection={handleDeleteConnection}
          />
        }
        <TasksTable
          hidden={selectedTab !== 'Tasks'}

          associatedId={controlData?.id}
          onlyScheduled={false}
        />

        {selectedTab === 'Schedule' &&
          <>
            <div className='border-b'>
              <ElementDetail
                detailSpec={schedule_spec}
                data={controlData}
              />
            </div>
            <TasksTable
              associatedId={controlData?.id}
              onlyScheduled={true}
            />
          </>
        }
      </div>
    </>
  )
}
