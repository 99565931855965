// useTask.js
import { useEffect, useRef, useState } from 'react';
import { controlService } from '../../services/controlService';
import { useAuth } from '../../supabase';

export const useControlByIdData = (id, skip = false) => {
  const { supabase } = useAuth();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    if (!data) setIsLoading(true);
    try {
      const data = await controlService.getControlById(supabase, id);

      if (data && data.framework_safeguard) {
        data.framework_safeguard = data.framework_safeguard.map((safeguard) => ({
          ...safeguard,
          framework_name: safeguard.framework?.name || 'Unknown Framework', // Add framework name to each safeguard
        }))
      }
      const dataFinal = {...data, ...data.custom_values};

      setData(dataFinal ?? {})
    } catch (error) {
      setError(error);
    }
    if(!data) setIsLoading(false);
  };

  useEffect(() => {
    if (!skip) {
      fetchData();
    } else {
      setIsLoading(false)
    }
  }, [supabase, id]);

  return { data, isLoading, error, refetch: fetchData };
};
