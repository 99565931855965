import { ControlOutlined } from '@ant-design/icons'
import { faUser, faUsers } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useNavigate } from 'react-router'
import { Tag } from '../../misc/tag'
import { Tooltip } from './tooltip'

export const jFormatDateAndTime = (dateString) => {
  const date = new Date(dateString)
  const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit'}
  const formattedDate = date.toLocaleDateString('en-us', options)

  return formattedDate
}

export const JFormatDate = (dateString) => {
  const date = new Date(dateString ? dateString : new Date())
  const options = { year: 'numeric', month: 'long', day: 'numeric'}
  const formattedDate = date.toLocaleDateString('en-us', options)

  return formattedDate
}


export const RenderGroupOrUser = ({ user, group }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (group) {
      navigate(`/app/group/${group.id}`);
    } else if (user) {
      navigate(`/app/user/${user.id}`);
    }
  };

  return (
    <div
      className={`border hover:bg-slate-100 hover:border-blue-200 text-sm rounded-xl cursor-pointer px-2 py-1 inline-block border-slate-300 bg-slate-50`}
      onClick={handleClick}
    >
      {group &&
        <>
          <FontAwesomeIcon icon={faUsers} className='mr-2 text-xs' />
          <span>{group?.name}</span>
        </>
      }
      {user &&
        <>
          <FontAwesomeIcon icon={faUser} className='mr-2 text-xs' />
          <span>{user?.name}</span>
        </>
      }
      {!user && !group && <span>N/D</span>}
    </div>
  );
};

export const StatusTag = (e) => (
  <>
    {e?.status === 'ACTIVE' && <Tag text={e?.status} />}
    {e?.status === 'ARCHIVED' && <Tag text={e?.status} type='gray' />}
    {e?.status === 'DRAFT' && <Tag text={e?.status} type='yellow' />}
  </>
)

export const TaskStatusTag = (e) => (
  <>
    {e?.status === 'APPROVED' && <Tag text={e?.status} />}
    {e?.status === 'REJECTED' && <Tag text={e?.status} type='error' />}
    {e?.status === 'PENDING_APPROVAL' && <Tag text={e?.status} type='yellow' />}
    {e?.status === 'TODO' && <Tag text={e?.status} type='error' />}
  </>
)

/*-----------------------------Policy--------------------------------------*/
export const PolicyColumnsShort = [
  { key: 'userdef_id', sortable: true, label: 'ID', style: 'text-center whitespace-nowrap' },
  { key: 'title', label: 'Title', style: 'break-words w-full ' },
  {
    key: 'status',
    label: 'Tag',
    sortable: true,
    style: 'w-4',
    render: StatusTag,
    style: 'text-center whitespace-nowrap'
  }
]

export const PolicyColumnsFull = [
  { key: 'userdef_id', label: 'ID', sortable: true, style: 'text-center whitespace-nowrap' },
  {
    key: 'title',
    label: 'Title',
    style: 'break-words w-full ',
  },
  {
    key: 'created_at',
    label: 'creation date',
    style: 'text-center whitespace-nowrap',
    render: (e) => <span>{e.created_at.split('T')[0]}</span>,
    sortable: true
  },
  {
    key: 'owner',
    label: 'Owner',
    style: 'text-center whitespace-nowrap',
    render: (e) => <RenderGroupOrUser user={e?.user} group={e.group} />
  },
  {
    key: 'versions',
    label: 'Versions',
    style: 'text-center whitespace-nowrap',
    render: (e) => (
      <Tag
        text={e?.policy_version ? e?.policy_version[0].count : 'N/D'}
        type={e?.policy_version[0].count > 0 ? undefined : 'gray'}
      />
    )
  },
  {
    key: 'status',
    label: 'Tag',
    sortable: true,
    style: 'w-4',
    render: StatusTag,
    style: 'text-center whitespace-nowrap'
  }
]

/*-----------------------------Compliance--------------------------------------*/
export const ComplianceApplicableTagRender = (applicable) => (
  <Tag text={applicable ? 'APPLICABLE' : 'NOT APPLICABLE'} type={applicable ? undefined : 'gray'} />
)

export const ComplianceMaturity = (compliance) => {
  return (
    <Tag
      text={compliance}
      dataTestid={'compliance-maturity'}
      type={
        compliance < 3
          ? 'error'
          : compliance < 5
            ? 'yellow'
            : undefined
      }
    />
  );
}

export const ComplianceColumnShort = [
  { key: 'framework_name', label: 'framework_name', style: 'text-center whitespace-nowrap', render: (e) => <Tag type='gray' text={e.framework_name} /> },
  { key: 'title', label: 'Name', style: 'break-words w-full text-left', render: (e) => <span>{e?.userdef_id + ': ' + e?.title}</span> },
  { key: 'applicable', label: 'Applicable', style: 'break-words w-full text-center' }
]

export const complianceColumnFull = [
  { key: 'userdef_id', label: 'ID', style: 'text-center whitespace-nowrap' },
  { key: 'title', label: 'Name', style: 'break-words w-full text-left' },
  {
    key: 'Risks',
    label: 'Risks',
    style: 'break-words w-full text-center',
    render: (e) => <Tag text={e?.risk.length ?? "Error"} type={e?.risk.length === 0 ? 'gray' : undefined} />
  },
  {
    key: 'Policies',
    label: 'Policies',
    style: 'break-words w-full text-center',
    render: (e) => <Tag text={e?.policy.length ?? "Error"} type={e?.policy.length === 0 ? 'gray' : undefined} />
  },
  {
    key: 'Controls',
    label: 'Controls',
    style: 'break-words w-full text-center',
    render: (e) => <Tag text={e?.control.length ?? "Error"} type={e?.control.length === 0 ? 'gray' : undefined} />
  },
  {
    key: 'maturity',
    label: 'Maturity',
    style: 'break-words w-full text-center',
    render: (e) => ComplianceMaturity(e?.maturity ? e.maturity : 0)
  },
  {
    key: 'applicable',
    label: 'Applicable',
    style: 'break-words w-full text-center',
    render: (e) => ComplianceApplicableTagRender(e.applicable)
  }
]

/*-----------------------------Risk--------------------------------------*/
export const RiskColumnShort = [
  { key: 'userdef_id', label: 'ID', style: 'text-center whitespace-nowrap' },
  { key: 'title', label: 'Title', style: 'w-full break-words' },
  {
    key: 'status',
    label: 'Tag',
    render: StatusTag,
    style: 'text-center whitespace-nowrap'
  }
]

export const RiskColumnFull = [
  { key: 'userdef_id', label: 'ID', sortable: true, style: 'text-center whitespace-nowrap' },
  { key: 'title', label: 'Title', style: 'w-full break-words', },
  { key: 'created_at', label: 'Creation date', sortable: true, style: 'text-center whitespace-nowrap', render: (e) => <span>{e.created_at.split('T')[0]}</span> },
  {
    key: 'owner',
    label: 'Owner',
    style: 'text-center whitespace-nowrap',
    render: (e) => <RenderGroupOrUser user={e?.user} group={e?.group} />
  },
  {
    key: 'risk_score',
    label: 'Score',
    style: 'text-center whitespace-nowrap',
    sortable: true,
    render: (e) => <Tag text={e.risk_score} />
  },
  { key: 'status', label: 'Tag', sortable: true, style: 'text-center whitespace-nowrap', render: StatusTag }
]

/*-----------------------------Control--------------------------------------*/
export const ControlColumnShort = [
  { key: 'userdef_id', label: 'ID', style: 'text-center whitespace-nowrap' },
  { key: 'title', label: 'Title', style: 'w-full break-words' },
  {
    key: 'status',
    label: 'Tag',
    render: StatusTag,
    style: 'w-4 text-center whitespace-nowrap'
  }
]

export const TaskFullColumn = [
  { key: 'userdef_id', label: 'ID', style: 'text-center whitespace-nowrap' },
  {
    key: 'title',
    label: 'Title',
    style: 'break-words w-full',
    render: (e) => (
      <div className='flex flex-row'>
        {e?.fk_schedule_control &&
          <Tooltip message={'Scheduled Task'}>
            <ControlOutlined className='text-lg text-blue-600 mr-1' />
          </Tooltip>
        }
        <span>{e.title}</span>
      </div>
    )
  },
  { key: 'created_at', label: 'creation date', style: 'text-center whitespace-nowrap', sortable: true, render: (e) => <span>{e.created_at ? e.created_at.split('T')[0] : 'N/A'}</span> },
  { key: 'duedate', label: 'Due Date', style: 'text-center whitespace-nowrap', sortable: true },
  {
    key: 'executor',
    label: 'Asssigned',
    style: 'text-center whitespace-nowrap',
    render: (e) => <RenderGroupOrUser user={e?.executor_user} group={e?.executor_group} />
  },
  { key: 'status', label: 'Tag', style: 'text-center whitespace-nowrap w-2', sortable: true, render: TaskStatusTag }
]

export const ControlColumnFull = [
  { key: 'userdef_id', sortable: true, label: 'ID', sortable: true, style: 'text-center whitespace-nowrap' },
  {
    key: 'title',
    label: 'Title',
    style: 'w-full break-words',
  },
  {
    key: 'readable_control_schedule_str',
    label: 'Schedule',
    style: 'text-center whitespace-nowrap',
    render: (e) => (
      <>
        {e.control_strategy === 'nocontrol' && <span>NO SCHEDULE</span>}
        {e.control_strategy === 'scheduled_control' && <span>{e.readable_control_schedule_str}</span>}
      </>
    )
  },
  {
    key: 'owner',
    label: 'Owner',
    sortable: true,
    style: 'text-center whitespace-nowrap',
    render: (e) => <RenderGroupOrUser user={e?.owner_user} group={e?.owner_group} />
  },
  {
    key: 'status',
    label: 'Tag',
    sortable: true,
    style: 'w-4',
    render: StatusTag,
    style: 'text-center whitespace-nowrap'
  }
]

export const AuditColumns = [
  { key: 'table_name', label: 'Object', style: 'text-center' },
  { key: 'operation_type', label: 'Operation', style: 'text-center' },
  { key: 'changed_by', label: 'modified by', style: 'text-center' },
  { key: 'time', label: 'time', style: 'text-center' },
]
