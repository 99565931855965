import React, { useRef, useState } from 'react'

import { useNavigate, useParams } from 'react-router'
import { ElementDetail } from '../components/element/element-detail'
import { ObjectHeader } from '../components/header/object-header'
import { FullPageCard } from '../components/misc/full-page-card'
import { LoadingWheel } from '../components/misc/loading-wheel'
import { GroupForm } from '../components/stakeholder/group-form'
import { JTable } from '../components/table/j-table'
import { useGenericFetchData } from '../hooks/db/useGenericFetchData'
import { useDeleteConfirmationDialog } from '../hooks/useDeleteConfirmationDialog'
import { jToaster } from '../misc/j-toaster'
import { userService } from '../services/userService'
import { useAuth } from '../supabase'

const jtable_user_columns = [
  {
    key: 'name',
    label: 'name',
    style: 'text-center px-8'
  },
  {
    key: 'email',
    label: 'email',
    style: 'text-center px-8'
  },
  {
    key: 'department',
    label: 'department',
    style: 'text-center px-8'
  }
]

const group_spec = [
  {
    key: 'name',
    label: 'Name'
  },
  {
    key: 'created_at',
    label: 'Creation Date',
    render: (e) => <span>{e.created_at.split('T')[0]}</span>
  },
  {
    key: 'user',
    label: 'Users',
    render: (data) => <JTable columns={jtable_user_columns} value={data?.user} canEditDaa={false} />
  }
]

export const GroupDetailPage = (props) => {
  const navigate = useNavigate()
  let { id } = useParams()
  if (id === 'new') id = undefined

  const { supabase, session } = useAuth()

  const [isEditing, setIsEditing] = useState(id ? false : true)

  const { openDialog, DeleteConfirmationDialog } = useDeleteConfirmationDialog();
  const { data, isLoading, error } = useGenericFetchData(
    userService.getGroupById,
    [supabase, id],
    id === undefined
  );
  const errorShownRef = useRef(false);

  const handleEditClick = () => {
    setIsEditing(true)
  }

  const handleDelete = async () => {
    openDialog(async () => {
      try {
        const { error } = await supabase
          .from('group')
          .delete()
          .match({ id: id })

        if (error) throw error;

        jToaster.success(`Group "${data?.name}" has been deleted.`)
        navigate('/app/stakeholders')
      } catch (error) {
        jToaster.error('Failed to delete the group. Error code: ' + error?.code);
      }
    })
  }

  if (error && !errorShownRef.current) {
    jToaster.error('Failed to load group information. Error code: ' + error.code)
    errorShownRef.current = true;
  }

  if (isLoading) return <LoadingWheel />;
  if (error) return <></>

  return (
    <FullPageCard>
      <ObjectHeader
        title={'Group'}
        backUrl={-1}
        secondaryButtonText={!isEditing ? 'Delete' : undefined}
        onSecondaryButtonClick={handleDelete}
        primaryButtonText={!isEditing ? 'Edit' : undefined}
        onPrimaryButtonClick={handleEditClick}
      />
      <DeleteConfirmationDialog />
      {isEditing &&
        <GroupForm
          id={id}
          UserTableColumns={jtable_user_columns}
        />
      }
      {!isEditing &&
        <ElementDetail
          detailSpec={group_spec}
          data={data}
        />
      }
    </FullPageCard>
  )
}
