
export const controlService = {
  async getAllControl(supabase, project_id) {
    const { data, error } = await supabase
      .from('control')
      .select(`
        *,
        owner_group:group!control_fk_owner_group_id_fkey (*),
        owner_user:user!control_fk_owner_user_id_fkey (*),
        executor_group:group!control_fk_executor_group_id_fkey (*),
        executor_user:user!control_fk_executor_user_id_fkey (*)
      `)
      .eq('fk_project_id', project_id)
      .order('created_at', { ascending: false })

    if (error) throw error
    return data
  },

  async getControlById(supabase, id) {
    const { data, error } = await supabase
      .from('control')
      .select(`
          *, 
          workflow_template(id, name),

          framework_safeguard(*, framework(name)), 
          policy(*),
          risk(*), 

          owner_group:group!control_fk_owner_group_id_fkey (*),
          owner_user:user!control_fk_owner_user_id_fkey (*),

          executor_group:group!control_fk_executor_group_id_fkey (*),
          executor_user:user!control_fk_executor_user_id_fkey (*)
      `)
      .eq('id', id)
      .single()

    if (error) throw error
    return data
  }
}