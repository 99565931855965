import React, { Fragment } from 'react'

import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dialog, Transition } from '@headlessui/react'

const DrawerContent = ({ filterOptions, activeFilters, onFilterChange }) => {
  return (
    <>
      {filterOptions &&
        filterOptions.map((filterCategory) => (
          <div className='border-b py-2' key={filterCategory.key}>
            <span className='block text-sm font-medium text-gray-900 py-1'>{filterCategory.section_title}</span>
            <div className='space-y-3 pl-4'>
              {filterCategory.data.map((categoryDetail) => (
                <div className='relative flex items-start' key={categoryDetail.value}>
                  <div className='flex h-6 items-center'>
                    <input
                      id={categoryDetail.value}
                      aria-describedby='comments-description'
                      name={categoryDetail.value}
                      checked={activeFilters[categoryDetail.value]}
                      onChange={onFilterChange}
                      type='checkbox'
                      className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600'
                    />
                  </div>
                  <div className='ml-3 text-sm leading-6'>
                    <label htmlFor={categoryDetail.value} className='text-gray-900'>
                      {categoryDetail.label}
                    </label>{' '}
                    {categoryDetail.subtitle && (
                      <span id='comments-description' className='text-gray-500'>
                        {categoryDetail.subtitle}
                      </span>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
    </>
  )
}

export const FilterDrawer = (props) => {
  const { filterOptions, activeFilters, onFilterChange, open, onClose } = props

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter='ease-in-out duration-500'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in-out duration-500'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 overflow-hidden'>
          <div className='absolute inset-0 overflow-hidden'>
            <div className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10'>
              <Transition.Child
                as={Fragment}
                enter='transform transition ease-in-out duration-500 sm:duration-700'
                enterFrom='translate-x-full'
                enterTo='translate-x-0'
                leave='transform transition ease-in-out duration-500 sm:duration-700'
                leaveFrom='translate-x-0'
                leaveTo='translate-x-full'
              >
                <Dialog.Panel className='pointer-events-auto w-screen max-w-md'>
                  <div className='flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl'>
                    <div className='px-4 sm:px-6'>
                      <div className='flex items-start justify-between border-b pb-2'>
                        <Dialog.Title className='text-base font-semibold leading-6 text-gray-900 '>Filter Options</Dialog.Title>
                        <div className='ml-3 flex h-7 items-center'>
                          <button
                            type='button'
                            className='rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                            onClick={onClose}
                          >
                            <span className='sr-only'>Close panel</span>
                            <FontAwesomeIcon icon={faTimes} />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className='relative flex-1 px-4 sm:px-6'>
                      <DrawerContent activeFilters={activeFilters} filterOptions={filterOptions} onFilterChange={onFilterChange} />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
