import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { ActionButton } from '../form/action-button'
import { TextInput } from '../form/inputs'
import { Radio } from '../form/radio'
import { JTable } from '../table/j-table'

const radio_options = [
  {
    primary: 'SuperUser',
    secondary:
      'Full access to all features and settings within the application.',
    value: 'SUPERUSER'
  },
  {
    primary: 'Contributor',
    secondary:
      'Allows users to view and manage tasks assigned to them as either a validator or executor. They only have access to the specific tasks they are involved in and no additional permissions beyond that.',
    value: 'CONTRIBUTOR'
  }
]

const radio_options_mfa = [
  {
    primary: 'Enabled',
    secondary: 'The user will be required to set up multi-factor authentication (MFA) using TOTP during their first login. This setting can be reset by a Superuser if needed.',
    value: 'enabled'
  },
  {
    primary: 'Disabled',
    secondary: 'The user will log in using only their email and password, without the need for multi-factor authentication.',
    value: 'disabled'
  }
];

export const UserForm = (props) => {
  const { initialData, onSubmit, onCancel } = props;

  const defaultValues = {
    id: initialData?.id || undefined,
    name: initialData?.name || '',
    email: initialData?.email || '',
    department: initialData?.department || '',
    type: initialData?.type || 'SUPERUSER',
    group: initialData?.group || [],
    mfa_required: initialData?.mfa_required === false ? 'disabled' : 'enabled'
  }

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    control
  } = useForm({ defaultValues })

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='space-y-4'>
      <TextInput
        label='Name'
        name='name'
        data-testid='user-form-name'
        errorMessage={errors?.name ? 'This field is required' : null}
        register_fn={() => {
          return register('name', { required: true })
        }}
      />
      <TextInput
        label='Email'
        name='email'
        data-testid='user-form-email'
        disabled={initialData?.id}
        errorMessage={errors?.name ?? 'This field is required'}
        register_fn={() => {
          return register('email', { required: true })
        }}
      />

      {!initialData?.id && <span>An invitation link will be sent to this user. Please make sure that it is a valid email address.</span>}

      <TextInput
        label='Department'
        name='department'
        data-testid='user-form-department'
        errorMessage={errors?.name ? 'This field is required' : null}
        register_fn={() => {
          return register('department', { required: true })
        }}
      />

      <div>
        <label className='block text-sm font-medium text-gray-700 mb-1'>Type</label>
        <Controller
          control={control}
          name='type'
          render={({ field: { value, onChange } }) => <Radio options={radio_options} value={value} onChange={onChange} name='type' />}
        />
      </div>

      <div>
        <label className='block text-sm font-medium text-gray-700 mb-1'>MFA</label>
        <Controller
          control={control}
          name='mfa_required'
          render={({ field: { value, onChange } }) => <Radio options={radio_options_mfa} value={value} onChange={onChange} name='mfa_required' />}
        />
      </div>

      {initialData?.id &&
        <div>
          <label className='block text-sm font-medium text-gray-700 mb-1'>Groups</label>
          <Controller
            control={control}
            name='group'
            render={({ field: { value, onChange } }) => (
              <JTable
                value={value}
                onChange={onChange}
                columns={[{ key: "name", label: "name", style: "text-center w-full" }]}
                canEditData={true}
                AddElementModalType={'group'}
              />
            )}
          />
        </div>
      }

      <div className='flex justify-end gap-2'>
        <ActionButton onClick={onCancel} styleType='secondary' type='button'>
          Cancel
        </ActionButton>
        <ActionButton dataTestid='user-form-save' disabled={!isValid} type='submit'>
          Save
        </ActionButton>
      </div>
    </form>
  )
}
